import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../styles/classes/reusable-classes";
import { AddExerciseInputs } from "../../types/add-exercise-inputs";

export const ExerciseDescriptionController = () => {
  const { control } = useFormContext<AddExerciseInputs>();

  return (
    <Controller
      control={control}
      name="description"
      render={({ field: { value, onChange } }) => (
        <UXInputContainer $textareaDimentions>
          <UXInputLabel $width="150px">
            Description
            <span>English - optional</span>
          </UXInputLabel>
          <UXInputWrapper $textareaDimentions $width="80%">
            <UXInputTextarea
              placeholder="Write a description for this exercise..."
              dir="auto"
              value={value === undefined ? "" : value}
              onChange={(e) =>
                onChange(e.target.value === undefined ? "" : e.target.value)
              }
            />
          </UXInputWrapper>
        </UXInputContainer>
      )}
    />
  );
};
