import { useFormContext } from "react-hook-form";
import { AddProgramInputs } from "./types/add-program-inputs";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../styles/workouts-form-header/workouts-form-header-styles";
import { Button } from "../../../../styles/classes/reusable-classes";

export const AddProgramFormHeader = () => {
  const { watch } = useFormContext<AddProgramInputs>();
  const programName = watch("name");
  const programNameInArabic = watch("nameInArabic");

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Program</span>
          {programNameInArabic}{" "}
          {programName !== "" && programName ? `(${programName})` : ``}
        </p>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="submit">Save</Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
