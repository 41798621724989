import { Link, useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { AddLevelInputs } from "./types/add-level-inputs";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../../styles/workouts-form-header/workouts-form-header-styles";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { WorkoutProgramDTO } from "../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

type AddLevelFormHeaderProps = {
  workoutProgramDTO: WorkoutProgramDTO;
};

export const AddLevelFormHeader = (props: AddLevelFormHeaderProps) => {
  const { workoutProgramDTO } = props;

  const { programID } = useParams();

  const { watch } = useFormContext<AddLevelInputs>();
  const levelName = watch("name");
  const levelNameInArabic = watch("nameInArabic");

  const programNameInEnglish =
    workoutProgramDTO.name.translations[0]?.translation;

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Program</span>
          <Link dir="auto" to={`/workouts/program/${programID}`}>
            {workoutProgramDTO.name.defaultText}{" "}
            {programNameInEnglish !== "" && programNameInEnglish
              ? `(${programNameInEnglish})`
              : ``}
          </Link>
        </p>
        <>
          <span className="material-symbols-outlined">chevron_right</span>
          <p dir="auto">
            <span>Level </span>
            {levelNameInArabic}{" "}
            {levelName !== "" && levelName ? `(${levelName})` : ``}
          </p>
        </>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="submit">Save</Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
