import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../../styles/classes/reusable-classes";
import { useExercises } from "../context/loadable-exercises-context";
import { useMemo, useState } from "react";
import { TablePaginationContainer } from "../../../../components/table/styled-table";
import { Pagination } from "../../../../components/pagination/pagination";
import { Table } from "../../../../components/table/table";
import { ExercisesTableRows } from "./exersices-table-rows";
import { TableHeader } from "../../../../components/table/table-header/table-header";
import { ExercisesTableHeadings } from "./exersices-table-headings";
import { WorkoutsFormContainer } from "../../styles/workouts-common-styles";
import { EmptyState } from "../../../../components/empty-state/empty-state";

export const ExercisesTable = () => {
  const { exercisesPage } = useParams();

  const { exercises } = useExercises();

  const [searchTerm, setSearchTerm] = useState("");
  const [tableRowsPerPage, setTableRowsPerPage] = useState(8);

  const navigate = useNavigate();

  const sorted = useMemo(
    () =>
      exercises.sort((a, b) =>
        a.name.defaultText.localeCompare(b.name.defaultText)
      ),
    [exercises]
  );

  const filtered = useMemo(
    () =>
      sorted.filter((item) => {
        if (searchTerm === "") {
          return item;
        } else if (
          item.name.defaultText.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
          return item;
        } else {
          return 0;
        }
      }),
    [sorted, searchTerm]
  );

  const maxPage = Math.ceil(filtered.length / tableRowsPerPage);

  const currentOrMaxPage = Math.min(Number(exercisesPage), maxPage);

  const currentPageExercises = useMemo(() => {
    const indexOfLastEntry = currentOrMaxPage * tableRowsPerPage;
    const indexOfFirstEntry = indexOfLastEntry - tableRowsPerPage;

    return filtered.slice(indexOfFirstEntry, indexOfLastEntry);
  }, [filtered, tableRowsPerPage, currentOrMaxPage]);

  function onSearchTermChange(searchTerm: string) {
    navigate(`../exercises/1`);
    setSearchTerm(searchTerm);
  }

  if (exercises.length === 0) {
    return (
      <WorkoutsFormContainer>
        <EmptyState
          hasHeader={false}
          hasSearch={false}
          text="No exercises yet created"
          title=""
          button={
            <Button flex type="button">
              <span className="material-symbols-outlined">add</span>
              Add Exercise
            </Button>
          }
        />
      </WorkoutsFormContainer>
    );
  }

  return (
    <WorkoutsFormContainer>
      <TableHeader
        title="All Exercises"
        searchTerm={searchTerm}
        setSearchTerm={onSearchTermChange}
        boldTitle
        button={
          <Link to={`/workouts/create-new-exercise`}>
            <Button flex type="button">
              <span className="material-symbols-outlined">add</span>
              Add Exercise
            </Button>
          </Link>
        }
      />
      <Table
        tableHeadings={<ExercisesTableHeadings />}
        tableRows={<ExercisesTableRows exercises={currentPageExercises} />}
      />
      <TablePaginationContainer>
        <Pagination
          totalRowsCount={filtered.length}
          tableRowsPerPage={tableRowsPerPage}
          setRowsPerPage={setTableRowsPerPage}
          navigateBasePath={"/exercises"}
          pageVariable={exercisesPage}
        />
      </TablePaginationContainer>
    </WorkoutsFormContainer>
  );
};
