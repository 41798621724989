import { FormProvider, useForm } from "react-hook-form";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
} from "../../../../styles/workouts-common-styles";
import { AddLevelInputs } from "./types/add-level-inputs";
import { AddLevelLeftControllers } from "./controllers/add-level-left-controllers/add-level-left-controllers";
import { LevelCoverPhotoController } from "./controllers/add-level-right-controllers/add-level-cover-photo-controller";
import { usePutWorkoutProgram } from "../../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { useToastService } from "../../../../../../context/toast-service-context";
import { WorkoutProgramDTO } from "../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";
import { PutWorkoutProgramDTO } from "../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";
import { PutWorkoutProgramLevelDTO } from "../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-level-dto";
import { useNavigate } from "react-router-dom";
import { AddLevelWorkouts } from "./add-level-workouts/add-level-workouts";
import { AddLevelWorkoutsWeeks } from "./add-level-workouts/types/add-level-workouts-weeks";
import { AddLevelWorkoutsWeeksFormHandlersProvider } from "./add-level-workouts/context/add-level-workouts-weeks-form-handlers-context";
import { PutWorkoutDTO } from "../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-dto";
import { AddLevelFormHeader } from "./add-level-form-header";

type AddLevelFormProps = {
  workoutProgramDTO: WorkoutProgramDTO;
};

export const AddLevelForm = (props: AddLevelFormProps) => {
  const { workoutProgramDTO } = props;

  const { showToast } = useToastService();
  const addLevelFormMethods = useForm<AddLevelInputs>();

  const addLevelWorkoutsWeeksFormMethods = useForm<AddLevelWorkoutsWeeks>({
    defaultValues: {
      weeks: [{ weekNumber: 1, days: [1, 2, 3, 4, 5, 6, 7], workouts: [] }],
    },
  });

  const putWorkoutProgram = usePutWorkoutProgram();

  const navigate = useNavigate();

  const workouts = addLevelWorkoutsWeeksFormMethods
    .watch("weeks")
    .flatMap((week) => week.workouts);

  const onSubmit = addLevelFormMethods.handleSubmit(async (inputs) => {
    try {
      const putWorkoutProgramLevelDTO: PutWorkoutProgramLevelDTO = {
        name: {
          defaultLanguageId: 2,
          defaultText: inputs.nameInArabic,
          translations:
            inputs.name && inputs.name !== ""
              ? [
                  {
                    languageId: 1,
                    translation: inputs.name,
                  },
                ]
              : [],
        },
        coverPhotoUploadedFileId: inputs.coverPhotoUploadedFileId,
        daysCount: Number(inputs.daysCount),
        uiOrder: Number(inputs.uiOrder),
        workouts: workouts
          .filter((workout) => workout.name.defaultText !== "Rest Day")
          .map((item) => ({
            ...item,
            workoutExercises: item.workoutExercises?.map((i) => ({
              exerciseId: i.exerciseId,
              uiOrder: i.uiOrder,
            })),
          })),
      };

      const restOfWorkoutProgramLevels: PutWorkoutProgramLevelDTO[] = workoutProgramDTO.workoutProgramLevels
        ? [
            ...workoutProgramDTO.workoutProgramLevels.map((item) => ({
              id: item.id,
              name: item.name,
              coverPhotoUploadedFileId: item.coverPhotoUploadedFileId,
              daysCount: item.daysCount,
              uiOrder: item.uiOrder,
              workouts: item.workouts?.map((item) => ({
                ...item,
                workoutExercises: item.workoutExercises?.map((i) => ({
                  exerciseId: i.exercise.id,
                  uiOrder: i.uiOrder,
                })),
              })) as PutWorkoutDTO[],
            })),
          ]
        : [];

      const putWorkoutProgramDTO: PutWorkoutProgramDTO = {
        ...workoutProgramDTO,
        workoutProgramLevels: [
          ...restOfWorkoutProgramLevels,
          putWorkoutProgramLevelDTO,
        ],
      };

      await putWorkoutProgram(workoutProgramDTO.id, putWorkoutProgramDTO).then(
        (res) => {
          if (res.workoutProgramLevels) {
            const newLevelID = res.workoutProgramLevels?.sort(
              (a, b) => a.uiOrder - b.uiOrder
            )[res.workoutProgramLevels.length - 1].id;
            navigate(
              `/workouts/program/${workoutProgramDTO.id}/level/${newLevelID}`
            );
          }
        }
      );

      showToast("Success", "Success Adding Level");
    } catch (error) {
      showToast("Error", "Faild to Add Level :(");
    }
  });

  return (
    <FormProvider {...addLevelFormMethods}>
      <form onSubmit={onSubmit}>
        <AddLevelFormHeader workoutProgramDTO={workoutProgramDTO} />
        <WorkoutsFormContainer $level>
          <WorkoutsFormControllersContainer>
            <AddLevelLeftControllers />
            <WorkoutsFormFormSubContainer>
              <LevelCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
          <FormProvider {...addLevelWorkoutsWeeksFormMethods}>
            <AddLevelWorkoutsWeeksFormHandlersProvider>
              <AddLevelWorkouts />
            </AddLevelWorkoutsWeeksFormHandlersProvider>
          </FormProvider>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
