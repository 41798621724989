import { ContentModuleQuestionDTO } from "../../../../../../models/content-module-dtos/content-module-question-dto";
import {
  ContentModuleLessonMobilePreviewQuestionAnswer,
  ContentModuleLessonMobilePreviewQuestionAnswers,
  ContentModuleLessonMobilePreviewQuestionsListItem,
  ContentModuleLessonMobilePreviewQuestionTitle,
} from "../../mobile-preview-lesson-questions-styles";

type ReadContentModuleLessonMobilePreviewQuestionProps = {
  question: ContentModuleQuestionDTO;
  activeCard: boolean;
};

export const ReadContentModuleLessonMobilePreviewQuestion = (
  props: ReadContentModuleLessonMobilePreviewQuestionProps
) => {
  const { question, activeCard } = props;

  return (
    <ContentModuleLessonMobilePreviewQuestionsListItem $activeCard={activeCard}>
      <ContentModuleLessonMobilePreviewQuestionTitle>
        {question.question}
      </ContentModuleLessonMobilePreviewQuestionTitle>
      <ContentModuleLessonMobilePreviewQuestionAnswers>
        {question.choices.map((choice, index) => (
          <ContentModuleLessonMobilePreviewQuestionAnswer key={index}>
            {choice.title}
          </ContentModuleLessonMobilePreviewQuestionAnswer>
        ))}
      </ContentModuleLessonMobilePreviewQuestionAnswers>
    </ContentModuleLessonMobilePreviewQuestionsListItem>
  );
};
