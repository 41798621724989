import { FormProvider, useForm } from "react-hook-form";
import { AddLevelWorkoutsModalBody } from "./add-level-workouts-modal-body/add-level-workouts-modal-body";
import { AddLevelWorkoutInputs } from "./types/add-level-workout-inputs";
import { useModalHelpers } from "../../../../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../../../../styles/classes/reusable-classes";
import { Modal } from "../../../../../../../../../components/modal/modal";
import { useAddLevelWorkoutsWeeksFormHandlers } from "../../context/add-level-workouts-weeks-form-handlers-context";

type AddLevelWorkoutsEditModalProps = {
  day: number;
  weekNumber: number;
};

export const AddLevelWorkoutsEditModal = (
  props: AddLevelWorkoutsEditModalProps
) => {
  const { day, weekNumber } = props;

  const { getWeekWorkouts } = useAddLevelWorkoutsWeeksFormHandlers();
  const workouts = getWeekWorkouts(weekNumber);

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const currentWorkout = workouts?.find(
    (workoutDTO) => workoutDTO.dayNumber === day
  );

  const workoutExercisesFormMethods = useForm<AddLevelWorkoutInputs>({
    defaultValues: {
      dayNumber: day,
      name: currentWorkout?.name.translations[0]?.translation,
      nameInArabic: currentWorkout?.name.defaultText,
      descriptionInArabic: currentWorkout?.description.defaultText,
      description: currentWorkout?.name.translations[0]?.translation,
      workoutExercises: currentWorkout?.workoutExercises,
    },
  });

  return (
    <>
      <Button type="button" flex onClick={openModal}>
        <span className="material-symbols-outlined">open_in_full</span>Expand
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...workoutExercisesFormMethods}>
          <AddLevelWorkoutsModalBody
            key={modalBodyKey}
            closeModal={closeModal}
            day={day}
            weekNumber={weekNumber}
            editMode
          />
        </FormProvider>
      </Modal>
    </>
  );
};
