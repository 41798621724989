import styled from "styled-components/macro";
import {
  flexCenter,
  mediumFont,
} from "../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../styles/classes/reusable-classes";

export const WorkoutsFormHeaderContainer = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${flexCenter}
  gap: 0px 24px;
  background: ${({ theme }) => theme.colors.containerColor};

  @media screen and (max-width: 800px) {
    padding: 16px;

    ${Button} {
      padding: 8px 16px;
    }
  }
`;

export const WorkoutsFormHeaderTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0px 8px;

  p {
    margin: 0px;
    display: grid;
  }

  a {
    margin: 0;
    ${mediumFont}
    color: ${({ theme }) => theme.colors.primaryColor};
  }

  @media screen and (max-width: 800px) {
    ${mediumFont}
  }
`;

export const WorkoutsFormHeaderButtonContainer = styled.div`
  margin-left: auto;
  ${flexCenter}
  gap: 0px 16px;
`;
