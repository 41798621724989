import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
} from "../../../styles/workouts-common-styles";
import { ReadExerciseLeftControllers } from "./controllers/read-exercise-left-controllers/read-exercise-left-controllers";
import { ReadExerciseRightControllers } from "./controllers/read-exercise-right-controllers/read-exercise-right-controllers";
import { ReadExerciseFormHeader } from "./read-exercise-form-header";

export const ReadExerciseForm = () => {
  return (
    <div>
      <ReadExerciseFormHeader />
      <WorkoutsFormContainer>
        <WorkoutsFormControllersContainer>
          <ReadExerciseLeftControllers />
          <ReadExerciseRightControllers />
        </WorkoutsFormControllersContainer>
      </WorkoutsFormContainer>
    </div>
  );
};
