import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../../styles/classes/reusable-classes";
import { useProgramState } from "../context/program-state-context";
import { useWorkoutProgram } from "../context/loadable-workout-program-context";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../styles/workouts-form-header/workouts-form-header-styles";

export const ReadProgramFormHeader = () => {
  const { setProgramState } = useProgramState();
  const { programID } = useParams();
  const { workoutProgramDTO } = useWorkoutProgram();

  const programNameInEnglish =
    workoutProgramDTO.name.translations[0]?.translation;

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Program</span>
          <Link dir="auto" to={`/workouts/program/${programID}`}>
            {workoutProgramDTO.name.defaultText}{" "}
            {programNameInEnglish !== "" && programNameInEnglish
              ? `(${programNameInEnglish})`
              : ``}
          </Link>
        </p>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="button" onClick={() => setProgramState("Update")}>
          Edit
        </Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
