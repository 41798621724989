import { FormProvider, useForm } from "react-hook-form";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
} from "../../../styles/workouts-common-styles";
import {
  useReloadWorkoutProgram,
  useWorkoutProgram,
} from "../context/loadable-workout-program-context";
import { UpdateProgramInputs } from "./types/update-program-form-inputs";
import { UpdateProgramLeftControllers } from "./controllers/update-program-left-controllers/update-program-left-controllers";
import { UpdateProgramCoverPhotoController } from "./controllers/update-program-right-controllers/update-program-cover-photo-controller";
import { useProgramState } from "../context/program-state-context";
import { useToastService } from "../../../../../context/toast-service-context";
import { usePutWorkoutProgram } from "../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { PutWorkoutProgramDTO } from "../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";
import { Levels } from "../levels/levels";
import { PutWorkoutProgramLevelDTO } from "../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-level-dto";
import { UpdateProgramFormHeader } from "./update-program-form-header";

export const UpdateProgramForm = () => {
  const { workoutProgramDTO } = useWorkoutProgram();
  const { setProgramState } = useProgramState();
  const { showToast } = useToastService();
  const reloadWorkoutProgram = useReloadWorkoutProgram();
  const updateWorkoutProgram = usePutWorkoutProgram();

  const updateProgramInputsFormMethods = useForm<UpdateProgramInputs>({
    defaultValues: {
      isDefault: workoutProgramDTO.isDefault,
      isDraft: workoutProgramDTO.isDraft,
      name: workoutProgramDTO.name.translations[0]?.translation,
      nameInArabic: workoutProgramDTO.name.defaultText,
      coverPhotoUploadedFileId: workoutProgramDTO.coverPhotoUploadedFileId,
      workoutProgramLevels: workoutProgramDTO.workoutProgramLevels,
    },
  });

  const onSubmit = updateProgramInputsFormMethods.handleSubmit(
    async (inputs) => {
      try {
        const putWorkoutProgramDTO: PutWorkoutProgramDTO = {
          name: {
            defaultLanguageId: 2,
            defaultText: inputs.nameInArabic,
            translations:
              inputs.name && inputs.name !== ""
                ? [
                    {
                      languageId: 1,
                      translation: inputs.name,
                    },
                  ]
                : [],
          },
          isDefault: inputs.isDefault,
          isDraft: inputs.isDraft,
          coverPhotoUploadedFileId: inputs.coverPhotoUploadedFileId,
          workoutProgramLevels: workoutProgramDTO.workoutProgramLevels as PutWorkoutProgramLevelDTO[],
        };

        await updateWorkoutProgram(workoutProgramDTO.id, putWorkoutProgramDTO);
        reloadWorkoutProgram();
        setProgramState("Read");
        showToast("Success", "Program updated successfully");
      } catch (error) {
        showToast("Error", "Faild to update Program");
      }
    }
  );

  return (
    <FormProvider {...updateProgramInputsFormMethods}>
      <form onSubmit={onSubmit}>
        <UpdateProgramFormHeader />
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <UpdateProgramLeftControllers />
            <WorkoutsFormFormSubContainer>
              <UpdateProgramCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
          <Levels />
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
