import { useMemo } from "react";
import {
  LevelWorkoutsCardWorkoutContainer,
  LevelWorkoutsCardWorkoutDetail,
  LevelWorkoutsCardWorkoutDetails,
  LevelWorkoutsCardWorkoutHeader,
  LevelWorkoutsCardWorkoutHeaderTitle,
  LevelWorkoutsCardWorkoutInformation,
} from "../../../../../../../../styles/level-workouts-styles/level-workouts-card-styles";
import { calculateWorkoutDetails } from "../../../../../add-level/add-level-workouts/add-level-workouts-container/utils/calculate-workout-details";
import { useReadLevelWorkouts } from "../../context/loadable-read-level-workouts-context";
import { useReadLevelWorkoutsWeeksFormHandlers } from "../../context/read-level-workouts-weeks-form-handlers-context";
import { ReadLevelWorkoutsCardExercises } from "./read-level-workouts-card-exercises";

type ReadLevelWorkoutsCardProps = {
  dayNumber: number;
  weekNumber: number;
};

export const ReadLevelWorkoutsCard = (props: ReadLevelWorkoutsCardProps) => {
  const { dayNumber, weekNumber } = props;

  const { getWeekWorkouts } = useReadLevelWorkoutsWeeksFormHandlers();
  const workouts = getWeekWorkouts(weekNumber);

  const { exerciseDTOs } = useReadLevelWorkouts();

  const { currentDayExercises, workoutInformation } = useMemo(() => {
    const workoutsExercises = workouts
      ? workouts
          .map((workout) =>
            workout.workoutExercises && workout.dayNumber === dayNumber
              ? workout.workoutExercises
              : []
          )
          .flat()
          .sort((a, b) => a.uiOrder - b.uiOrder)
      : [];

    const currentDayExercises = workoutsExercises.map(
      (item) => exerciseDTOs.find((ex) => ex.id === item.exercise.id)!
    );

    const currentWorkout = workouts?.find(
      (workoutDTO) => workoutDTO.dayNumber === dayNumber
    );

    const workoutInformation = {
      name: currentWorkout?.name.defaultText || "",
      description: currentWorkout?.description.defaultText || "",
      nameInEnglish: currentWorkout?.name.translations[0]?.translation || "",
      descriptionInEnglish:
        currentWorkout?.description.translations[0]?.translation || "",
    };

    return { currentDayExercises, workoutInformation };
  }, [workouts, exerciseDTOs, dayNumber]);

  return (
    <LevelWorkoutsCardWorkoutContainer $restDay={false} $readMode>
      <LevelWorkoutsCardWorkoutHeader $restDay={false}>
        <LevelWorkoutsCardWorkoutHeaderTitle $restDay>
          Day {dayNumber}
        </LevelWorkoutsCardWorkoutHeaderTitle>
      </LevelWorkoutsCardWorkoutHeader>
      <div>
        <LevelWorkoutsCardWorkoutInformation>
          <p>
            {workoutInformation.name}
            <span>
              {workoutInformation.nameInEnglish !== "" &&
              workoutInformation.nameInEnglish
                ? ` (${workoutInformation.nameInEnglish})`
                : ``}
            </span>
          </p>
        </LevelWorkoutsCardWorkoutInformation>
        <LevelWorkoutsCardWorkoutDetails>
          <LevelWorkoutsCardWorkoutDetail>
            <span className="material-symbols-outlined">schedule</span>
            <p>
              {
                calculateWorkoutDetails(currentDayExercises)
                  .totalWorkoutDuration
              }{" "}
              mins
            </p>
          </LevelWorkoutsCardWorkoutDetail>
          <LevelWorkoutsCardWorkoutDetail>
            <span className="material-symbols-outlined">
              local_fire_department
            </span>
            <p>
              {calculateWorkoutDetails(currentDayExercises).totalBurnedKcals}{" "}
              kcal
            </p>
          </LevelWorkoutsCardWorkoutDetail>
        </LevelWorkoutsCardWorkoutDetails>
        <ReadLevelWorkoutsCardExercises
          currentDayExercises={currentDayExercises}
          removeImage={workouts.length > 5}
        />
      </div>
    </LevelWorkoutsCardWorkoutContainer>
  );
};
