import { FormProvider, useForm } from "react-hook-form";
import { useUpdateLevelWorkoutsWeeksFormHandlers } from "../../context/update-level-workouts-weeks-form-handlers-context";
import { useModalHelpers } from "../../../../../../../../../../components/modal/use-modal-helpers";
import { UpdateLevelWorkoutInputs } from "./types/update-level-workout-inputs";
import { Button } from "../../../../../../../../../../styles/classes/reusable-classes";
import { Modal } from "../../../../../../../../../../components/modal/modal";
import { UpdateLevelWorkoutsModalBody } from "./update-level-workouts-modal-body/update-level-workouts-modal-body";

type UpdateLevelWorkoutsEditModalProps = {
  day: number;
  weekNumber: number;
};

export const UpdateLevelWorkoutsEditModal = (
  props: UpdateLevelWorkoutsEditModalProps
) => {
  const { day, weekNumber } = props;

  const { getWeekWorkouts } = useUpdateLevelWorkoutsWeeksFormHandlers();
  const workouts = getWeekWorkouts(weekNumber);

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const currentWorkout = workouts?.find(
    (workoutDTO) => workoutDTO.dayNumber === day
  );

  const workoutExercisesFormMethods = useForm<UpdateLevelWorkoutInputs>({
    defaultValues: {
      dayNumber: day,
      name: currentWorkout?.name.defaultText,
      nameInEnglish: currentWorkout?.name.translations[0]?.translation,
      description: currentWorkout?.description.defaultText,
      descriptionInEnglish:
        currentWorkout?.description.translations[0]?.translation,
      workoutExercises: currentWorkout?.workoutExercises?.map(
        (workoutExercise) => ({
          exerciseId: workoutExercise.exercise.id,
          uiOrder: workoutExercise.uiOrder,
        })
      ),
    },
  });

  return (
    <>
      <Button type="button" flex onClick={openModal}>
        <span className="material-symbols-outlined">open_in_full</span>Expand
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...workoutExercisesFormMethods}>
          <UpdateLevelWorkoutsModalBody
            key={modalBodyKey}
            closeModal={closeModal}
            day={day}
            weekNumber={weekNumber}
            editMode
          />
        </FormProvider>
      </Modal>
    </>
  );
};
