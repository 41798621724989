import { useCallback } from "react";
import { useGetAllExercises } from "../../../../../../../../hooks/exercises/use-get-all-exercises";
import { useLoadableData } from "../../../../../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../../../../../components/loadable-data-loading";
import { LoadableUpdateLevelWorkoutsProvider } from "./context/loadable-update-level-workouts-context";
import { UpdateLevelWorkoutsContainer } from "./update-level-workouts-container/update-level-workouts-container";

export const UpdateLevelWorkouts = () => {
  const getAllExercises = useGetAllExercises();

  const loadableUpdateLevelWorkoutsCallback = useCallback(async () => {
    const [exerciseDTOs] = await Promise.all([getAllExercises()]);

    return { exerciseDTOs };
  }, [getAllExercises]);

  const loadableUpdateLevelWorkouts = useLoadableData(
    loadableUpdateLevelWorkoutsCallback
  );

  return (
    <LoadableDataLoading
      state={loadableUpdateLevelWorkouts.state}
      successComponent={() => (
        <LoadableUpdateLevelWorkoutsProvider
          loadableUpdateLevelWorkouts={loadableUpdateLevelWorkouts}
        >
          <UpdateLevelWorkoutsContainer />
        </LoadableUpdateLevelWorkoutsProvider>
      )}
    />
  );
};
