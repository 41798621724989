import styled from "styled-components/macro";
import { lightShadowAlt, transition } from "../../../../styles/global-style";
import {
  regularSemiBoldFont,
  flexCenterCenter,
} from "../../../../styles/classes/gloabl-classes";

export const ContentCourseModuleLessonTabsList = styled.ul`
  ${flexCenterCenter}
  gap: 0px 24px;
  position: sticky;
  top: 0px;
  background: ${({ theme }) => theme.colors.containerColor};
  z-index: 500;
  margin: 0px;
  padding: 8px;
  ${lightShadowAlt}
`;

export const ContentCourseModuleLessonTabsListItem = styled.li<{
  $active: boolean;
}>`
  cursor: pointer;
  ${regularSemiBoldFont}
  color: ${({ theme, $active }) =>
    $active ? theme.colors.titleColor : theme.colors.textColor};
    position: relative;

    ::after {
      content: '';
      position: absolute;
      bottom: -7px;
      left: 0px;
      height: 4px;
      width: ${({ $active }) => ($active ? "100%" : "0px")};;
      background: ${({ theme }) => theme.colors.primaryColorAlt};
      transition: ${transition};
    }
`;
