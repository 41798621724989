import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { CreateUploadedFileDTO } from "../../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { UpdateExerciseInputs } from "../../../types/update-exercise-inputs";
import { useVideoUploadHelpers } from "../../../../../../../content/components/video-upload/context/video-upload-helpers-context";
import { VideoUpload } from "../../../../../../../content/components/video-upload/video-upload";
import { useExercise } from "../../../../context/loadable-exercise-context";

export const UpdateExerciseMediaContainer = () => {
  const { exerciseDTO } = useExercise();

  const {
    videoFile,
    videoUploadState,
    setVideoFile,
    setVideoUploadState,
    handleUploadedVideoState,
    setLoading,
  } = useVideoUploadHelpers();

  const addUploadedFile = useAddUploadedFile();
  const { showToast } = useToastService();
  const {
    setValue,
    formState: { errors },
  } = useFormContext<UpdateExerciseInputs>();

  useEffect(() => {
    if (exerciseDTO.mediaUploadedFile) {
      setVideoUploadState({
        state: "Uploaded",
        previewUrl: exerciseDTO.mediaUploadedFile.presignedUrl,
      });

      const jsonMediaUploadedFile = JSON.stringify(
        exerciseDTO.mediaUploadedFile
      );
      const blob = new Blob([jsonMediaUploadedFile], {
        type: "application/json",
      });
      setVideoFile(blob);
    }
  }, [setVideoUploadState, exerciseDTO.mediaUploadedFile, setVideoFile]);

  const onUpload = useCallback(async () => {
    try {
      if (videoFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: "ContentModuleCardPartVideo",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          videoFile
        ).then((response) => setValue("mediaUploadedFileId", response.id));
        handleUploadedVideoState(videoFile);
        showToast("Success", "Media Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Media :(");
    }
  }, [
    videoFile,
    setLoading,
    showToast,
    addUploadedFile,
    handleUploadedVideoState,
    setValue,
  ]);

  useEffect(() => {
    if (videoFile && videoUploadState.state === "Upload") {
      onUpload();
    } else if (
      videoUploadState.state === "Upload" &&
      videoUploadState.previewUrl === undefined
    ) {
      setValue("mediaUploadedFileId", undefined);
    }
  }, [videoFile, onUpload, videoUploadState, setValue]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px">Exercise Media</UXInputLabel>
      <VideoUpload
        errorState={errors.mediaUploadedFileId?.type === "required"}
      />
    </UXInputContainer>
  );
};
