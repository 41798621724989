import styled from "styled-components/macro";
import { transition } from "../../../../styles/global-style";
import { regularSemiBoldFont } from "../../../../styles/classes/gloabl-classes";
import { EmptyStateBody } from "../../../../components/empty-state/styled-empty-state";

export const ContentModuleLessonMobilePreviewQuestionsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px 0px;
  height: 350px;
  margin-top: 0px;
  margin-bottom: 90px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  ${EmptyStateBody} {
    height: 20vh;

    img {
      height: 140px;
    }

    p {
      font-size: 16px;
    }
  }
`;

export const ContentModuleLessonMobilePreviewQuestionsListItem = styled.li<{
  $activeCard: boolean;
}>`
  opacity: ${({ $activeCard }) => ($activeCard ? "1" : "0")};
  height: ${({ $activeCard }) => ($activeCard ? "auto" : "0px")};
  overflow: ${({ $activeCard }) => ($activeCard ? "visible" : "hidden")};
  transition: ${transition};
  border-radius: 8px;
  padding: ${({ $activeCard }) => ($activeCard ? "16px" : "0px")};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const ContentModuleLessonMobilePreviewQuestionTitle = styled.p`
  text-align: center;
  ${regularSemiBoldFont}
`;

export const ContentModuleLessonMobilePreviewQuestionAnswers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentModuleLessonMobilePreviewQuestionAnswer = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: 100%;
  text-align: center;
`;
