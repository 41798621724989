import { createContext, PropsWithChildren, useContext } from "react";
import { ExerciseDTO } from "../../../../../../../../../models/exercises-dtos/exercise-dto";
import { UseLoadableDataReturn } from "../../../../../../../../../hooks/use-loadable-data";

export type UpdateLevelWorkouts = {
  exerciseDTOs: ExerciseDTO[];
};

export type LoadableUpdateLevelWorkouts = UseLoadableDataReturn<
  UpdateLevelWorkouts
>;

const LoadableUpdateLevelWorkoutsContext = createContext<
  LoadableUpdateLevelWorkouts | undefined
>(undefined);

export type LoadableUpdateLevelWorkoutsProviderProps = PropsWithChildren<{
  loadableUpdateLevelWorkouts: LoadableUpdateLevelWorkouts;
}>;

export function LoadableUpdateLevelWorkoutsProvider(
  props: LoadableUpdateLevelWorkoutsProviderProps
) {
  const { loadableUpdateLevelWorkouts, children } = props;

  return (
    <LoadableUpdateLevelWorkoutsContext.Provider
      value={loadableUpdateLevelWorkouts}
    >
      {children}
    </LoadableUpdateLevelWorkoutsContext.Provider>
  );
}

export function useLoadableUpdateLevelWorkouts(): LoadableUpdateLevelWorkouts {
  const loadableUpdateLevelWorkouts = useContext(
    LoadableUpdateLevelWorkoutsContext
  );

  if (loadableUpdateLevelWorkouts === undefined) {
    throw Error(
      "useLoadableUpdateLevelWorkouts must be used within an LoadableUpdateLevelWorkoutsProvider"
    );
  }

  return loadableUpdateLevelWorkouts;
}

export function useUpdateLevelWorkouts(): UpdateLevelWorkouts {
  const loadableUpdateLevelWorkouts = useLoadableUpdateLevelWorkouts();

  if (loadableUpdateLevelWorkouts.state.data === undefined) {
    throw Error("UpdateLevelWorkouts have never been loaded successfully yet.");
  }

  return loadableUpdateLevelWorkouts.state.data;
}

export function useReloadUpdateLevelWorkouts(): () => void {
  const loadableUpdateLevelWorkouts = useLoadableUpdateLevelWorkouts();

  return loadableUpdateLevelWorkouts.reload;
}
