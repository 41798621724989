import styled from "styled-components/macro";
import { flexCenter } from "../../../../../../../../../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../../../../../../../../../styles/global-style";

export const UpdateLevelWrokoutsModalAutoSelectContainer = styled.div`
  width: 540px;
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 5px;
  position: relative;
`;

export const UpdateLevelWrokoutsModalAutoSelectDropdownToggle = styled.div`
  ${flexCenter}
  padding: 8px;
  cursor: pointer;
`;

export const UpdateLevelWrokoutsModalAutoSelectDropdownToggleIcon = styled.span<{
  $showDropdown: boolean;
}>`
  margin-left: auto;
  transform: rotate(
    ${({ $showDropdown }) => ($showDropdown ? "-180deg" : "0")}
  );
  transition: ${transition};
`;

export const UpdateLevelWrokoutsModalAutoSelectSearchBox = styled.div`
  width: 95%;
  background: ${({ theme }) => theme.colors.inputColor};
  border-radius: 8px;
  padding: 4px 8px;
  ${flexCenter}
  gap: 0px 8px;

  span {
    color: ${({ theme }) => theme.colors.textColor};
    font-size: 20px;
  }
`;

export const UpdateLevelWrokoutsModalAutoSelectSearchInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  outline: none;
`;
