import { useFormContext } from "react-hook-form";
import { UpdateExerciseInputs } from "./types/update-exercise-inputs";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../styles/workouts-form-header/workouts-form-header-styles";
import { Button } from "../../../../../styles/classes/reusable-classes";

export const UpdateExerciseHeader = () => {
  const { watch } = useFormContext<UpdateExerciseInputs>();
  const exerciseName = watch("name");
  const exerciseNameInArabic = watch("nameInArabic");

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Exercise</span>
          {exerciseNameInArabic}{" "}
          {exerciseName !== "" && exerciseName ? `(${exerciseName})` : ``}
        </p>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="submit">Save</Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
