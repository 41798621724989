import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useContentModuleMode } from "../../../context/content-module-mode-context";
import { ReadContentModule } from "../../../components/read-content-module/read-content-module";
import { ContentModuleLessonMobilePreview } from "../../../components/content-module-lesson-mobile-preview/content-module-lesson-mobile-preview";
import { LessonReadModeCards } from "./lesson-read-mode-cards/lesson-read-mode-cards";
import { UpdateContentModule } from "../../../components/update-content-module/update-content-module";
import { LessonUpdateModeCards } from "./lesson-update-mode-cards/lesson-update-mode-cards";
import { useCardPartsLoading } from "../../context/card-parts-loading-context";
import { LessonReadModeQuestions } from "./lesson-read-mode-questions/lesson-read-mode-questions";
import { LessonUpdateModeQuestions } from "./lesson-update-mode-questions/lesson-update-mode-questions";
import { ContentModuleLessonTabsProvider } from "../../../context/content-module-lesson-tabs-context";
import { ContentCourseModuleLessonTabs } from "../../../components/content-course-module-lesson-tabs/content-course-module-lesson-tabs";

export const ContentCourseModuleLessonModes = () => {
  const {
    contentModuleMode,
    onContentModuleModeChangeToUpdate,
  } = useContentModuleMode();

  const location = useLocation();

  const { courseID, moduleID } = useParams();
  const parentCourseID = parseInt(courseID!);
  const parentModuleID = parseInt(moduleID!);

  const { cardPartsLoading } = useCardPartsLoading();

  useEffect(() => {
    if (location.pathname.includes("update")) {
      onContentModuleModeChangeToUpdate(contentModuleMode.mode.contentModule);
    }
  }, [
    location,
    onContentModuleModeChangeToUpdate,
    contentModuleMode.mode.contentModule,
  ]);

  if (contentModuleMode.mode.kind === "Read") {
    return (
      <ContentModuleLessonTabsProvider>
        <ReadContentModule
          contentModuleDTOType="Lesson"
          contentModuleLessonReadModeComponents={{
            lessonReadModeCards: (
              <ContentCourseModuleLessonTabs
                cards={
                  <LessonReadModeCards
                    contentModuleCards={
                      contentModuleMode.mode.contentModule.contentModuleCards
                    }
                  />
                }
                questions={
                  <LessonReadModeQuestions
                    contentModuleQuestions={
                      contentModuleMode.mode.contentModule
                        .contentModuleQuestions
                    }
                  />
                }
              />
            ),
            lessonReadModeMobilePreview: (
              <ContentModuleLessonMobilePreview
                kind={{
                  mode: "Read",
                  contentModuleDTO: contentModuleMode.mode.contentModule,
                }}
              />
            ),
          }}
        />
      </ContentModuleLessonTabsProvider>
    );
  }

  return (
    <ContentModuleLessonTabsProvider>
      <UpdateContentModule
        contentModuleDTO={contentModuleMode.mode.contentModule}
        routeAfterUpdate={`/content/courses/course/${parentCourseID}/module/${parentModuleID}/lesson/${contentModuleMode.mode.contentModule.id}`}
        contentModuleLessonUpdateModeComponents={{
          lessonUpdateModeCards: (
            <ContentCourseModuleLessonTabs
              cards={<LessonUpdateModeCards />}
              questions={<LessonUpdateModeQuestions />}
            />
          ),
          lessonUpdateModeMobilePreview: (
            <ContentModuleLessonMobilePreview
              kind={{
                mode: "Update",
                lessonTitle: contentModuleMode.mode.contentModule.name,
              }}
            />
          ),
          lessonCardPartsHeaderLoading: cardPartsLoading,
        }}
      />
    </ContentModuleLessonTabsProvider>
  );
};
