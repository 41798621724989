import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { UpdateExerciseInputs } from "./types/update-exercise-inputs";
import { useToastService } from "../../../../../context/toast-service-context";
import { usePutExercise } from "../../../../../hooks/exercises/use-put-exercise";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
} from "../../../styles/workouts-common-styles";
import { useExerciseState } from "../context/exercise-state-context";
import { UpdateExerciseLeftControllers } from "./controllers/update-exercise-left-controllers/update-exercise-left-controllers";
import { UpdateExerciseRightControllers } from "./controllers/update-exercise-right-controllers/update-exercise-right-controllers";
import {
  useExercise,
  useReloadExercise,
} from "../context/loadable-exercise-context";
import { CreateOrUpdateExerciseDTO } from "../../../../../models/exercises-dtos/create-or-update-exercise-dto";
import { UpdateExerciseHeader } from "./update-exercise-form-header";
import { formatExerciseDuration } from "../utils/format-exercise-duration";

export const UpdateExercise = () => {
  const { exerciseDTO } = useExercise();

  const updateExerciseFormMethods = useForm<UpdateExerciseInputs>({
    defaultValues: {
      nameInArabic: exerciseDTO.name.defaultText,
      name: exerciseDTO.name.translations[0]?.translation,
      durationInSeconds: formatExerciseDuration(exerciseDTO.durationInSeconds)
        .secs,
      durationInMins: formatExerciseDuration(exerciseDTO.durationInSeconds)
        .mins,
      descriptionInArabic: exerciseDTO.description.defaultText,
      description: exerciseDTO.description.translations[0]?.translation,
      thumbnailUploadedFileId: exerciseDTO.thumbnailUploadedFileId,
      mediaUploadedFileId: exerciseDTO.mediaUploadedFileId,
      burnedKCals: exerciseDTO.burnedKCals,
      restrictions: exerciseDTO.restrictions,
    },
  });
  const navigate = useNavigate();

  const putExercise = usePutExercise();
  const { showToast } = useToastService();
  const { setExerciseState } = useExerciseState();
  const reloadExercise = useReloadExercise();

  const onSubmit = updateExerciseFormMethods.handleSubmit(async (inputs) => {
    try {
      const putExerciseDTO: CreateOrUpdateExerciseDTO = {
        name: {
          defaultLanguageId: 2,
          defaultText: inputs.nameInArabic,
          translations: inputs.name
            ? [
                {
                  languageId: 1,
                  translation: inputs.name,
                },
              ]
            : [],
        },
        burnedKCals: Number(inputs.burnedKCals),
        description: {
          defaultLanguageId: 2,
          defaultText: inputs.descriptionInArabic,
          translations: inputs.description
            ? [
                {
                  languageId: 1,
                  translation: inputs.description,
                },
              ]
            : [],
        },
        durationInSeconds:
          Number(inputs.durationInMins * 60) + Number(inputs.durationInSeconds),
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId!,
        mediaUploadedFileId: inputs.mediaUploadedFileId!,
        restrictions: [],
      };

      await putExercise(exerciseDTO.id, putExerciseDTO).then((res) => {
        navigate(`/workouts/exercise/${res.id}`);
        setExerciseState("Read");
      });
      showToast("Success", "Success Updating Exercise");
      reloadExercise();
    } catch (error) {
      showToast("Error", "Failed to Update Exercise :(");
    }
  });

  return (
    <FormProvider {...updateExerciseFormMethods}>
      <form onSubmit={onSubmit}>
        <UpdateExerciseHeader />
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <UpdateExerciseLeftControllers />
            <UpdateExerciseRightControllers />
          </WorkoutsFormControllersContainer>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
