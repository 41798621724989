import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../../../../../components/modal/modal";
import { useModalHelpers } from "../../../../../../../../../components/modal/use-modal-helpers";
import { Button } from "../../../../../../../../../styles/classes/reusable-classes";
import { AddLevelWorkoutsModalBody } from "./add-level-workouts-modal-body/add-level-workouts-modal-body";
import { AddLevelWorkoutInputs } from "./types/add-level-workout-inputs";

type AddLevelWorkoutsModalProps = {
  day: number;
  weekNumber: number;
};

export const AddLevelWorkoutsModal = (props: AddLevelWorkoutsModalProps) => {
  const { day, weekNumber } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const levelWorkoutsFormMethods = useForm<AddLevelWorkoutInputs>();

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          openModal();
          levelWorkoutsFormMethods.setValue("dayNumber", day);
        }}
      >
        Workout Day
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...levelWorkoutsFormMethods}>
          <AddLevelWorkoutsModalBody
            key={modalBodyKey}
            closeModal={closeModal}
            day={day}
            weekNumber={weekNumber}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
