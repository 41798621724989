import styled from "styled-components/macro";
import {
  flexCenter,
  regularSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../styles/global-style";

export const WorkoutsNavbarNav = styled.nav`
  ${flexCenter}
  gap: 0px 16px;
  position: relative;
`;

export const WorkoutsNavbarLink = styled.div<{ $active: boolean }>`
  position: relative;

  a {
    ${regularSemiBoldFont}
    color: ${({ theme, $active }) =>
      $active ? theme.colors.buttonColorAlt : theme.colors.textColor};
    text-decoration: none;
  }

  ::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: ${({ $active }) => ($active ? "100%" : "0px")};
    height: 4px;
    background: ${({ theme }) => theme.colors.primaryColorAlt};
    transition: ${transition};
  }
`;
