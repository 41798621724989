import { Outlet } from "react-router-dom";
import { WorkoutsScreenHeader } from "./workouts-screen-header/workouts-screen-header";

export const Workouts = () => {
  return (
    <>
      <WorkoutsScreenHeader />
      <Outlet />
    </>
  );
};
