import { useCallback } from "react";
import {
  AddLevelWorkoutsContainer,
  AddLevelWorkoutsHeader,
} from "./styled-add-level-workouts-modal-body";
import { ModalForm } from "../../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../../../styles/classes/reusable-classes";
import { AddLevelWorkoutsModalAutoSelect } from "./add-level-workouts-modal-auto-select/add-level-workouts-modal-auto-select";
import { ExerciseDTO } from "../../../../../../../../../../models/exercises-dtos/exercise-dto";
import { AddLevelWorkoutModalAutoSelectSearchTermProvider } from "./add-level-workouts-modal-auto-select/context/add-level-workout-modal-auto-select-search-term-context";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AddLevelWorkoutsModalBodyNameController } from "./controllers/add-level-workouts-modal-body-name-controller";
import { AddLevelWorkoutsModalBodyDescriptionController } from "./controllers/add-level-workouts-modal-body-description-controller";
import { AddLevelWorkoutsModalBodyList } from "./add-level-workouts-modal-body-list/add-level-workouts-modal-body-list";
import { AddLevelWorkoutInputs } from "../types/add-level-workout-inputs";
import { useAddLevelWorkoutsWeeksFormHandlers } from "../../../context/add-level-workouts-weeks-form-handlers-context";
import { AddLevelWorkoutsModalBodyNameArabicController } from "./controllers/add-level-workouts-modal-body-name-arabic-controller";
import { AddLevelWorkoutsModalBodyDescriptionArabicController } from "./controllers/add-level-workouts-modal-body-description-arabic-controller";
import { CreateWorkoutDTO } from "../../../../../../../../../../models/workout-programs-dtos/create-workout-program-dtos/create-workout-dto";

type AddLevelWorkoutsModalBodyProps = {
  closeModal: () => void;
  day: number;
  editMode?: boolean;
  weekNumber: number;
};

export const AddLevelWorkoutsModalBody = (
  props: AddLevelWorkoutsModalBodyProps
) => {
  const { closeModal, day, editMode, weekNumber } = props;

  const {
    handleAppendWorkoutsToWeek,
    getWeekWorkouts,
  } = useAddLevelWorkoutsWeeksFormHandlers();
  const workouts = getWeekWorkouts(weekNumber);

  const { control, watch, setValue, getValues } = useFormContext<
    AddLevelWorkoutInputs
  >();
  const { append, move, remove } = useFieldArray<AddLevelWorkoutInputs>({
    control,
    name: `workoutExercises`,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const workoutFields: CreateWorkoutDTO = {
    dayNumber: watch("dayNumber"),
    name: {
      defaultLanguageId: 2,
      defaultText: watch("nameInArabic"),
      translations:
        watch("name") !== "" && watch("name")
          ? [
              {
                languageId: 1,
                translation: watch("name"),
              },
            ]
          : [],
    },
    description: {
      defaultLanguageId: 2,
      defaultText: watch("descriptionInArabic"),
      translations:
        watch("description") !== "" && watch("description")
          ? [
              {
                languageId: 1,
                translation: watch("description"),
              },
            ]
          : [],
    },
    workoutExercises: watch("workoutExercises"),
  };

  const handleAppendWorkouts = useCallback(() => {
    const updatedWorkouts = workouts.map((workout) =>
      workout.dayNumber === workoutFields.dayNumber
        ? { ...workout, ...workoutFields } // Update the existing workout
        : workout
    );

    handleAppendWorkoutsToWeek(
      weekNumber,
      editMode ? updatedWorkouts : [...workouts, workoutFields]
    );
  }, [
    workoutFields,
    workouts,
    handleAppendWorkoutsToWeek,
    editMode,
    weekNumber,
  ]);

  const handleAppendWorkoutExercises = useCallback(
    (exerciseDTO: ExerciseDTO, index: number) => {
      if (
        !workoutFields.workoutExercises?.some(
          (watcherItem) => watcherItem.exerciseId === exerciseDTO.id
        )
      ) {
        append({
          exerciseId: exerciseDTO.id,
          uiOrder: index + 1,
        });

        setValue(
          "workoutExercises",
          getValues("workoutExercises")?.map((exercise, exerciseIndex) => ({
            ...exercise,
            uiOrder: exerciseIndex + 1,
          }))
        );
      }
    },
    [append, workoutFields.workoutExercises, getValues, setValue]
  );

  return (
    <ModalForm width={800} height={800} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader
        onModalClose={closeModal}
        title={
          editMode
            ? `Edit exercises for Day ${day}`
            : `Add exercises for Day ${day}`
        }
      />
      <AddLevelWorkoutsContainer>
        <AddLevelWorkoutsHeader>
          <AddLevelWorkoutsModalBodyNameArabicController />
          <AddLevelWorkoutsModalBodyNameController />
          <AddLevelWorkoutsModalBodyDescriptionArabicController />
          <AddLevelWorkoutsModalBodyDescriptionController />
          <AddLevelWorkoutModalAutoSelectSearchTermProvider>
            <AddLevelWorkoutsModalAutoSelect
              append={handleAppendWorkoutExercises}
            />
          </AddLevelWorkoutModalAutoSelectSearchTermProvider>
        </AddLevelWorkoutsHeader>
        <AddLevelWorkoutsModalBodyList move={move} remove={remove} />
      </AddLevelWorkoutsContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            handleAppendWorkouts();
            closeModal();
          }}
        >
          {editMode ? "Update" : "Confirm"}
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
