import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";

export const ReadProgramNameController = () => {
  const { workoutProgramDTO } = useWorkoutProgram();

  const programNameInEnglish =
    workoutProgramDTO.name.translations[0]?.translation;

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px" $isOptional>
        Program Name
        <span>English</span>
      </UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          width={"100%"}
          dir="auto"
          type="text"
          readOnly
          defaultValue={
            programNameInEnglish !== "" && programNameInEnglish
              ? programNameInEnglish
              : ""
          }
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
