import { useDropdown } from "../../../../../../../../../../../components/dropdown/hooks/use-dropdown";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../../../styles/classes/reusable-classes";
import { useAddLevelWorkoutModalAutoSelectSearchTerm } from "./context/add-level-workout-modal-auto-select-search-term-context";
import { ExerciseDTO } from "../../../../../../../../../../../models/exercises-dtos/exercise-dto";
import {
  AddLevelWrokoutsModalAutoSelectContainer,
  AddLevelWrokoutsModalAutoSelectDropdownToggle,
  AddLevelWrokoutsModalAutoSelectDropdownToggleIcon,
  AddLevelWrokoutsModalAutoSelectSearchBox,
  AddLevelWrokoutsModalAutoSelectSearchInput,
} from "./styled-add-level-workouts-modal-auto-select";
import { AddLevelWorkoutAutoSelectList } from "./add-level-workouts-modal-auto-select-list/add-level-workouts-modal-auto-select-list";

type AddLevelWorkoutsModalAutoSelectProps = {
  append: (exerciseDTO: ExerciseDTO, index: number) => void;
};

export const AddLevelWorkoutsModalAutoSelect = (
  props: AddLevelWorkoutsModalAutoSelectProps
) => {
  const { append } = props;

  const { dropdownContainerRef, showDropdown, toggleDropdown } = useDropdown();

  const {
    searchTerm,
    handleOnSearchInputChange,
  } = useAddLevelWorkoutModalAutoSelectSearchTerm();

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel>Exercises</UXInputLabel>
      <AddLevelWrokoutsModalAutoSelectContainer ref={dropdownContainerRef}>
        <AddLevelWrokoutsModalAutoSelectDropdownToggle onClick={toggleDropdown}>
          <AddLevelWrokoutsModalAutoSelectSearchBox>
            <span className="material-symbols-outlined">search</span>
            <AddLevelWrokoutsModalAutoSelectSearchInput
              placeholder="Search exercises..."
              type="search"
              value={searchTerm}
              onChange={(event) => handleOnSearchInputChange(event)}
            />
          </AddLevelWrokoutsModalAutoSelectSearchBox>
          <AddLevelWrokoutsModalAutoSelectDropdownToggleIcon
            className="material-symbols-outlined"
            $showDropdown={showDropdown}
          >
            expand_more
          </AddLevelWrokoutsModalAutoSelectDropdownToggleIcon>
        </AddLevelWrokoutsModalAutoSelectDropdownToggle>
        <AddLevelWorkoutAutoSelectList
          showDropdown={showDropdown}
          append={append}
        />
      </AddLevelWrokoutsModalAutoSelectContainer>
    </UXInputContainer>
  );
};
