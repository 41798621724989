import styled from "styled-components/macro";
import {
  lightShadowAlt,
  transition,
} from "../../../../../../../../../../../../../styles/global-style";

export const UpdateLevelWorkoutsAutoSelectListList = styled.ul<{
  $showDropdown: boolean;
}>`
  max-height: ${({ $showDropdown }) => ($showDropdown ? "206px" : "0px")};
  height: fit-content;
  overflow-y: ${({ $showDropdown }) => ($showDropdown ? "auto" : "hidden")};
  overflow-x: hidden;
  width: 100%;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.z300};
  background: ${({ theme }) => theme.colors.containerColor};
  ${lightShadowAlt}
  border-radius: 1rem;
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  transition: ${({ $showDropdown }) => ($showDropdown ? transition : "")};
  margin: 0px;
`;
