import { Button } from "../../../../../../../../../../styles/classes/reusable-classes";
import { LevelWorkoutsContainerListItem } from "../../../../../../../../styles/level-workouts-styles/level-workouts-container-styles";
import { useUpdateLevelWorkoutsWeeksFormHandlers } from "../../context/update-level-workouts-weeks-form-handlers-context";
import { UpdateLevelWorkoutsModal } from "../update-level-workouts-modal/update-level-workouts-modal";
import { UpdateLevelWorkoutsCard } from "./update-level-workouts-card";
import { UpdateLevelWorkoutsRestDayCard } from "./update-level-workouts-rest-day-card";

type UpdateLevelWorkoutsContainerCardsProps = {
  day: number;
  weekNumber: number;
  dayIndex: number;
};

export const UpdateLevelWorkoutsContainerCards = (
  props: UpdateLevelWorkoutsContainerCardsProps
) => {
  const { day, weekNumber, dayIndex } = props;

  const {
    weeks,
    handleAppendWorkoutToWeek,
    getWeekWorkouts,
  } = useUpdateLevelWorkoutsWeeksFormHandlers();

  const workouts = getWeekWorkouts(weekNumber);

  const isWorkoutDay =
    workouts && workouts.some((item) => item.dayNumber === day);

  const isRestDay =
    isWorkoutDay &&
    workouts.some(
      (item) =>
        item.dayNumber === day &&
        item.name.defaultText === "Rest Day" &&
        item.description.defaultText === "This day is For Rest"
    );

  const isDisabledToAddWorkouts = () => {
    const firstDaysOfEachWeek = weeks.map((week) => week.days[0]);

    if (firstDaysOfEachWeek.includes(day)) {
      return false;
    }

    const previousDay = day - 1;

    const isPreviousDayValid = workouts?.some(
      (item) => item.dayNumber === previousDay
    );

    return !isPreviousDayValid;
  };

  if (isRestDay) {
    return (
      <UpdateLevelWorkoutsRestDayCard
        key={`RestDay ${day} Workout`}
        dayNumber={day}
        weekNumber={weekNumber}
        dayIndex={dayIndex}
      />
    );
  }

  if (isWorkoutDay) {
    return (
      <UpdateLevelWorkoutsCard
        key={`Day ${day} Workout`}
        dayNumber={day}
        weekNumber={weekNumber}
        dayIndex={dayIndex}
      />
    );
  }

  return (
    <LevelWorkoutsContainerListItem
      key={day}
      $disabled={isDisabledToAddWorkouts() && !isRestDay && !isWorkoutDay}
    >
      <p>Day {day}</p>
      <UpdateLevelWorkoutsModal day={day} weekNumber={weekNumber} />
      <Button
        type="button"
        outlined
        onClick={() =>
          handleAppendWorkoutToWeek(weekNumber, {
            dayNumber: day,
            name: {
              defaultLanguageId: 2,
              defaultText: "Rest Day",
              translations: [],
            },
            description: {
              defaultLanguageId: 2,
              defaultText: "This day is For Rest",
              translations: [],
            },
            id: 1000 + day,
          })
        }
      >
        Rest Day
      </Button>
    </LevelWorkoutsContainerListItem>
  );
};
