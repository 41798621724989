import { useFormContext } from "react-hook-form";
import { ContentModuleUpdateModeInputs } from "../../../../context/content-module-mode-context";
import { useConentModuleMobilePreviewCarousel } from "../../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewQuestionsList } from "../../mobile-preview-lesson-questions-styles";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { UpdateContentModuleLessonMobilePreviewQuestion } from "./update-content-module-lesson-mobile-preview-question";

export const UpdateContentModuleLessonMobilePreviewQuestions = () => {
  const { watch } = useFormContext<ContentModuleUpdateModeInputs>();
  const contentModuleQuestions = watch("contentModuleQuestions");

  const { currentCard } = useConentModuleMobilePreviewCarousel();

  if (!contentModuleQuestions || contentModuleQuestions.length === 0) {
    return (
      <ContentModuleLessonMobilePreviewQuestionsList>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Questions Added Yet"
          title=""
        />
      </ContentModuleLessonMobilePreviewQuestionsList>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewQuestionsList>
      {contentModuleQuestions.map((question, index) => (
        <UpdateContentModuleLessonMobilePreviewQuestion
          key={index}
          question={question}
          activeCard={index === currentCard}
        />
      ))}
    </ContentModuleLessonMobilePreviewQuestionsList>
  );
};
