import { useCallback } from "react";
import { useModalHelpers } from "../../../../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../../../../context/toast-service-context";
import {
  useReloadWorkoutProgram,
  useWorkoutProgram,
} from "../../context/loadable-workout-program-context";
import { usePutWorkoutProgram } from "../../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { PutWorkoutProgramDTO } from "../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";
import { GenericActionsListItemItem } from "../../../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../../../components/modal/modal";
import { DeleteModal } from "../../../../../../components/delete-modal/delete-modal";

type DeleteProgramModalProps = {
  levelID: number;
};

export const DeleteLevelModal = (props: DeleteProgramModalProps) => {
  const { levelID } = props;

  const { workoutProgramDTO } = useWorkoutProgram();
  const reloadWorkoutProgram = useReloadWorkoutProgram();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === levelID
  );

  const putWorkoutProgram = usePutWorkoutProgram();

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const { showToast } = useToastService();

  const onDelete = useCallback(async () => {
    try {
      const putWorkoutProgramDTO: PutWorkoutProgramDTO = {
        ...workoutProgramDTO,
        workoutProgramLevels: workoutProgramDTO.workoutProgramLevels
          ?.filter((level) => level.id !== levelID)
          .map((item) => ({
            ...item,
            workouts: item.workouts?.map((workout) => ({
              ...workout,
              workoutExercises: workout.workoutExercises?.map((exercise) => ({
                exerciseId: exercise.exercise.id,
                uiOrder: exercise.uiOrder,
              })),
            })),
          })),
      };

      await putWorkoutProgram(workoutProgramDTO.id, putWorkoutProgramDTO);

      showToast("Success", "Program Deleted Successfully!");
      closeModal();
      reloadWorkoutProgram();
    } catch (error) {
      showToast("Error", "Failed to Delete Program :(");
    }
  }, [
    closeModal,
    reloadWorkoutProgram,
    putWorkoutProgram,
    showToast,
    workoutProgramDTO,
    levelID,
  ]);

  return (
    <>
      <GenericActionsListItemItem isDanger onClick={openModal}>
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title={`Level ${currentLevel?.name}`}
          onDelete={onDelete}
          onModalClose={closeModal}
        />
      </Modal>
    </>
  );
};
