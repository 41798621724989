import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { ContentModuleUpdateModeInputs } from "../../../../../../context/content-module-mode-context";
import {
  UpdateLessonQuestionCardBodyQuestionAnswerContainer,
  UpdateLessonQuestionCardBodyQuestionsTitle,
} from "./styled-lesson-update-mode-question-card-body";
import {
  Button,
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";

type LessonUpdateModeQuestionCardBodyProps = {
  cardIndex: number;
};

export const LessonUpdateModeQuestionCardBody = (
  props: LessonUpdateModeQuestionCardBodyProps
) => {
  const { cardIndex } = props;

  const { control, watch, setValue } = useFormContext<
    ContentModuleUpdateModeInputs
  >();
  const choices = watch(`contentModuleQuestions.${cardIndex}.choices`);

  const { append, remove } = useFieldArray<ContentModuleUpdateModeInputs>({
    control,
    name: `contentModuleQuestions.${cardIndex}.choices`,
  });

  return (
    <div className="card-parts-list">
      <UpdateLessonQuestionCardBodyQuestionsTitle>
        Correct answer
      </UpdateLessonQuestionCardBodyQuestionsTitle>
      {choices.map((_, index) => (
        <UpdateLessonQuestionCardBodyQuestionAnswerContainer key={index}>
          <Controller
            name={`contentModuleQuestions.${cardIndex}.choices.${index}.isCorrect`}
            control={control}
            render={({ field: { onChange } }) => (
              <input
                type="radio"
                checked={choices[index].isCorrect}
                onChange={() => {
                  const updatedChoices = choices.map((choice, i) => ({
                    ...choice,
                    isCorrect: i === index,
                  }));

                  setValue(
                    `contentModuleQuestions.${cardIndex}.choices`,
                    updatedChoices
                  );

                  onChange(updatedChoices[index].isCorrect);
                }}
              />
            )}
          />
          <Controller
            name={`contentModuleQuestions.${cardIndex}.choices.${index}.title`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <UXInputWrapper>
                <UXInputInput
                  placeholder="Enter Answer"
                  value={
                    value === undefined
                      ? ""
                      : choices[index].title !== ""
                      ? choices[index].title
                      : value
                  }
                  onChange={(e) =>
                    onChange(e.target.value === undefined ? "" : e.target.value)
                  }
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                      append({
                        title: "",
                        isCorrect: false,
                        uiOrder: choices.length + 1,
                      });
                    }
                  }}
                />
              </UXInputWrapper>
            )}
          />
          <span
            className="remove-lesson-card-part-icon"
            onClick={() => remove(index)}
          >
            <img
              className="cursor-pointer"
              src="/img/trash.svg"
              alt="Trash Icon"
            />
          </span>
        </UpdateLessonQuestionCardBodyQuestionAnswerContainer>
      ))}
      <Button
        outlineNoBorder
        flex
        type="button"
        onClick={() =>
          append({ title: "", isCorrect: false, uiOrder: choices.length + 1 })
        }
        style={{
          marginLeft: "auto",
        }}
      >
        <span className="material-symbols-outlined">add</span>Add Answer
      </Button>
      <Controller
        control={control}
        name={`contentModuleQuestions.${cardIndex}.correctAnswerExplination`}
        render={({ field: { onChange, value } }) => (
          <UXInputContainer $textareaDimentions style={{ marginTop: "24px" }}>
            <UXInputLabel $width="90px">Explanation</UXInputLabel>
            <UXInputWrapper $textareaDimentions>
              <UXInputTextarea
                placeholder="Enter Explanation"
                value={value === undefined ? "" : value}
                onChange={(e) =>
                  onChange(e.target.value === undefined ? "" : e.target.value)
                }
              />
            </UXInputWrapper>
          </UXInputContainer>
        )}
      />
    </div>
  );
};
