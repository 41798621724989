import { useParams } from "react-router-dom";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
} from "../../../../../styles/workouts-common-styles";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";
import { useLevelState } from "../context/level-state-context";
import { ReadLevelLeftControllers } from "./controllers/read-level-left-controllers/read-level-left-controllers";
import { ReadLevelCoverPhotoController } from "./controllers/read-level-right-controllers/read-level-cover-photo-controller";
import { ReadLevelWorkouts } from "./read-level-workouts/read-level-workouts";
import { ReadLevelWorkoutsWeeksFormHandlersProvider } from "./read-level-workouts/context/read-level-workouts-weeks-form-handlers-context";
import { FormProvider, useForm } from "react-hook-form";
import { ReadLevelWorkoutsWeeks } from "./read-level-workouts/types/read-level-workout-weeks";
import { generateWeeks } from "./utils/generate-weeks";
import { EmptyState } from "../../../../../../../components/empty-state/empty-state";
import { ReadLevelFormHeader } from "./read-level-form-header";

export const ReadLevelForm = () => {
  const { setLevelState } = useLevelState();

  const { workoutProgramDTO } = useWorkoutProgram();

  const { levelID } = useParams();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );

  const readLevelWorkoutsWeeksFormMethods = useForm<ReadLevelWorkoutsWeeks>({
    defaultValues: {
      weeks:
        currentLevel?.workouts?.length === 0
          ? []
          : generateWeeks(currentLevel?.workouts ? currentLevel.workouts : []),
    },
  });

  return (
    <>
      <ReadLevelFormHeader />
      <WorkoutsFormContainer $level>
        <WorkoutsFormControllersContainer>
          <ReadLevelLeftControllers currentLevel={currentLevel} />
          <WorkoutsFormFormSubContainer>
            <ReadLevelCoverPhotoController />
          </WorkoutsFormFormSubContainer>
        </WorkoutsFormControllersContainer>
        <FormProvider {...readLevelWorkoutsWeeksFormMethods}>
          <ReadLevelWorkoutsWeeksFormHandlersProvider>
            <ReadLevelWorkouts />
          </ReadLevelWorkoutsWeeksFormHandlersProvider>
        </FormProvider>
        {readLevelWorkoutsWeeksFormMethods.getValues("weeks").length === 0 && (
          <EmptyState
            button={
              <Button type="button" onClick={() => setLevelState("Update")}>
                Add Workouts
              </Button>
            }
            text="No levels found for this program"
            hasHeader={false}
            hasSearch={false}
            title=""
          />
        )}
      </WorkoutsFormContainer>
    </>
  );
};
