import { useDropdown } from "../../../../../../../../../../../../components/dropdown/hooks/use-dropdown";
import { ExerciseDTO } from "../../../../../../../../../../../../models/exercises-dtos/exercise-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../../../../../styles/classes/reusable-classes";
import { useUpdateLevelWorkoutModalAutoSelectSearchTerm } from "./context/update-level-workout-modal-auto-select-search-term-context";
import {
  UpdateLevelWrokoutsModalAutoSelectContainer,
  UpdateLevelWrokoutsModalAutoSelectDropdownToggle,
  UpdateLevelWrokoutsModalAutoSelectDropdownToggleIcon,
  UpdateLevelWrokoutsModalAutoSelectSearchBox,
  UpdateLevelWrokoutsModalAutoSelectSearchInput,
} from "./styled-update-level-workouts-modal-auto-select";
import { UpdateLevelWorkoutAutoSelectList } from "./update-level-workouts-modal-auto-select-list/update-level-workouts-modal-auto-select-list";

type UpdateLevelWorkoutsModalAutoSelectProps = {
  append: (exerciseDTO: ExerciseDTO, index: number) => void;
};

export const UpdateLevelWorkoutsModalAutoSelect = (
  props: UpdateLevelWorkoutsModalAutoSelectProps
) => {
  const { append } = props;

  const { dropdownContainerRef, showDropdown, toggleDropdown } = useDropdown();

  const {
    searchTerm,
    handleOnSearchInputChange,
  } = useUpdateLevelWorkoutModalAutoSelectSearchTerm();

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel>Exercises</UXInputLabel>
      <UpdateLevelWrokoutsModalAutoSelectContainer ref={dropdownContainerRef}>
        <UpdateLevelWrokoutsModalAutoSelectDropdownToggle
          onClick={toggleDropdown}
        >
          <UpdateLevelWrokoutsModalAutoSelectSearchBox>
            <span className="material-symbols-outlined">search</span>
            <UpdateLevelWrokoutsModalAutoSelectSearchInput
              placeholder="Search exercises..."
              type="search"
              value={searchTerm}
              onChange={(event) => handleOnSearchInputChange(event)}
            />
          </UpdateLevelWrokoutsModalAutoSelectSearchBox>
          <UpdateLevelWrokoutsModalAutoSelectDropdownToggleIcon
            className="material-symbols-outlined"
            $showDropdown={showDropdown}
          >
            expand_more
          </UpdateLevelWrokoutsModalAutoSelectDropdownToggleIcon>
        </UpdateLevelWrokoutsModalAutoSelectDropdownToggle>
        <UpdateLevelWorkoutAutoSelectList
          showDropdown={showDropdown}
          append={append}
        />
      </UpdateLevelWrokoutsModalAutoSelectContainer>
    </UXInputContainer>
  );
};
