import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../../../styles/classes/reusable-classes";

type ReadLevelNameControllerProps = {
  levelName?: string;
};

export const ReadLevelNameController = (
  props: ReadLevelNameControllerProps
) => {
  const { levelName } = props;

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px" $isOptional>
        Level Name
        <span>English</span>
      </UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          readOnly
          width="100%"
          dir="auto"
          type="text"
          placeholder="Enter Level Name"
          defaultValue={levelName}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
