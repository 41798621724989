import {
  LevelWorkoutsContainerHeader,
  LevelWorkoutsContainerHeaderActions,
  LevelWorkoutsContainerTitle,
} from "../../../../../../../../styles/level-workouts-styles/level-workouts-container-styles";
import { useUpdateLevelWorkoutsWeeksFormHandlers } from "../../context/update-level-workouts-weeks-form-handlers-context";
import { WorkoutWeek } from "../../types/update-level-workouts-weeks";

type UpdateLevelWorkoutsWeeksHeaderProps = {
  showDropdown: boolean;
  toggleDropdown: VoidFunction;
  week: WorkoutWeek;
  weekIndex: number;
};

export const UpdateLevelWorkoutsWeeksHeader = (
  props: UpdateLevelWorkoutsWeeksHeaderProps
) => {
  const { toggleDropdown, week, weekIndex, showDropdown } = props;

  const {
    handleRemoveWeek,
    handleSortWeeks,
    weekDragItem,
    weekDraggedOverItem,
    handleDuplicateWeek,
  } = useUpdateLevelWorkoutsWeeksFormHandlers();

  return (
    <LevelWorkoutsContainerHeader
      draggable
      onDragStart={() => (weekDragItem.current = week.weekNumber - 1)}
      onDragEnter={() => (weekDraggedOverItem.current = week.weekNumber - 1)}
      onDragEnd={handleSortWeeks}
      onDragOver={(e) => e.preventDefault()}
    >
      <span className="material-symbols-outlined level-workouts-container-header-action-move">
        menu
      </span>
      <LevelWorkoutsContainerTitle>
        Days {week.days[0]} to {week.days[week.days.length - 1]}
      </LevelWorkoutsContainerTitle>
      <LevelWorkoutsContainerHeaderActions $showDropdown={showDropdown}>
        <span
          className="material-symbols-outlined"
          onClick={() => handleDuplicateWeek(week.weekNumber)}
        >
          content_copy
        </span>
        <span
          className="material-symbols-outlined level-workouts-container-header-action-remove"
          onClick={() => handleRemoveWeek(weekIndex)}
        >
          delete
        </span>
        <span
          className="material-symbols-outlined level-workouts-container-header-action-dropdown"
          onClick={toggleDropdown}
        >
          keyboard_arrow_down
        </span>
      </LevelWorkoutsContainerHeaderActions>
    </LevelWorkoutsContainerHeader>
  );
};
