import { Link } from "react-router-dom";
import {
  TD,
  TDContainer,
  TDLink,
} from "../../../../components/table/styled-table";
import { WorkoutsTableRowsThumbnailContainer } from "../../styles/workouts-common-styles";
import { GenericActionsList } from "../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import { DeleteProgramModal } from "./delete-workout-program-modal";
import { WorkoutProgramDTO } from "../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

type ProgramsProps = {
  programDTOs: WorkoutProgramDTO[];
};

export const ProgramsTableRows = (props: ProgramsProps) => {
  const { programDTOs } = props;

  const tableRows = programDTOs.map((programDTO) => (
    <tr key={programDTO.id}>
      <TD width={100}>
        <WorkoutsTableRowsThumbnailContainer>
          {programDTO.coverPhotoUploadedFile?.presignedUrl && (
            <img
              src={programDTO.coverPhotoUploadedFile?.presignedUrl}
              alt={`${programDTO.name}'s Workout Program Thumbnail`}
            />
          )}
        </WorkoutsTableRowsThumbnailContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDLink>
            <Link to={`/workouts/program/${programDTO.id}`}>
              {programDTO.name.defaultText}
            </Link>
          </TDLink>
        </TDContainer>
      </TD>
      <TD>
        <span>{programDTO.isDraft ? "Yes" : "No"}</span>
      </TD>
      <TD>
        <span>{programDTO.isDefault ? "Yes" : "No"}</span>
      </TD>
      <TD width={100}>
        <GenericActionsList>
          <GenericActionsListItemItem>
            <Link to={`/workouts/programs/${programDTO.id}`}>View</Link>
          </GenericActionsListItemItem>
          <DeleteProgramModal programDTO={programDTO} />
        </GenericActionsList>
      </TD>
    </tr>
  ));

  return <>{tableRows}</>;
};
