import {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

type AddLevelWorkoutModalAutoSelectSearchTerm = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  handleOnSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const AddLevelWorkoutModalAutoSelectSearchTermContext = createContext<
  AddLevelWorkoutModalAutoSelectSearchTerm | undefined
>(undefined);

type AddLevelWorkoutModalAutoSelectSearchTermProviderProps = PropsWithChildren<{}>;

export function AddLevelWorkoutModalAutoSelectSearchTermProvider(
  props: AddLevelWorkoutModalAutoSelectSearchTermProviderProps
) {
  const { children } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const handleOnSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value),
    []
  );

  return (
    <AddLevelWorkoutModalAutoSelectSearchTermContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        handleOnSearchInputChange,
      }}
    >
      {children}
    </AddLevelWorkoutModalAutoSelectSearchTermContext.Provider>
  );
}

export function useAddLevelWorkoutModalAutoSelectSearchTerm(): AddLevelWorkoutModalAutoSelectSearchTerm {
  const context = useContext(AddLevelWorkoutModalAutoSelectSearchTermContext);

  if (!context) {
    throw new Error(
      "useAddLevelWorkoutModalAutoSelectSearchTerm must be used within a AddLevelWorkoutModalAutoSelectSearchTermProvider"
    );
  }

  return context;
}
