import { WorkoutDTO } from "../../../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-dto";
import { WorkoutWeek } from "../read-level-workouts/types/read-level-workout-weeks";

export function generateWeeks(workoutDTOs: WorkoutDTO[]): WorkoutWeek[] {
  const days = workoutDTOs.map((workoutDTO) => workoutDTO.dayNumber);

  const newWeeks: WorkoutWeek[] = [];
  const minDay = Math.min(...days);
  const maxDay = Math.max(...days);
  const totalDays = Math.ceil((maxDay - minDay + 1) / 7) * 7;

  const completeDays = Array.from({ length: totalDays }, (_, i) => minDay + i);

  for (let i = 0; i < totalDays; i += 7) {
    const weekDays = completeDays.slice(i, i + 7);
    const weekWorkouts = workoutDTOs.filter((workout) =>
      weekDays.includes(workout.dayNumber)
    );

    newWeeks.push({
      weekNumber: i / 7 + 1,
      days: weekDays,
      workouts: weekWorkouts,
    });
  }

  return newWeeks.map((week) => ({
    ...week,
    workouts: week.days.map((dayNumber) => {
      const workout = week?.workouts.find((w) => w.dayNumber === dayNumber);

      return (
        workout || {
          id: 1000 + dayNumber,
          dayNumber,
          name: {
            defaultLanguageId: 2,
            defaultText: "Rest Day",
            translations: [],
          },
          description: {
            defaultLanguageId: 2,
            defaultText: "This day is For Rest",
            translations: [],
          },
        }
      );
    }),
  }));
}
