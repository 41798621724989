import styled from "styled-components/macro";
import {
  flexCenterCenter,
  flexCenterSpaceBetween,
  regularRegularFont,
} from "../../../../../../../../../../../../styles/classes/gloabl-classes";
import { LevelExerciseListItemListItem } from "../../../../../../../../../../styles/level-workouts-styles/level-exercise-list-item-styles";

export const UpdateLevelWorkoutsListDetails = styled.div`
  margin: 16px 0px 0px;
  ${flexCenterCenter}
  gap: 0px 16px;

  p {
    ${flexCenterSpaceBetween}
    margin: 0px;
    ${regularRegularFont}

    span {
      font-size: 18px;
      margin-right: 8px;
    }
  }
`;

export const UpdateLevelWorkoutsList = styled.ul`
  margin: 0px;
  height: 340px;
  overflow-y: auto;

  ${LevelExerciseListItemListItem} {
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    margin: 8px 0px;
    border-radius: 4px;
  }

  .workouts-exercises-list-item-remove-icon {
    margin-left: 8px;
  }
`;
