import styled from "styled-components/macro";
import { flexCenter } from "../../../../styles/classes/gloabl-classes";
import { UXInputLabel } from "../../../../styles/classes/reusable-classes";

export const ExerciseDurationControllersContainer = styled.div`
  ${flexCenter}

  .duration-ratio {
    margin: 0px 8px;
  }

  ${UXInputLabel} {
    margin-left: auto;
  }
`;

export const ExerciseDurationAndBurnedKCalsControllersContainer = styled.div`
  ${flexCenter}
`;
