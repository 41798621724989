import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { ExerciseDurationAndBurnedKCalsControllersContainer } from "../../../../styles/exercise-common-styles";
import { UpdateExerciseBurnedKcalsController } from "./update-exercise-burned-kcals-controller";
import { UpdateExerciseDescriptionArabicController } from "./update-exercise-description-arabic-controller";
import { UpdateExerciseDescriptionController } from "./update-exercise-description-controller";
import { UpdateExerciseDurationControllers } from "./update-exercise-duration-controllers/update-exercise-duration-controllers";
import { UpdateExerciseNameArabicController } from "./update-exercise-name-arabic-controller";
import { UpdateExerciseNameController } from "./update-exercise-name-controller";

export const UpdateExerciseLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <UpdateExerciseNameArabicController />
      <UpdateExerciseNameController />
      <ExerciseDurationAndBurnedKCalsControllersContainer>
        <UpdateExerciseBurnedKcalsController />
        <UpdateExerciseDurationControllers />
      </ExerciseDurationAndBurnedKCalsControllersContainer>
      <UpdateExerciseDescriptionArabicController />
      <UpdateExerciseDescriptionController />
    </WorkoutsFormFormSubContainer>
  );
};
