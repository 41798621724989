import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import { useLevelState } from "../context/level-state-context";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../../../styles/workouts-form-header/workouts-form-header-styles";

export const ReadLevelFormHeader = () => {
  const { levelID, programID } = useParams();
  const { setLevelState } = useLevelState();

  const { workoutProgramDTO } = useWorkoutProgram();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );

  const programNameInEnglish =
    workoutProgramDTO.name.translations[0]?.translation;

  const levelNameInEnglish = currentLevel?.name.translations[0]?.translation;

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Program</span>
          <Link dir="auto" to={`/workouts/program/${programID}`}>
            {workoutProgramDTO.name.defaultText}{" "}
            {programNameInEnglish && programNameInEnglish !== ""
              ? ` (${programNameInEnglish})`
              : ``}
          </Link>
        </p>
        {currentLevel !== undefined && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span>
                Level{" "}
                {currentLevel.uiOrder !== undefined ? currentLevel.uiOrder : ""}
              </span>
              <Link
                dir="auto"
                to={`/workouts/program/${programID}/level/${levelID}`}
              >
                {currentLevel.name.defaultText}{" "}
                {levelNameInEnglish && levelNameInEnglish !== ""
                  ? ` (${levelNameInEnglish})`
                  : ``}
              </Link>
            </p>
          </>
        )}
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="button" onClick={() => setLevelState("Update")}>
          Edit
        </Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
