import { FormProvider, useForm } from "react-hook-form";
import { ReactNode, useCallback } from "react";
import { ContentModuleDTO } from "../../../../models/content-module-dtos/content-module-dto";
import {
  ContentModuleUpdateModeInputs,
  useContentModuleMode,
} from "../../context/content-module-mode-context";
import { PutContentModuleDTO } from "../../../../models/content-module-dtos/put-content-module-dto";
import { UpdateContentModuleHeader } from "./update-content-module-header";
import { UpdateContentModuleNameController } from "./update-content-module-left-controllers/update-content-module-name-controller";
import { UpdateContentModuleDescriptionController } from "./update-content-module-left-controllers/update-content-module-description-controller";
import { UpdateContentModuleObjectiveController } from "./update-content-module-left-controllers/update-content-module-objective-controller";
import { UpdateContentModuleThumbnailController } from "./update-content-module-right-controllers/update-content-module-thumbnail-controller";
import { UpdateContentModuleTargetAudienceController } from "./update-content-module-right-controllers/update-content-module-target-audience-controller";
import { PutContentModuleCardDTO } from "../../../../models/content-module-dtos/put-content-module-card-dto";
import { PutContentModuleCardPartDTO } from "../../../../models/content-module-dtos/put-content-module-card-part-dto";
import { CardPartsLoadingState } from "../../content-course-module-lessons/context/card-parts-loading-context";
import {
  ContentModuleFormControls,
  ContentModuleLeftFormControls,
  ContentModuleRightFormControls,
} from "../../styles/content-modules-form-controllers/content-modules-form-controllers-css";
import { useToastService } from "../../../../context/toast-service-context";

type ContentCourseUpdateModeProps = {
  contentModuleDTO: ContentModuleDTO;
  routeAfterUpdate: string;
  childTable?: ReactNode;
  contentModuleLessonUpdateModeComponents?: {
    lessonUpdateModeCards: ReactNode;
    lessonUpdateModeMobilePreview: ReactNode;
    lessonCardPartsHeaderLoading?: CardPartsLoadingState;
  };
};

export const UpdateContentModule = (props: ContentCourseUpdateModeProps) => {
  const {
    contentModuleDTO,
    routeAfterUpdate,
    childTable,
    contentModuleLessonUpdateModeComponents,
  } = props;

  const contentModuleDTOType = contentModuleDTO.contentModuleType;
  const { contentModuleMode } = useContentModuleMode();

  const updateCourseFormMethods = useForm<ContentModuleUpdateModeInputs>({
    defaultValues: {
      name: contentModuleDTO.name,
      description: contentModuleDTO.description,
      objective: contentModuleDTO.objective,
      status: contentModuleDTO.isDraft ? false : true,
      thumbnailUploadedFileId: contentModuleDTO.thumbnailUploadedFileId,
      targetAudience: contentModuleDTO.contentModuleTargetAudiences.map(
        (cmta) => ({
          id: cmta.targetAudienceId,
          name: cmta.targetAudience.name,
        })
      ),
      uiOrder: contentModuleDTO.uiOrder,
      contentModuleCards: contentModuleDTO.contentModuleCards,
      contentModuleQuestions: contentModuleDTO.contentModuleQuestions?.map(
        (item, index) => ({
          ...item,
          uiOrder: index + 1,
        })
      ),
    },
  });

  const contentModuleCards = updateCourseFormMethods.watch(
    "contentModuleCards"
  );

  const newCards = useCallback(() => {
    const newCards: PutContentModuleCardDTO[] = [];
    if (contentModuleDTOType === "Lesson") {
      for (let x = 0; x < contentModuleCards.length; x++) {
        const newParts: PutContentModuleCardPartDTO[] = [];

        const contentModuleCardParts =
          contentModuleCards[x].contentModuleCardParts;

        const indexes = contentModuleCardParts.map((_, index) => index);

        for (let i = 0; i < contentModuleCardParts.length; i++) {
          newParts.push({
            ...contentModuleCards[x].contentModuleCardParts[i],
            uiOrder: indexes[i],
          });
        }

        newCards.push({
          ...contentModuleCards[x],
          contentModuleCardParts: newParts,
        });
      }
    }
    return newCards;
  }, [contentModuleCards, contentModuleDTOType]);

  const contentModuleQuestions = updateCourseFormMethods.watch(
    "contentModuleQuestions"
  );

  const { showToast } = useToastService();

  const onSubmit = updateCourseFormMethods.handleSubmit(async (inputs) => {
    if (contentModuleMode.mode.kind === "Update") {
      const isValidQuestions =
        !contentModuleQuestions || // Allow submission if undefined
        contentModuleQuestions.every(
          (question) =>
            question.choices.some((choice) => choice.isCorrect) &&
            question.choices.some(
              (choice) => choice.title !== "" && choice.title !== undefined
            ) &&
            question.question !== "" &&
            question.question !== undefined
        );

      if (!isValidQuestions) {
        showToast("Error", "Choose a correct answer for each question");
        return;
      }

      const putContentModuleDTO: PutContentModuleDTO = {
        childContentModuleIds: contentModuleDTO.childContentModules?.map(
          (ccm) => ccm.id
        )!,
        contentModuleCards: newCards(),
        description: inputs.description,
        isDraft: inputs.status ? false : true,
        name: inputs.name!,
        objective: inputs.objective,
        parentContentModuleId: contentModuleDTO.parentContentModuleId,
        targetAudienceIds: inputs.targetAudience.map((item) => item.id),
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId,
        uiOrder: inputs.uiOrder,
        contentModuleQuestions: contentModuleQuestions,
      };

      await contentModuleMode.mode.handleSubmit(
        contentModuleDTO.id,
        putContentModuleDTO,
        contentModuleDTO.contentModuleType,
        routeAfterUpdate
      );
    }
  });

  return (
    <FormProvider {...updateCourseFormMethods}>
      <form onSubmit={onSubmit}>
        <UpdateContentModuleHeader
          lessonCardPartsLoading={
            contentModuleLessonUpdateModeComponents?.lessonCardPartsHeaderLoading
          }
        />
        <ContentModuleFormControls
          $lessonsUI={contentModuleDTOType === "Lesson" ? true : false}
        >
          <ContentModuleLeftFormControls
            $lessonsUI={contentModuleDTOType === "Lesson" ? true : false}
          >
            <UpdateContentModuleNameController
              contentModuleDTO={contentModuleDTO}
            />
            {contentModuleDTOType !== "Lesson" && (
              <UpdateContentModuleDescriptionController
                contentModuleType={contentModuleDTO.contentModuleType}
              />
            )}
            {contentModuleDTOType !== "Lesson" && (
              <UpdateContentModuleObjectiveController
                contentModuleType={contentModuleDTO.contentModuleType}
              />
            )}
            {contentModuleDTOType === "Lesson" &&
              contentModuleLessonUpdateModeComponents &&
              contentModuleLessonUpdateModeComponents.lessonUpdateModeCards}
          </ContentModuleLeftFormControls>
          <ContentModuleRightFormControls
            $lessonsUI={contentModuleDTOType === "Lesson" ? true : false}
          >
            <UpdateContentModuleThumbnailController
              contentModuleDTO={contentModuleDTO}
            />
            <UpdateContentModuleTargetAudienceController />
            {contentModuleDTOType === "Lesson" &&
              contentModuleLessonUpdateModeComponents &&
              contentModuleLessonUpdateModeComponents.lessonUpdateModeMobilePreview}
          </ContentModuleRightFormControls>
        </ContentModuleFormControls>
        {childTable && childTable}
      </form>
    </FormProvider>
  );
};
