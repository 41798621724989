import styled from "styled-components/macro";
import { lgSemiBoldFont } from "../../../styles/classes/gloabl-classes";

export const WorkoutsScreenHeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.containerColor};
  padding: 16px 16px 4px;
  margin-bottom: 24px;
`;

export const WorkoutsScreenHeaderTitle = styled.p`
  margin: 0px 0px 16px;
  color: ${({ theme }) => theme.colors.titleColor};
  ${lgSemiBoldFont}
`;
