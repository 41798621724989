import { WorkoutsFormFormSubContainer } from "../../../../styles/workouts-common-styles";
import { ExerciseDurationAndBurnedKCalsControllersContainer } from "../../../styles/exercise-common-styles";
import { AddExerciseDurationControllers } from "./add-exercise-duration-controllers/add-exercise-duration-controllers";
import { ExerciseBurnedKcalsController } from "./exercise-burned-kcals-controller";
import { ExerciseDescriptionArabicController } from "./exercise-description-arabic-controller";
import { ExerciseDescriptionController } from "./exercise-description-controller";
import { ExerciseNameArabicController } from "./exercise-name-arabic-controller";
import { ExerciseNameController } from "./exercise-name-controller";

export const AddExerciseLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ExerciseNameArabicController />
      <ExerciseNameController />
      <ExerciseDurationAndBurnedKCalsControllersContainer>
        <ExerciseBurnedKcalsController />
        <AddExerciseDurationControllers />
      </ExerciseDurationAndBurnedKCalsControllersContainer>
      <ExerciseDescriptionArabicController />
      <ExerciseDescriptionController />
    </WorkoutsFormFormSubContainer>
  );
};
