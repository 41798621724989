import { ContentModuleQuestionDTO } from "../../../../../../../../models/content-module-dtos/content-module-question-dto";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";
import {
  ReadLessonQuestionCardBodyQuestionAnswerContainer,
  ReadLessonQuestionCardBodyQuestionsTitle,
} from "./styled-lesson-read-mode-question-card-body";

type LessonReadModeQuestionCardBodyProps = {
  question: ContentModuleQuestionDTO;
};

export const LessonReadModeQuestionCardBody = (
  props: LessonReadModeQuestionCardBodyProps
) => {
  const { question } = props;

  return (
    <div className="card-parts-list">
      <ReadLessonQuestionCardBodyQuestionsTitle>
        Correct answer
      </ReadLessonQuestionCardBodyQuestionsTitle>
      {question.choices
        .sort((a, b) => a.uiOrder - b.uiOrder)
        .map((choice, index) => (
          <ReadLessonQuestionCardBodyQuestionAnswerContainer key={index}>
            <input type="radio" checked={choice.isCorrect} readOnly />
            <UXInputWrapper $readOnly>
              <UXInputInput defaultValue={choice.title} readOnly />
            </UXInputWrapper>
          </ReadLessonQuestionCardBodyQuestionAnswerContainer>
        ))}
      <UXInputContainer $textareaDimentions style={{ marginTop: "24px" }}>
        <UXInputLabel $width="90px">Explanation</UXInputLabel>
        <UXInputWrapper $textareaDimentions>
          <UXInputTextarea
            readOnly
            defaultValue={question.correctAnswerExplination}
          />
        </UXInputWrapper>
      </UXInputContainer>
    </div>
  );
};
