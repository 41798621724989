import { useFormContext } from "react-hook-form";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../styles/workouts-form-header/workouts-form-header-styles";
import { Button } from "../../../../styles/classes/reusable-classes";
import { AddExerciseInputs } from "./types/add-exercise-inputs";

export const AddExerciseHeader = () => {
  const { watch } = useFormContext<AddExerciseInputs>();
  const exerciseName = watch("name");
  const exerciseArabicName = watch("nameInArabic");

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Exercise</span>
          {exerciseArabicName} {exerciseName ? `(${exerciseName})` : ``}
        </p>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="submit">Save</Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
