import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../styles/workouts-form-header/workouts-form-header-styles";
import { useExerciseState } from "../context/exercise-state-context";
import { useExercise } from "../context/loadable-exercise-context";

export const ReadExerciseFormHeader = () => {
  const { setExerciseState } = useExerciseState();
  const { exerciseID } = useParams();
  const { exerciseDTO } = useExercise();

  const exerciseNameInEnglish = exerciseDTO.name.translations[0]?.translation;

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Exercise</span>
          <Link dir="auto" to={`/workouts/exercise/${exerciseID}`}>
            {exerciseDTO.name.defaultText}{" "}
            {exerciseNameInEnglish !== "" && exerciseNameInEnglish
              ? `(${exerciseNameInEnglish})`
              : ``}
          </Link>
        </p>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="button" onClick={() => setExerciseState("Update")}>
          Edit
        </Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
