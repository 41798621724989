import {
  UXInputInput,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../../context/loadable-exercise-context";
import { formatExerciseDuration } from "../../../../utils/format-exercise-duration";

export const ReadExerciseDurationMinsController = () => {
  const { exerciseDTO } = useExercise();
  const minutes = formatExerciseDuration(exerciseDTO.durationInSeconds).mins;

  return (
    <UXInputWrapper $width="20%" $readOnly>
      <UXInputInput
        placeholder="Min."
        defaultValue={minutes}
        dir="auto"
        readOnly
        style={{
          textAlign: "right",
        }}
      />
    </UXInputWrapper>
  );
};
