import { WorkoutProgramLevelDTO } from "../../../../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-level-dto";
import { WorkoutsFormFormSubContainer } from "../../../../../../../styles/workouts-common-styles";
import { ReadLevelDaysCountController } from "./read-level-days-count-controller";
import { ReadLevelNameArabicController } from "./read-level-name-arabic-controller";
import { ReadLevelNameController } from "./read-level-name-controller";
import { ReadLevelUiOrder } from "./read-level-ui-order";

type ReadLevelLeftControllersProps = {
  currentLevel?: WorkoutProgramLevelDTO;
};

export const ReadLevelLeftControllers = (
  props: ReadLevelLeftControllersProps
) => {
  const { currentLevel } = props;

  return (
    <WorkoutsFormFormSubContainer>
      <ReadLevelNameArabicController
        levelName={currentLevel?.name.defaultText}
      />
      <ReadLevelNameController
        levelName={currentLevel?.name.translations[0]?.translation || ""}
      />
      <ReadLevelDaysCountController daysCount={currentLevel?.daysCount} />
      <ReadLevelUiOrder uiOrder={currentLevel?.uiOrder} />
    </WorkoutsFormFormSubContainer>
  );
};
