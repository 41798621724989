import {
  WorkoutsScreenHeaderContainer,
  WorkoutsScreenHeaderTitle,
} from "./styled-workouts-screen-header";
import { WorkoutsNavbar } from "./workouts-navbar/workouts-navbar";

export const WorkoutsScreenHeader = () => {
  return (
    <WorkoutsScreenHeaderContainer>
      <WorkoutsScreenHeaderTitle>Workouts</WorkoutsScreenHeaderTitle>
      <WorkoutsNavbar />
    </WorkoutsScreenHeaderContainer>
  );
};
