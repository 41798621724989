import styled from "styled-components/macro";
import {
  flexCenter,
  smallRegularFont,
} from "../../../../../../../../styles/classes/gloabl-classes";

export const UpdateLessonQuestionCardBodyQuestionsTitle = styled.span`
  ${smallRegularFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin-left: 40px;
`;

export const UpdateLessonQuestionCardBodyQuestionAnswerContainer = styled.div`
  ${flexCenter}
  gap: 0px 8px;
  margin: 8px 0px;

  input[type="radio"] {
    margin-left: 95px;
  }
`;
