import { useCallback } from "react";
import { useClient } from "../../context/client-context";
import { BASE_PATH } from "../../constants/paths";
import { CreateOrUpdateExerciseDTO } from "../../models/exercises-dtos/create-or-update-exercise-dto";
import { ExerciseDTO } from "../../models/exercises-dtos/exercise-dto";

export function useAddExercise(): (
  createExerciseDTO: CreateOrUpdateExerciseDTO
) => Promise<ExerciseDTO> {
  const client = useClient();

  return useCallback(
    async (createExerciseDTO) => {
      const response = await client.post<ExerciseDTO>(
        `${BASE_PATH}/exercises`,
        createExerciseDTO
      );

      return response.data;
    },
    [client]
  );
}
