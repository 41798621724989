import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { useAddUploadedFile } from "../../../../../../../hooks/uploaded-file-hooks/use-add-uploaded-file";
import { AddExerciseInputs } from "../../../types/add-exercise-inputs";
import { CreateUploadedFileDTO } from "../../../../../../../models/uploaded-file-dtos/create-uploaded-file-dto";
import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";
import { ImageVideoUpload } from "../../../../../../../components/image-video-upload/image-video-upload";
import { useImageVideoUploadHelpers } from "../../../../../../../components/image-video-upload/context/image-video-upload-helpers-context";

export const ExerciseMediaContainer = () => {
  const { showToast } = useToastService();
  const addUploadedFile = useAddUploadedFile();

  const {
    imageVideoFile,
    setLoading,
    handleUploadedImageVideoState,
    imageVideoUploadState,
  } = useImageVideoUploadHelpers();

  const {
    setValue,
    formState: { errors },
  } = useFormContext<AddExerciseInputs>();

  const onUpload = useCallback(async () => {
    try {
      if (imageVideoFile) {
        const createUploadedFileDTO: CreateUploadedFileDTO = {
          feature: imageVideoFile.type.startsWith("video")
            ? "ContentModuleCardPartVideo"
            : "ContentModuleCardPartImage",
        };

        setLoading(true);
        await addUploadedFile(
          createUploadedFileDTO,
          imageVideoFile
        ).then((response) => setValue("mediaUploadedFileId", response.id));
        handleUploadedImageVideoState(imageVideoFile);
        showToast("Success", "Media Uploaded Successfully");
        setLoading(false);
      }
    } catch (error) {
      showToast("Error", "Failed to Upload Media :(");
    }
  }, [
    imageVideoFile,
    setLoading,
    setValue,
    showToast,
    addUploadedFile,
    handleUploadedImageVideoState,
  ]);

  useEffect(() => {
    if (imageVideoFile && imageVideoUploadState.state === "Upload") {
      onUpload();
    }
  }, [imageVideoFile, onUpload, imageVideoUploadState]);

  return (
    <UXInputContainer $textareaDimentions>
      <UXInputLabel $width="150px">Exercise Media</UXInputLabel>
      <ImageVideoUpload
        errorState={errors.mediaUploadedFileId?.type === "required"}
      />
    </UXInputContainer>
  );
};
