import { useCallback } from "react";
import { LoadableAddLevelWorkoutsProvider } from "./context/loadable-add-level-workouts-context";
import { AddLevelWorkoutsContainer } from "./add-level-workouts-container/add-level-workouts-container";
import { useLoadableData } from "../../../../../../../hooks/use-loadable-data";
import { useGetAllExercises } from "../../../../../../../hooks/exercises/use-get-all-exercises";
import { LoadableDataLoading } from "../../../../../../../components/loadable-data-loading";

export const AddLevelWorkouts = () => {
  const getAllExercises = useGetAllExercises();

  const loadableAddLevelWorkoutsCallback = useCallback(async () => {
    const [exerciseDTOs] = await Promise.all([getAllExercises()]);

    return { exerciseDTOs };
  }, [getAllExercises]);

  const loadableAddLevelWorkouts = useLoadableData(
    loadableAddLevelWorkoutsCallback
  );

  return (
    <LoadableDataLoading
      state={loadableAddLevelWorkouts.state}
      successComponent={() => (
        <LoadableAddLevelWorkoutsProvider
          loadableAddLevelWorkouts={loadableAddLevelWorkouts}
        >
          <AddLevelWorkoutsContainer />
        </LoadableAddLevelWorkoutsProvider>
      )}
    />
  );
};
