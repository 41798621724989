import styled from "styled-components/macro";
import { flexCenter } from "../../../styles/classes/gloabl-classes";

export const WorkoutsTableRowsThumbnailContainer = styled.div`
  ${flexCenter}

  img {
    width: 35px;
    height: 35px;
  }
`;

export const WorkoutsFormContainer = styled.div<{ $level?: boolean }>`
  height: ${({ $level }) => ($level ? "auto" : "calc(100vh - 85px)")};
  min-height: ${({ $level }) => ($level ? "calc(100vh - 85px)" : "unset")};
  padding: 12px 8px;
  background: ${({ theme }) => theme.colors.containerColor};
`;

export const WorkoutsFormControllersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const WorkoutsFormFormSubContainer = styled.div`
  width: 50%;
  display: grid;
  gap: 12px 0px;
`;

export const WorkoutsSubTableContainer = styled.div`
  width: 90%;
  margin: 24px auto 0px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
`;
