import styled, { css } from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumFont,
  mediumRegularFont,
  smallMediumFont,
  smallSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../styles/global-style";

export const LevelWorkoutsCardExercisesList = styled.ul<{
  $readMode?: boolean;
}>`
  margin: 0px;
  height: ${({ $readMode }) => ($readMode ? "200px" : "250px")};
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const LevelWorkoutsCardExercisesListItem = styled.li`
  user-select: none;
  ${mediumRegularFont}
  ${flexCenter}
  width: 100%;
  padding: 10px 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
`;

export const LevelWorkoutsCardExercisesListItemNumberContainer = styled.div`
  ${flexCenter}
  margin-right: 8px;

  p {
    margin: 0px;
    ${mediumFont}
  }

  span {
    font-size: 18px;
    margin-left: 4px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const LevelWorkoutsCardExercisesListItemLGImageContainer = styled.img`
  border: 2px solid ${({ theme }) => theme.colors.borderColor};
  background: ${({ theme }) => theme.colors.containerColor};
  position: absolute;
  z-index: 100;
  top: -10px;
  left: 10px;
  opacity: 0;
  height: 0px !important;
  width: 0px !important;
  overflow: hidden;
  transition: ${transition};
`;

export const LevelWorkoutsCardExercisesListItemImageContainer = styled.div<{
  $width: string;
  $height: string;
  $removeImage: boolean;
}>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
  ${flexCenterCenter}
  margin-right: 8px;
  position: relative;
  display: ${({ $removeImage }) => ($removeImage ? "none" : "block")};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  :hover ${LevelWorkoutsCardExercisesListItemLGImageContainer} {
    opacity: 1;
    width: 100px !important;
    height: 100px !important;
    overflow: visible;
  }
`;

const LevelWorkoutsCardExercisesListItemTextContainerPTagHoverCSS = css`
  position: relative;

  :hover ${LevelWorkoutsCardExercisesListItemLGImageContainer} {
    opacity: 1;
    width: 100px !important;
    height: 100px !important;
    overflow: visible;
  }
`;

export const LevelWorkoutsCardExercisesListItemTextContainer = styled.div<{
  $removeImage: boolean;
}>`
  max-width: 155px;

  p {
    margin: 0px;
    ${mediumRegularFont}

    ${({ $removeImage }) =>
      $removeImage
        ? LevelWorkoutsCardExercisesListItemTextContainerPTagHoverCSS
        : ``};
  }

  span {
    color: ${({ theme }) => theme.colors.textColor};
    ${smallMediumFont}
    position: relative;
    width: 100%;
  }
`;

export const LevelWorkoutsCardExercisesListItemInfoContainer = styled.div`
  ${flexCenter}
  margin-left: auto;

  .exercise-list-item-icon {
    color: ${({ theme }) => theme.colors.primaryColor};
    margin-left: 24px;
  }
`;

export const LevelWorkoutsCardExercisesListItemInfoSubContainer = styled.div`
  display: grid;
`;

export const LevelWorkoutsCardExercisesListItemInfoItem = styled.div`
    ${flexCenter}
    gap: 0px 4px;
  
    p {
      margin: 0px;
      ${smallSemiBoldFont}
      color: ${({ theme }) => theme.colors.textColor};
    }
  
    span {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.textColor};
    }
`;
