import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { ExerciseDurationAndBurnedKCalsControllersContainer } from "../../../../styles/exercise-common-styles";
import { ReadExerciseBurnedKcalsController } from "./read-exercise-burned-kcals-controller";
import { ReadExerciseDescriptionArabicController } from "./read-exercise-description-arabic-controller";
import { ReadExerciseDescriptionController } from "./read-exercise-description-controller";
import { ReadExerciseDurationControllers } from "./read-exercise-duration-controllers/read-exercise-duration-controllers";
import { ReadExerciseNameArabicController } from "./read-exercise-name-arabic-controller";
import { ReadExerciseNameController } from "./read-exercise-name-controller";

export const ReadExerciseLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <ReadExerciseNameArabicController />
      <ReadExerciseNameController />
      <ExerciseDurationAndBurnedKCalsControllersContainer>
        <ReadExerciseBurnedKcalsController />
        <ReadExerciseDurationControllers />
      </ExerciseDurationAndBurnedKCalsControllersContainer>
      <ReadExerciseDescriptionArabicController />
      <ReadExerciseDescriptionController />
    </WorkoutsFormFormSubContainer>
  );
};
