import { useAddLevelWorkouts } from "../../context/loadable-add-level-workouts-context";
import { calculateWorkoutDetails } from "../utils/calculate-workout-details";
import { useMemo } from "react";
import { AddLevelWorkoutsCardExercises } from "./add-level-workouts-card-exercises";
import { AddLevelWorkoutsEditModal } from "../add-level-workouts-modal/add-level-workouts-edit-modal";
import {
  LevelWorkoutsCardWorkoutContainer,
  LevelWorkoutsCardWorkoutDetail,
  LevelWorkoutsCardWorkoutDetails,
  LevelWorkoutsCardWorkoutFooter,
  LevelWorkoutsCardWorkoutHeader,
  LevelWorkoutsCardWorkoutHeaderActions,
  LevelWorkoutsCardWorkoutHeaderTitle,
  LevelWorkoutsCardWorkoutInformation,
} from "../../../../../../../styles/level-workouts-styles/level-workouts-card-styles";
import { useAddLevelWorkoutsWeeksFormHandlers } from "../../context/add-level-workouts-weeks-form-handlers-context";

type AddLevelWorkoutsCardProps = {
  dayNumber: number;
  weekNumber: number;
  dayIndex: number;
};

export const AddLevelWorkoutsCard = (props: AddLevelWorkoutsCardProps) => {
  const { dayNumber, weekNumber, dayIndex } = props;

  const {
    getWeekWorkouts,
    handleRemoveWorkoutFromWeek,
    handleDuplicateWorkout,
    workoutDragItem,
    weekDraggedOverItem,
    handleSortWorkoutsInWeek,
  } = useAddLevelWorkoutsWeeksFormHandlers();
  const workouts = getWeekWorkouts(weekNumber);

  const { exerciseDTOs } = useAddLevelWorkouts();

  const { currentDayExercises, workoutInformation } = useMemo(() => {
    const workoutsExercises = workouts
      ? workouts
          .map((workout) =>
            workout.workoutExercises && workout.dayNumber === dayNumber
              ? workout.workoutExercises
              : []
          )
          .flat()
          .sort((a, b) => a.uiOrder - b.uiOrder)
      : [];

    const currentDayExercises = workoutsExercises.map(
      (item) => exerciseDTOs.find((ex) => ex.id === item.exerciseId)!
    );

    const currentWorkout = workouts?.find(
      (workoutDTO) => workoutDTO.dayNumber === dayNumber
    );

    const workoutInformation = {
      name: currentWorkout?.name.defaultText || "",
      description: currentWorkout?.description.defaultText || "",
      nameInEnglish: currentWorkout?.name.translations[0]?.translation || "",
      descriptionInEnglish:
        currentWorkout?.description.translations[0]?.translation || "",
    };

    return { currentDayExercises, workoutInformation };
  }, [workouts, exerciseDTOs, dayNumber]);

  return (
    <LevelWorkoutsCardWorkoutContainer $restDay={false}>
      <LevelWorkoutsCardWorkoutHeader
        $restDay={false}
        draggable
        onDragStart={() => (workoutDragItem.current = dayIndex)}
        onDragEnter={() => (weekDraggedOverItem.current = dayIndex)}
        onDragEnd={() =>
          handleSortWorkoutsInWeek(
            weekNumber,
            workoutDragItem.current,
            weekDraggedOverItem.current
          )
        }
        onDragOver={(e) => e.preventDefault()}
      >
        <span className="material-symbols-outlined level-workouts-container-header-action-move">
          menu
        </span>
        <LevelWorkoutsCardWorkoutHeaderTitle $restDay>
          Day {dayNumber}
        </LevelWorkoutsCardWorkoutHeaderTitle>
        <LevelWorkoutsCardWorkoutHeaderActions>
          <span
            className="material-symbols-outlined"
            onClick={() => handleDuplicateWorkout(weekNumber, dayNumber)}
          >
            content_copy
          </span>
          <span
            className="material-symbols-outlined"
            onClick={() => handleRemoveWorkoutFromWeek(weekNumber, dayNumber)}
          >
            delete
          </span>
        </LevelWorkoutsCardWorkoutHeaderActions>
      </LevelWorkoutsCardWorkoutHeader>
      <div>
        <LevelWorkoutsCardWorkoutInformation>
          <p>
            {workoutInformation.name}
            <span>
              {workoutInformation.nameInEnglish !== ""
                ? ` (${workoutInformation.nameInEnglish})`
                : ``}
            </span>
          </p>
          <p>
            {workoutInformation.description}
            <span>
              {workoutInformation.descriptionInEnglish !== ""
                ? ` (${workoutInformation.descriptionInEnglish})`
                : ``}
            </span>
          </p>
        </LevelWorkoutsCardWorkoutInformation>
        <LevelWorkoutsCardWorkoutDetails>
          <LevelWorkoutsCardWorkoutDetail>
            <span className="material-symbols-outlined">schedule</span>
            <p>
              {
                calculateWorkoutDetails(currentDayExercises)
                  .totalWorkoutDuration
              }{" "}
              mins
            </p>
          </LevelWorkoutsCardWorkoutDetail>
          <LevelWorkoutsCardWorkoutDetail>
            <span className="material-symbols-outlined">
              local_fire_department
            </span>
            <p>
              {calculateWorkoutDetails(currentDayExercises).totalBurnedKcals}{" "}
              kcal
            </p>
          </LevelWorkoutsCardWorkoutDetail>
        </LevelWorkoutsCardWorkoutDetails>
        <AddLevelWorkoutsCardExercises
          currentDayExercises={currentDayExercises}
          removeImage={workouts.length > 5}
        />
      </div>
      <LevelWorkoutsCardWorkoutFooter>
        <AddLevelWorkoutsEditModal day={dayNumber} weekNumber={weekNumber} />
      </LevelWorkoutsCardWorkoutFooter>
    </LevelWorkoutsCardWorkoutContainer>
  );
};
