import { WorkoutsFormFormSubContainer } from "../../../../../../../styles/workouts-common-styles";
import { UpdateLevelDaysCountController } from "./update-level-days-count-controller";
import { UpdateLevelNameArabicController } from "./update-level-name-arabic-controller";
import { UpdateLevelNameController } from "./update-level-name-controller";
import { UpdateLevelUiOrder } from "./update-level-ui-order";

export const UpdateLevelLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <UpdateLevelNameArabicController />
      <UpdateLevelNameController />
      <UpdateLevelDaysCountController />
      <UpdateLevelUiOrder />
    </WorkoutsFormFormSubContainer>
  );
};
