import { FormProvider, useForm } from "react-hook-form";
import { AddExerciseInputs } from "./types/add-exercise-inputs";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
} from "../../styles/workouts-common-styles";
import { useAddExercise } from "../../../../hooks/exercises/use-add-exercise";
import { useToastService } from "../../../../context/toast-service-context";
import { CreateOrUpdateExerciseDTO } from "../../../../models/exercises-dtos/create-or-update-exercise-dto";
import { AddExerciseLeftControllers } from "./controllers/add-exercise-left-controllers/add-exercise-left-controllers";
import { AddExerciseRightControllers } from "./controllers/add-exercise-right-controllers/add-exercise-right-controllers";
import { useNavigate } from "react-router-dom";
import { AddExerciseHeader } from "./add-exercise-header";

export const AddExercise = () => {
  const addExerciseFormMethods = useForm<AddExerciseInputs>();
  const navigate = useNavigate();

  const { showToast } = useToastService();

  const addNewExercise = useAddExercise();

  const onSubmit = addExerciseFormMethods.handleSubmit(async (inputs) => {
    try {
      const createExerciseDTO: CreateOrUpdateExerciseDTO = {
        name: {
          defaultLanguageId: 2,
          defaultText: inputs.nameInArabic,
          translations: inputs.name
            ? [
                {
                  languageId: 1,
                  translation: inputs.name,
                },
              ]
            : [],
        },
        burnedKCals: Number(inputs.burnedKCals),
        description: {
          defaultLanguageId: 2,
          defaultText: inputs.descriptionInArabic,
          translations: inputs.description
            ? [
                {
                  languageId: 1,
                  translation: inputs.description,
                },
              ]
            : [],
        },
        durationInSeconds:
          Number(inputs.durationInMins * 60) + Number(inputs.durationInSeconds),
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId,
        mediaUploadedFileId: inputs.mediaUploadedFileId,
        restrictions: [],
      };

      await addNewExercise(createExerciseDTO).then((res) =>
        navigate(`/workouts/exercise/${res.id}`)
      );
      showToast("Success", "Success Adding Exercise");
    } catch (error) {
      showToast("Error", "Failed to Add Exercise :(");
    }
  });

  return (
    <FormProvider {...addExerciseFormMethods}>
      <form onSubmit={onSubmit}>
        <AddExerciseHeader />
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <AddExerciseLeftControllers />
            <AddExerciseRightControllers />
          </WorkoutsFormControllersContainer>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
