import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../../../../styles/classes/reusable-classes";
import { useWorkoutProgram } from "../../../context/loadable-workout-program-context";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../../../styles/workouts-form-header/workouts-form-header-styles";
import { UpdateLevelInputs } from "./types/update-level-inputs";
import { useFormContext } from "react-hook-form";

export const UpdateLevelFormHeader = () => {
  const { levelID, programID } = useParams();

  const { workoutProgramDTO } = useWorkoutProgram();
  const { watch } = useFormContext<UpdateLevelInputs>();
  const levelTitle = watch("name");
  const levelTitleInEnglish = watch("nameInEnglish");

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );

  const programNameInEnglish =
    workoutProgramDTO.name.translations[0]?.translation;

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Program</span>
          <Link dir="auto" to={`/workouts/program/${programID}`}>
            {workoutProgramDTO.name.defaultText}{" "}
            {programNameInEnglish && programNameInEnglish !== ""
              ? ` (${programNameInEnglish})`
              : ""}
          </Link>
        </p>
        {currentLevel !== undefined && (
          <>
            <span className="material-symbols-outlined">chevron_right</span>
            <p dir="auto">
              <span>
                Level{" "}
                {currentLevel.uiOrder !== undefined ? currentLevel.uiOrder : ""}
              </span>
              <Link
                dir="auto"
                to={`/workouts/program/${programID}/level/${levelID}`}
              >
                {levelTitle}{" "}
                {levelTitleInEnglish && levelTitleInEnglish !== ""
                  ? ` (${levelTitleInEnglish})`
                  : ``}
              </Link>
            </p>
          </>
        )}
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="submit">Save</Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
