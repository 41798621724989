import {
  LevelWorkoutsCardWorkoutContainer,
  LevelWorkoutsCardWorkoutHeader,
  LevelWorkoutsCardWorkoutHeaderActions,
  LevelWorkoutsCardWorkoutHeaderTitle,
} from "../../../../../../../../styles/level-workouts-styles/level-workouts-card-styles";
import { useUpdateLevelWorkoutsWeeksFormHandlers } from "../../context/update-level-workouts-weeks-form-handlers-context";

type UpdateLevelWorkoutsRestDayCardProps = {
  dayNumber: number;
  weekNumber: number;
  dayIndex: number;
};

export const UpdateLevelWorkoutsRestDayCard = (
  props: UpdateLevelWorkoutsRestDayCardProps
) => {
  const { dayNumber, weekNumber, dayIndex } = props;

  const {
    handleRemoveWorkoutFromWeek,
    workoutDragItem,
    weekDraggedOverItem,
    handleSortWorkoutsInWeek,
  } = useUpdateLevelWorkoutsWeeksFormHandlers();

  return (
    <LevelWorkoutsCardWorkoutContainer $restDay>
      <LevelWorkoutsCardWorkoutHeader
        $restDay
        draggable
        onDragStart={() => (workoutDragItem.current = dayIndex)}
        onDragEnter={() => (weekDraggedOverItem.current = dayIndex)}
        onDragEnd={() =>
          handleSortWorkoutsInWeek(
            weekNumber,
            workoutDragItem.current,
            weekDraggedOverItem.current
          )
        }
        onDragOver={(e) => e.preventDefault()}
      >
        <span className="material-symbols-outlined level-workouts-container-header-action-move">
          menu
        </span>
        <LevelWorkoutsCardWorkoutHeaderTitle $restDay>
          Day {dayNumber}
        </LevelWorkoutsCardWorkoutHeaderTitle>
        <LevelWorkoutsCardWorkoutHeaderActions>
          <span
            className="material-symbols-outlined"
            onClick={() => handleRemoveWorkoutFromWeek(weekNumber, dayNumber)}
          >
            delete
          </span>
        </LevelWorkoutsCardWorkoutHeaderActions>
      </LevelWorkoutsCardWorkoutHeader>
      <p className="rest-day-text">
        <span>Rest</span>
      </p>
    </LevelWorkoutsCardWorkoutContainer>
  );
};
