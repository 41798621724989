import { useCallback, useEffect, useState } from "react";
import { useUpdateLevelWorkoutsWeeksFormHandlers } from "../context/update-level-workouts-weeks-form-handlers-context";
import {
  LevelWorkoutsContainerFooter,
  LevelWorkoutsContainerList,
  LevelWorkoutsContainerWrapper,
} from "../../../../../../../styles/level-workouts-styles/level-workouts-container-styles";
import { Button } from "../../../../../../../../../styles/classes/reusable-classes";
import { UpdateLevelWorkoutsWeeksHeader } from "./update-level-workouts-weeks-header/update-level-workouts-weeks-header";
import { UpdateLevelWorkoutsContainerCards } from "./update-level-workouts-cards/update-level-workouts-cards";

export const UpdateLevelWorkoutsContainer = () => {
  const { weeks, handleAppendWeek } = useUpdateLevelWorkoutsWeeksFormHandlers();

  const [dropdownStates, setDropdownStates] = useState<boolean[]>([]);

  useEffect(() => {
    setDropdownStates((prevStates) =>
      weeks.map((_, index) => prevStates[index] || true)
    );
  }, [weeks]);

  const toggleDropdown = (weekIndex: number) => {
    setDropdownStates((prevStates) =>
      prevStates.map((state, index) => (index === weekIndex ? !state : state))
    );
  };

  const isAddWeeksDisabled = useCallback(() => {
    const allDays = weeks.flatMap((week) => week.days).sort((a, b) => a - b);
    const workouts = weeks.flatMap((week) => week.workouts);

    const workoutDays = workouts
      ? workouts.map((item) => item.dayNumber).sort((a, b) => a - b)
      : [];

    return !allDays.every((item, index) => item === workoutDays[index]);
  }, [weeks]);

  return (
    <>
      {weeks.map((week, weekIndex) => (
        <LevelWorkoutsContainerWrapper key={weekIndex}>
          <UpdateLevelWorkoutsWeeksHeader
            showDropdown={dropdownStates[weekIndex]}
            toggleDropdown={() => toggleDropdown(weekIndex)}
            week={week}
            weekIndex={weekIndex}
          />
          <LevelWorkoutsContainerList $showDropdown={dropdownStates[weekIndex]}>
            {week.days.map((day, index) => (
              <UpdateLevelWorkoutsContainerCards
                key={index}
                day={day}
                weekNumber={week.weekNumber}
                dayIndex={index}
              />
            ))}
          </LevelWorkoutsContainerList>
        </LevelWorkoutsContainerWrapper>
      ))}
      <LevelWorkoutsContainerFooter>
        <Button
          disabled={isAddWeeksDisabled()}
          type="button"
          flex
          outlineNoBorder
          onClick={handleAppendWeek}
        >
          <span className="material-symbols-outlined">add</span>Add Week
        </Button>
      </LevelWorkoutsContainerFooter>
    </>
  );
};
