import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ContentModuleDTO } from "../../../models/content-module-dtos/content-module-dto";
import { ContentModuleType } from "../../../models/content-module-dtos/content-module-type";
import { TargetAudienceDTO } from "../../../models/target-audience-dtos/target-audience-dto";
import { usePutContentModule } from "../../../hooks/content-module-hooks/use-put-content-module";
import { useToastService } from "../../../context/toast-service-context";
import { useReloadContent } from "./loadable-content-context";
import { PutContentModuleDTO } from "../../../models/content-module-dtos/put-content-module-dto";
import { useLocation, useNavigate } from "react-router-dom";
import { PutContentModuleCardDTO } from "../../../models/content-module-dtos/put-content-module-card-dto";
import { PutContentModuleQuestionDTO } from "../../../models/content-module-dtos/put-content-module-question-dto";

export type ContentModuleUpdateModeInputs = {
  name?: string;
  description?: string;
  objective?: string;
  status: boolean;
  thumbnailUploadedFileId?: number;
  targetAudience: TargetAudienceDTO[];
  uiOrder?: number;
  contentModuleCards: PutContentModuleCardDTO[];
  contentModuleQuestions?: PutContentModuleQuestionDTO[];
};

export type ContentModuleReadModeInputs = {
  status: boolean;
  targetAudience: TargetAudienceDTO[];
};

type ContentModuleModeState = {
  mode:
    | {
        kind: "Read";
        contentModule: ContentModuleDTO;
        handleSubmit: (
          contentModuleID: number,
          putContentModuleDTO: PutContentModuleDTO,
          contetModuleType: ContentModuleType
        ) => Promise<void>;
      }
    | {
        kind: "Update";
        contentModule: ContentModuleDTO;
        handleSubmit: (
          contentModuleID: number,
          putContentModuleDTO: PutContentModuleDTO,
          contetModuleType: ContentModuleType,
          navigationRouteAfterUpdateComplete: string
        ) => Promise<void>;
      };
};

type ContentModuleMode = {
  contentModuleMode: ContentModuleModeState;
  onContentModuleModeChangeToRead: (contentModuleDTO: ContentModuleDTO) => void;
  onContentModuleModeChangeToUpdate: (
    contentModuleDTO: ContentModuleDTO
  ) => void;
};

const ContentModuleModeContext = createContext<ContentModuleMode | undefined>(
  undefined
);

type ContentModuleModeProviderProps = PropsWithChildren<{
  contentModuleDTO: ContentModuleDTO;
}>;

export const ContentModuleModeProvider = (
  props: ContentModuleModeProviderProps
) => {
  const { children, contentModuleDTO } = props;

  const putContentModule = usePutContentModule();
  const { showToast } = useToastService();
  const reloadContent = useReloadContent();

  const location = useLocation();
  const navigate = useNavigate();

  const onContentModuleReadModeSubmit = useCallback(
    async (
      contentModuleID: number,
      putContentModuleDTO: PutContentModuleDTO,
      contetModuleType: ContentModuleType
    ) => {
      try {
        await putContentModule(contentModuleID, putContentModuleDTO);

        showToast("Success", `${contetModuleType} Updated Successfully!`);
        reloadContent();
      } catch (error) {
        showToast("Error", `Faild to Update ${contetModuleType} :(`);
      }
    },
    [showToast, reloadContent, putContentModule]
  );

  const onContentModuleUpdateModeSubmit = useCallback(
    async (
      contentModuleID: number,
      putContentModuleDTO: PutContentModuleDTO,
      contetModuleType: ContentModuleType,
      navigationRouteAfterUpdateComplete: string
    ) => {
      try {
        await putContentModule(contentModuleID, putContentModuleDTO);

        showToast("Success", `${contetModuleType} Updated Successfully!`);
        reloadContent();

        if (location.pathname.includes("/update")) {
          navigate(`${navigationRouteAfterUpdateComplete}`);
        }
      } catch (error) {
        showToast("Error", `Faild to Update ${contetModuleType} :(`);
      }
    },
    [showToast, reloadContent, putContentModule, navigate, location.pathname]
  );

  const onContentModuleModeChangeToRead = useCallback(
    (contentModule: ContentModuleDTO) => {
      setContentModuleMode({
        mode: {
          kind: "Read",
          handleSubmit: onContentModuleReadModeSubmit,
          contentModule,
        },
      });
    },
    [onContentModuleReadModeSubmit]
  );

  const onContentModuleModeChangeToUpdate = useCallback(
    (contentModule: ContentModuleDTO) => {
      setContentModuleMode({
        mode: {
          kind: "Update",
          handleSubmit: onContentModuleUpdateModeSubmit,
          contentModule,
        },
      });
    },
    [onContentModuleUpdateModeSubmit]
  );

  const [contentModuleMode, setContentModuleMode] = useState<
    ContentModuleModeState
  >({
    mode: {
      kind: "Read",
      handleSubmit: onContentModuleReadModeSubmit,
      contentModule: contentModuleDTO,
    },
  });

  const value = useMemo(
    () => ({
      contentModuleMode,
      onContentModuleModeChangeToRead,
      onContentModuleModeChangeToUpdate,
    }),
    [
      contentModuleMode,
      onContentModuleModeChangeToRead,
      onContentModuleModeChangeToUpdate,
    ]
  );

  return (
    <ContentModuleModeContext.Provider value={value}>
      {children}
    </ContentModuleModeContext.Provider>
  );
};

export const useContentModuleMode = (): ContentModuleMode => {
  const context = useContext(ContentModuleModeContext);

  if (context === undefined) {
    throw new Error(
      "useContentModuleMode must be used within a ContentModuleModeProvider"
    );
  }

  return context;
};
