import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

type ContentModuleLessonTabs = {
  activeTab: "Cards" | "Questions";
  setActiveTab: (tab: "Cards" | "Questions") => void;
};

const ContentModuleLessonTabsContext = createContext<
  ContentModuleLessonTabs | undefined
>(undefined);

type ContentModuleLessonTabsProviderProps = PropsWithChildren<{}>;

export function ContentModuleLessonTabsProvider(
  props: ContentModuleLessonTabsProviderProps
) {
  const { children } = props;

  const [activeTab, setActiveTab] = useState<"Cards" | "Questions">("Cards");

  const value = useMemo(() => ({ activeTab, setActiveTab }), [
    activeTab,
    setActiveTab,
  ]);

  return (
    <ContentModuleLessonTabsContext.Provider value={value}>
      {children}
    </ContentModuleLessonTabsContext.Provider>
  );
}

export function useContentModuleLessonTabs() {
  const context = useContext(ContentModuleLessonTabsContext);

  if (!context) {
    throw new Error(
      "useContentModuleLessonTabs must be used within a ContentModuleLessonTabsProvider"
    );
  }

  return context;
}
