import styled, { css } from "styled-components/macro";
import { Button, ErrorText } from "../../../../styles/classes/reusable-classes";
import { mediumFont } from "../../../../styles/classes/gloabl-classes";

const VideoUploadContainerDimentions = css`
  margin-right: auto;
`;

export const VideoUploadContainer = styled.div<{
  $dragActive: boolean;
  $uploadedDimentions?: boolean;
  $errorState?: boolean;
}>`
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  padding: 16px;
  background: ${({ theme }) => theme.colors.inputColor};
  border: ${({ $errorState, theme }) =>
    $errorState
      ? `1px solid ${theme.colors.danger}`
      : `2px dashed ${theme.colors.borderColor}`};
  border-radius: 8px;
  cursor: pointer;
  opacity: ${({ $dragActive }) => ($dragActive ? "0.5" : "1")};
  ${({ $uploadedDimentions }) =>
    $uploadedDimentions ? VideoUploadContainerDimentions : "width: 80%;"};

  ${Button} {
    margin: 0px auto;
    padding: 8px 16px;
  }

  video {
  }

  input {
    display: none;
  }

  p {
    margin-bottom: 0px;
    margin-top: 8px;
    ${mediumFont}
  }

  span {
    font-size: 44px;
  }

  ${ErrorText} {
    font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  }
`;

export const VideoUploadVideoContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  padding: 8px;

  video {
  }
`;

export const VideoUploadClearIcon = styled.span`
  cursor: pointer;
  font-size: 16px !important;
  position: absolute;
  top: -5px;
  right: -15px;
`;

export const VideoUploadVideo = styled.video`
  width: fit-content;
  max-width: 250px;

  @media screen and (max-width: 1100px) {
    max-width: 200px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 250px;
  }
`;
