import { createContext, PropsWithChildren, useContext } from "react";
import { ExerciseDTO } from "../../../../../../../../models/exercises-dtos/exercise-dto";
import { UseLoadableDataReturn } from "../../../../../../../../hooks/use-loadable-data";

export type AddLevelWorkouts = {
  exerciseDTOs: ExerciseDTO[];
};

export type LoadableAddLevelWorkouts = UseLoadableDataReturn<AddLevelWorkouts>;

const LoadableAddLevelWorkoutsContext = createContext<
  LoadableAddLevelWorkouts | undefined
>(undefined);

export type LoadableAddLevelWorkoutsProviderProps = PropsWithChildren<{
  loadableAddLevelWorkouts: LoadableAddLevelWorkouts;
}>;

export function LoadableAddLevelWorkoutsProvider(
  props: LoadableAddLevelWorkoutsProviderProps
) {
  const { loadableAddLevelWorkouts, children } = props;

  return (
    <LoadableAddLevelWorkoutsContext.Provider value={loadableAddLevelWorkouts}>
      {children}
    </LoadableAddLevelWorkoutsContext.Provider>
  );
}

export function useLoadableAddLevelWorkouts(): LoadableAddLevelWorkouts {
  const loadableAddLevelWorkouts = useContext(LoadableAddLevelWorkoutsContext);

  if (loadableAddLevelWorkouts === undefined) {
    throw Error(
      "useLoadableAddLevelWorkouts must be used within an LoadableAddLevelWorkoutsProvider"
    );
  }

  return loadableAddLevelWorkouts;
}

export function useAddLevelWorkouts(): AddLevelWorkouts {
  const loadableAddLevelWorkouts = useLoadableAddLevelWorkouts();

  if (loadableAddLevelWorkouts.state.data === undefined) {
    throw Error("AddLevelWorkouts have never been loaded successfully yet.");
  }

  return loadableAddLevelWorkouts.state.data;
}

export function useReloadAddLevelWorkouts(): () => void {
  const loadableAddLevelWorkouts = useLoadableAddLevelWorkouts();

  return loadableAddLevelWorkouts.reload;
}
