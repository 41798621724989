import { useFieldArray, useFormContext } from "react-hook-form";
import { Button } from "../../../../../../styles/classes/reusable-classes";
import { CreateLessonInputs } from "../../create-lesson";
import {
  LessonCardsContainer,
  LessonCardsFooter,
} from "../../../../styles/content-module-lesson-classes";
import { CreateLessonQuestionCard } from "./create-lesson-question-card/create-lesson-question-card";
import { useCallback } from "react";
import { EmptyState } from "../../../../../../components/empty-state/empty-state";

export const CreateLessonQuestions = () => {
  const { control, watch } = useFormContext<CreateLessonInputs>();
  const contentModuleQuestions = watch("contentModuleQuestions");

  const { append, fields, remove } = useFieldArray<CreateLessonInputs>({
    name: "contentModuleQuestions",
    control,
  } as never);

  const addInitialQuestion = (
    <Button
      outlinedPrimary
      flex
      type="button"
      style={{
        width: "180px",
        marginLeft: contentModuleQuestions?.length === 0 ? "" : "auto",
      }}
      onClick={() =>
        append({
          uiOrder: fields.length + 1,
          question: "",
          correctAnswerExplination: "",
          choices: [],
        })
      }
    >
      <span className="material-symbols-outlined">add</span>
      Add Question
    </Button>
  );

  const handleRemove = useCallback((index: number) => remove(index), [remove]);

  if (contentModuleQuestions?.length === 0) {
    return (
      <LessonCardsContainer>
        <EmptyState
          button={addInitialQuestion}
          hasHeader={false}
          hasSearch={false}
          text="No Questions Added Yet"
          title=""
        />
      </LessonCardsContainer>
    );
  }

  return (
    <LessonCardsContainer>
      {fields.map((field, index) => (
        <CreateLessonQuestionCard
          key={field.id}
          cardIndex={index}
          remove={() => handleRemove(index)}
        />
      ))}
      <LessonCardsFooter>{addInitialQuestion}</LessonCardsFooter>
    </LessonCardsContainer>
  );
};
