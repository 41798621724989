import { useCallback } from "react";
import { DeleteModal } from "../../../../components/delete-modal/delete-modal";
import { GenericActionsListItemItem } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import { Modal } from "../../../../components/modal/modal";
import { useModalHelpers } from "../../../../components/modal/use-modal-helpers";
import { useToastService } from "../../../../context/toast-service-context";
import { useReloadWorkoutPrograms } from "../context/loadable-workout-programs-context";
import { useDeleteWorkoutProgram } from "../../../../hooks/workout-program-hooks/use-delete-workout-program";
import { WorkoutProgramDTO } from "../../../../models/workout-programs-dtos/workout-program-dtos/workout-program-dto";

type DeleteProgramModalProps = {
  programDTO: WorkoutProgramDTO;
};

export const DeleteProgramModal = (props: DeleteProgramModalProps) => {
  const { programDTO } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const deleteWorkoutProgram = useDeleteWorkoutProgram();

  const { showToast } = useToastService();
  const reloadWorkoutPrograms = useReloadWorkoutPrograms();

  const onDelete = useCallback(async () => {
    try {
      await deleteWorkoutProgram(programDTO.id);

      showToast("Success", "Program Deleted Successfully!");
      closeModal();
      reloadWorkoutPrograms();
    } catch (error) {
      showToast("Error", "Failed to Delete Program :(");
    }
  }, [
    programDTO.id,
    closeModal,
    deleteWorkoutProgram,
    reloadWorkoutPrograms,
    showToast,
  ]);

  return (
    <>
      <GenericActionsListItemItem isDanger onClick={openModal}>
        Delete
      </GenericActionsListItemItem>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <DeleteModal
          key={modalBodyKey}
          title={`Program ${programDTO.name.defaultText}`}
          onDelete={onDelete}
          onModalClose={closeModal}
        />
      </Modal>
    </>
  );
};
