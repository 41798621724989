import { Button } from "../../../../../../../../../../styles/classes/reusable-classes";
import { LevelWorkoutsContainerListItem } from "../../../../../../../../styles/level-workouts-styles/level-workouts-container-styles";
import { useReadLevelWorkoutsWeeksFormHandlers } from "../../context/read-level-workouts-weeks-form-handlers-context";
import { ReadLevelWorkoutsCard } from "./read-level-workouts-card";
import { ReadLevelWorkoutsRestDayCard } from "./read-level-workouts-rest-day-card";

type ReadLevelWorkoutsCardsProps = {
  day: number;
  weekNumber: number;
};

export const ReadLevelWorkoutsCards = (props: ReadLevelWorkoutsCardsProps) => {
  const { day, weekNumber } = props;

  const { getWeekWorkouts } = useReadLevelWorkoutsWeeksFormHandlers();

  const workouts = getWeekWorkouts(weekNumber);

  const isWorkoutDay =
    workouts && workouts.some((item) => item.dayNumber === day);

  const isRestDay =
    isWorkoutDay &&
    workouts.some(
      (item) =>
        item.dayNumber === day &&
        item.name.defaultText === "Rest Day" &&
        item.description.defaultText === "This day is For Rest"
    );

  if (isRestDay) {
    return (
      <ReadLevelWorkoutsRestDayCard
        key={`RestDay ${day} Workout`}
        dayNumber={day}
      />
    );
  }

  if (isWorkoutDay) {
    return (
      <ReadLevelWorkoutsCard
        key={`Day ${day} Workout`}
        dayNumber={day}
        weekNumber={weekNumber}
      />
    );
  }

  return (
    <LevelWorkoutsContainerListItem key={day} $disabled={false}>
      <p>Day {day}</p>
      <Button type="button" outlined>
        Rest Day
      </Button>
    </LevelWorkoutsContainerListItem>
  );
};
