import { ExerciseDTO } from "../../../../../../../../../models/exercises-dtos/exercise-dto";
import { truncateText } from "../../../../../../../../../utils/truncate-text";
import {
  LevelWorkoutsCardExercisesList,
  LevelWorkoutsCardExercisesListItem,
  LevelWorkoutsCardExercisesListItemImageContainer,
  LevelWorkoutsCardExercisesListItemInfoContainer,
  LevelWorkoutsCardExercisesListItemInfoItem,
  LevelWorkoutsCardExercisesListItemInfoSubContainer,
  LevelWorkoutsCardExercisesListItemLGImageContainer,
  LevelWorkoutsCardExercisesListItemNumberContainer,
  LevelWorkoutsCardExercisesListItemTextContainer,
} from "../../../../../../../styles/level-workouts-styles/level-workouts-card-exercises-styles";

type AddLevelWorkoutsCardExercisesProps = {
  currentDayExercises: ExerciseDTO[];
  removeImage: boolean;
};

export const AddLevelWorkoutsCardExercises = (
  props: AddLevelWorkoutsCardExercisesProps
) => {
  const { currentDayExercises, removeImage } = props;

  return (
    <LevelWorkoutsCardExercisesList>
      {currentDayExercises.map((exerciseDTO, index) => {
        const exerciseNameInEnglish =
          exerciseDTO.name.translations[0]?.translation;

        const displayExerciseNameInEnglish =
          exerciseNameInEnglish !== "" && exerciseNameInEnglish
            ? ` (${truncateText(exerciseNameInEnglish, 8)})`
            : ``;

        const exerciseDescriptionInEnglish =
          exerciseDTO.description.translations[0]?.translation;

        const displayExerciseDescriptionInEnglish =
          exerciseDescriptionInEnglish && exerciseDescriptionInEnglish !== ""
            ? exerciseDescriptionInEnglish
            : ``;

        const displayExerciseDescripiton = truncateText(
          `${exerciseDTO.description.defaultText} ${displayExerciseDescriptionInEnglish}`,
          25
        );

        return (
          <LevelWorkoutsCardExercisesListItem key={exerciseDTO.id}>
            <LevelWorkoutsCardExercisesListItemNumberContainer>
              <p>{index + 1}</p>
            </LevelWorkoutsCardExercisesListItemNumberContainer>
            <LevelWorkoutsCardExercisesListItemImageContainer
              $width="50px"
              $height="50px"
              $removeImage={removeImage}
            >
              {exerciseDTO.thumbnailUploadedFile ? (
                <>
                  <img
                    src={exerciseDTO.thumbnailUploadedFile.presignedUrl}
                    alt={exerciseDTO.name.defaultText}
                  />
                  <LevelWorkoutsCardExercisesListItemLGImageContainer
                    src={exerciseDTO.thumbnailUploadedFile.presignedUrl}
                    alt={exerciseDTO.name.defaultText}
                  />
                </>
              ) : (
                <span className="material-symbols-outlined">
                  directions_run
                </span>
              )}
            </LevelWorkoutsCardExercisesListItemImageContainer>
            <LevelWorkoutsCardExercisesListItemTextContainer
              $removeImage={removeImage}
            >
              <p>
                {exerciseDTO.name.defaultText}
                <span
                  style={{ fontSize: "11px" }}
                  title={displayExerciseNameInEnglish}
                >
                  {displayExerciseNameInEnglish}
                </span>{" "}
                {exerciseDTO.thumbnailUploadedFile ? (
                  <LevelWorkoutsCardExercisesListItemLGImageContainer
                    src={exerciseDTO.thumbnailUploadedFile.presignedUrl}
                    alt={exerciseDTO.name.defaultText}
                  />
                ) : (
                  <></>
                )}
              </p>
              <span title={displayExerciseDescripiton}>
                {displayExerciseDescripiton}
              </span>
            </LevelWorkoutsCardExercisesListItemTextContainer>
            <LevelWorkoutsCardExercisesListItemInfoContainer>
              <LevelWorkoutsCardExercisesListItemInfoSubContainer>
                <LevelWorkoutsCardExercisesListItemInfoItem>
                  <span className="material-symbols-outlined">schedule</span>
                  <p>{exerciseDTO.durationInSeconds} mins</p>
                </LevelWorkoutsCardExercisesListItemInfoItem>
                <LevelWorkoutsCardExercisesListItemInfoItem>
                  <span className="material-symbols-outlined">
                    local_fire_department
                  </span>
                  <p>{exerciseDTO.burnedKCals} kcal</p>
                </LevelWorkoutsCardExercisesListItemInfoItem>
              </LevelWorkoutsCardExercisesListItemInfoSubContainer>
            </LevelWorkoutsCardExercisesListItemInfoContainer>
          </LevelWorkoutsCardExercisesListItem>
        );
      })}
    </LevelWorkoutsCardExercisesList>
  );
};
