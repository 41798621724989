import { createContext, PropsWithChildren, useContext } from "react";
import { ExerciseDTO } from "../../../../../../../../../models/exercises-dtos/exercise-dto";
import { UseLoadableDataReturn } from "../../../../../../../../../hooks/use-loadable-data";

export type ReadLevelWorkouts = {
  exerciseDTOs: ExerciseDTO[];
};

export type LoadableReadLevelWorkouts = UseLoadableDataReturn<
  ReadLevelWorkouts
>;

const LoadableReadLevelWorkoutsContext = createContext<
  LoadableReadLevelWorkouts | undefined
>(undefined);

export type LoadableReadLevelWorkoutsProviderProps = PropsWithChildren<{
  loadableReadLevelWorkouts: LoadableReadLevelWorkouts;
}>;

export function LoadableReadLevelWorkoutsProvider(
  props: LoadableReadLevelWorkoutsProviderProps
) {
  const { loadableReadLevelWorkouts, children } = props;

  return (
    <LoadableReadLevelWorkoutsContext.Provider
      value={loadableReadLevelWorkouts}
    >
      {children}
    </LoadableReadLevelWorkoutsContext.Provider>
  );
}

export function useLoadableReadLevelWorkouts(): LoadableReadLevelWorkouts {
  const loadableReadLevelWorkouts = useContext(
    LoadableReadLevelWorkoutsContext
  );

  if (loadableReadLevelWorkouts === undefined) {
    throw Error(
      "useLoadableReadLevelWorkouts must be used within an LoadableReadLevelWorkoutsProvider"
    );
  }

  return loadableReadLevelWorkouts;
}

export function useReadLevelWorkouts(): ReadLevelWorkouts {
  const loadableReadLevelWorkouts = useLoadableReadLevelWorkouts();

  if (loadableReadLevelWorkouts.state.data === undefined) {
    throw Error("ReadLevelWorkouts have never been loaded successfully yet.");
  }

  return loadableReadLevelWorkouts.state.data;
}

export function useReloadReadLevelWorkouts(): () => void {
  const loadableReadLevelWorkouts = useLoadableReadLevelWorkouts();

  return loadableReadLevelWorkouts.reload;
}
