import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../context/loadable-exercise-context";

export const ReadExerciseNameArabicController = () => {
  const { exerciseDTO } = useExercise();

  return (
    <UXInputContainer>
      <UXInputLabel $width="150px" $isOptional>
        Exercise Name
        <span>Arabic</span>
      </UXInputLabel>
      <UXInputWrapper $width="80%" $readOnly>
        <UXInputInput
          readOnly
          dir="auto"
          type="text"
          defaultValue={exerciseDTO.name.defaultText}
        />
      </UXInputWrapper>
    </UXInputContainer>
  );
};
