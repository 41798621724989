import {
  LevelWorkoutsCardWorkoutContainer,
  LevelWorkoutsCardWorkoutHeader,
  LevelWorkoutsCardWorkoutHeaderTitle,
} from "../../../../../../../../styles/level-workouts-styles/level-workouts-card-styles";

type ReadLevelWorkoutsRestDayCardProps = {
  dayNumber: number;
};

export const ReadLevelWorkoutsRestDayCard = (
  props: ReadLevelWorkoutsRestDayCardProps
) => {
  const { dayNumber } = props;

  return (
    <LevelWorkoutsCardWorkoutContainer $restDay $readMode>
      <LevelWorkoutsCardWorkoutHeader $restDay>
        <LevelWorkoutsCardWorkoutHeaderTitle $restDay>
          Day {dayNumber}
        </LevelWorkoutsCardWorkoutHeaderTitle>
      </LevelWorkoutsCardWorkoutHeader>
      <p className="rest-day-text">
        <span>Rest</span>
      </p>
    </LevelWorkoutsCardWorkoutContainer>
  );
};
