import { Controller, useFormContext } from "react-hook-form";
import { ImageVideoUploadHelpersProvider } from "../../../../../../../components/image-video-upload/context/image-video-upload-helpers-context";
import { ExerciseMediaContainer } from "./exercise-media-container";
import { AddExerciseInputs } from "../../../types/add-exercise-inputs";

export const ExerciseMediaController = () => {
  const { control } = useFormContext<AddExerciseInputs>();

  return (
    <Controller
      control={control}
      name="mediaUploadedFileId"
      rules={{ required: true }}
      render={() => (
        <ImageVideoUploadHelpersProvider>
          <ExerciseMediaContainer />
        </ImageVideoUploadHelpersProvider>
      )}
    />
  );
};
