import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../styles/classes/reusable-classes";
import { ExerciseDurationControllersContainer } from "../../../../styles/exercise-common-styles";
import { ExerciseDurationMinsController } from "./exercise-duration-mins-controller";
import { ExerciseDurationSecsController } from "./exercise-duration-secs-controller";

export const AddExerciseDurationControllers = () => {
  return (
    <ExerciseDurationControllersContainer>
      <UXInputContainer>
        <UXInputLabel>Duration</UXInputLabel>
        <ExerciseDurationMinsController />{" "}
        <span className="duration-ratio">:</span>
        <ExerciseDurationSecsController />
      </UXInputContainer>
    </ExerciseDurationControllersContainer>
  );
};
