import {
  UXInputInput,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";
import { useExercise } from "../../../../context/loadable-exercise-context";
import { formatExerciseDuration } from "../../../../utils/format-exercise-duration";

export const ReadExerciseDurationSecsController = () => {
  const { exerciseDTO } = useExercise();
  const seconds = formatExerciseDuration(exerciseDTO.durationInSeconds).secs;

  return (
    <UXInputWrapper $width="20%" $readOnly>
      <UXInputInput
        placeholder="Secs"
        defaultValue={seconds}
        dir="auto"
        readOnly
      />
    </UXInputWrapper>
  );
};
