import { Link } from "react-router-dom";
import {
  TD,
  TDContainer,
  TDLink,
} from "../../../../components/table/styled-table";
import { ExerciseDTO } from "../../../../models/exercises-dtos/exercise-dto";
import { WorkoutsTableRowsThumbnailContainer } from "../../styles/workouts-common-styles";
import { GenericActionsList } from "../../../../components/generic-actions-list/generic-actions-list";
import { GenericActionsListItemItem } from "../../../../components/generic-actions-list/styled-generic-actions-list";
import { DeleteExerciseModal } from "./delete-exercise-modal";

type ExercisesProps = {
  exercises: ExerciseDTO[];
};

export const ExercisesTableRows = (props: ExercisesProps) => {
  const { exercises } = props;

  const tableRows = exercises.map((exerciseDTO) => (
    <tr key={exerciseDTO.id}>
      <TD width={100}>
        <WorkoutsTableRowsThumbnailContainer>
          {exerciseDTO.thumbnailUploadedFile?.presignedUrl && (
            <img
              src={exerciseDTO.thumbnailUploadedFile?.presignedUrl}
              alt={`${exerciseDTO.name}'s Exercise Thumbnail`}
            />
          )}
        </WorkoutsTableRowsThumbnailContainer>
      </TD>
      <TD>
        <TDContainer>
          <TDLink>
            <Link to={`/workouts/exercise/${exerciseDTO.id}`}>
              {exerciseDTO.name.defaultText}
            </Link>
          </TDLink>
        </TDContainer>
      </TD>
      <TD>
        <span>{exerciseDTO.durationInSeconds}</span>
      </TD>
      <TD>
        <span>{exerciseDTO.burnedKCals}</span>
      </TD>
      <TD width={100}>
        <GenericActionsList>
          <GenericActionsListItemItem>
            <Link to={`/workouts/exercise/${exerciseDTO.id}`}>View</Link>
          </GenericActionsListItemItem>
          <GenericActionsListItemItem>
            <Link to={`/workouts/exercise/${exerciseDTO.id}/update`}>
              Update
            </Link>
          </GenericActionsListItemItem>
          <DeleteExerciseModal exercise={exerciseDTO} />
        </GenericActionsList>
      </TD>
    </tr>
  ));

  return <>{tableRows}</>;
};
