import { useState } from "react";
import { ContentModuleQuestionDTO } from "../../../../../../../models/content-module-dtos/content-module-question-dto";
import {
  UXInputContainer,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import {
  LessonCardContainer,
  LessonCardHeader,
} from "../../../../../styles/content-module-lesson-classes";
import { LessonReadModeQuestionCardBody } from "./lesson-read-mode-question-card-body/lesson-read-mode-question-card-body";

type LessonReadModeQuestionCardProps = {
  question: ContentModuleQuestionDTO;
};

export const LessonReadModeQuestionCard = (
  props: LessonReadModeQuestionCardProps
) => {
  const { question } = props;
  const [showDropdown, setShowDropdown] = useState(true);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  return (
    <LessonCardContainer $showParts={showDropdown}>
      <LessonCardHeader>
        <UXInputContainer>
          <UXInputLabel $width="100px">Question</UXInputLabel>
          <UXInputWrapper
            $width="100%"
            className="content-module-name-input-wrapper"
            $readOnly
          >
            <h6 dir="auto">{question.question}</h6>
          </UXInputWrapper>
        </UXInputContainer>
        <span
          className="material-symbols-outlined show-parts-arrow"
          onClick={toggleDropdown}
        >
          expand_more
        </span>
      </LessonCardHeader>
      <LessonReadModeQuestionCardBody question={question} />
    </LessonCardContainer>
  );
};
