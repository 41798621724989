import { WorkoutsFormFormSubContainer } from "../../../../../styles/workouts-common-styles";
import { UpdateProgramDefaultController } from "./update-program-default-controller";
import { UpdateProgramDraftController } from "./update-program-draft-controller";
import { UpdateProgramArabicNameController } from "./update-program-name-arabic-controller";
import { UpdateProgramNameController } from "./update-program-name-controller";

export const UpdateProgramLeftControllers = () => {
  return (
    <WorkoutsFormFormSubContainer>
      <UpdateProgramArabicNameController />
      <UpdateProgramNameController />
      <UpdateProgramDraftController />
      <UpdateProgramDefaultController />
    </WorkoutsFormFormSubContainer>
  );
};
