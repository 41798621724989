import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { CreateContentModuleQuestionDTO } from "../../../../../../models/content-module-dtos/create-content-module-question-dto";
import { useConentModuleMobilePreviewCarousel } from "../../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewQuestionsList } from "../../mobile-preview-lesson-questions-styles";
import { CreateContentModuleLessonMobilePreviewQuestion } from "./create-content-module-lesson-mobile-preview-question";

type CreateContentModuleLessonMobilePreviewQuestionsProps = {
  contentModuleQuestions?: CreateContentModuleQuestionDTO[];
};

export const CreateContentModuleLessonMobilePreviewQuestions = (
  props: CreateContentModuleLessonMobilePreviewQuestionsProps
) => {
  const { contentModuleQuestions } = props;

  const { currentCard } = useConentModuleMobilePreviewCarousel();

  if (!contentModuleQuestions || contentModuleQuestions.length === 0) {
    return (
      <ContentModuleLessonMobilePreviewQuestionsList>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Questions Added Yet"
          title=""
        />
      </ContentModuleLessonMobilePreviewQuestionsList>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewQuestionsList>
      {contentModuleQuestions.map((question, index) => (
        <CreateContentModuleLessonMobilePreviewQuestion
          key={index}
          question={question}
          activeCard={index === currentCard}
        />
      ))}
    </ContentModuleLessonMobilePreviewQuestionsList>
  );
};
