import { ReactNode } from "react";
import {
  ContentCourseModuleLessonTabsList,
  ContentCourseModuleLessonTabsListItem,
} from "./styled-content-course-module-lesson-tabs";
import { useContentModuleLessonTabs } from "../../context/content-module-lesson-tabs-context";

type ContentCourseModuleLessonTabsProps = {
  cards: ReactNode;
  questions: ReactNode;
};

export const ContentCourseModuleLessonTabs = (
  props: ContentCourseModuleLessonTabsProps
) => {
  const { cards, questions } = props;

  const { activeTab, setActiveTab } = useContentModuleLessonTabs();

  return (
    <div>
      <ContentCourseModuleLessonTabsList>
        <ContentCourseModuleLessonTabsListItem
          $active={activeTab === "Cards"}
          onClick={() => setActiveTab("Cards")}
        >
          Cards
        </ContentCourseModuleLessonTabsListItem>
        <ContentCourseModuleLessonTabsListItem
          $active={activeTab === "Questions"}
          onClick={() => setActiveTab("Questions")}
        >
          Questions
        </ContentCourseModuleLessonTabsListItem>
      </ContentCourseModuleLessonTabsList>
      <div>{activeTab === "Cards" ? cards : questions}</div>
    </div>
  );
};
