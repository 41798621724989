import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { ContentModuleQuestionDTO } from "../../../../../../models/content-module-dtos/content-module-question-dto";
import { LessonCardsContainer } from "../../../../styles/content-module-lesson-classes";
import { LessonReadModeQuestionCard } from "./lesson-read-mode-question-card/lesson-read-mode-question-card";

type LessonReadModeQuestionsProps = {
  contentModuleQuestions?: ContentModuleQuestionDTO[];
};

export const LessonReadModeQuestions = (
  props: LessonReadModeQuestionsProps
) => {
  const { contentModuleQuestions } = props;

  if (!contentModuleQuestions || contentModuleQuestions?.length === 0) {
    return (
      <LessonCardsContainer>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Questions Added Yet"
          title=""
        />
      </LessonCardsContainer>
    );
  }

  return (
    <LessonCardsContainer>
      {contentModuleQuestions.map((question, index) => (
        <LessonReadModeQuestionCard key={index} question={question} />
      ))}
    </LessonCardsContainer>
  );
};
