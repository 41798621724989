import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { ExerciseDurationControllersContainer } from "../../../../../styles/exercise-common-styles";
import { ReadExerciseDurationMinsController } from "./read-exercise-duration-mins-controller";
import { ReadExerciseDurationSecsController } from "./read-exercise-duration-secs-controller";

export const ReadExerciseDurationControllers = () => {
  return (
    <ExerciseDurationControllersContainer>
      <UXInputContainer>
        <UXInputLabel>Duration</UXInputLabel>
        <ReadExerciseDurationMinsController />{" "}
        <span className="duration-ratio">:</span>
        <ReadExerciseDurationSecsController />
      </UXInputContainer>
    </ExerciseDurationControllersContainer>
  );
};
