import styled, { css } from "styled-components/macro";
import { Button, ErrorText } from "../../styles/classes/reusable-classes";
import { mediumFont } from "../../styles/classes/gloabl-classes";

const ImageUploadContainerDimentions = css`
  width: fit-content;
  max-width: 630px;
  margin-right: auto;
`;

export const ImageUploadContainer = styled.div<{
  $dragActive: boolean;
  $uploadedDimentions?: boolean;
  $errorState?: boolean;
}>`
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  padding: 16px;
  background: ${({ theme }) => theme.colors.inputColor};
  border: ${({ $errorState, theme }) =>
    $errorState
      ? `1px solid ${theme.colors.danger}`
      : `2px dashed ${theme.colors.borderColor}`};
  border-radius: 8px;
  cursor: pointer;
  opacity: ${({ $dragActive }) => ($dragActive ? "0.5" : "1")};
  height: 140px;
  ${({ $uploadedDimentions }) =>
    $uploadedDimentions ? ImageUploadContainerDimentions : "width: 80%;"};

  ${Button} {
    margin: 0px auto;
    padding: 8px 16px;
  }

  img {
    max-width: 240px;
    max-height: 140px;
  }

  input {
    display: none;
  }

  p {
    margin-bottom: 0px;
    margin-top: 8px;
    ${mediumFont}
  }

  span {
    font-size: 44px;
  }

  ${ErrorText} {
    font-size: ${({ theme }) => theme.typography.fontSize.smFontSize};
  }
`;

export const ImageUploadImageContainer = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  padding: 8px;
  max-height: 140px;

  img {
    margin: 0px auto;
    width: 90%;
    max-height: 130px;
  }
`;

export const ImageUploadClearIcon = styled.span`
  cursor: pointer;
  font-size: 16px !important;
  position: absolute;
  top: -5px;
  right: -15px;
`;
