import { Link, useParams } from "react-router-dom";
import { Button } from "../../../../../styles/classes/reusable-classes";
import {
  WorkoutsFormHeaderButtonContainer,
  WorkoutsFormHeaderContainer,
  WorkoutsFormHeaderTitle,
} from "../../../styles/workouts-form-header/workouts-form-header-styles";
import { useFormContext } from "react-hook-form";
import { UpdateProgramInputs } from "./types/update-program-form-inputs";

export const UpdateProgramFormHeader = () => {
  const { programID } = useParams();

  const { watch } = useFormContext<UpdateProgramInputs>();
  const programName = watch("name");
  const programNameInArabic = watch("nameInArabic");

  return (
    <WorkoutsFormHeaderContainer>
      <WorkoutsFormHeaderTitle>
        <p dir="auto">
          <span>Program</span>
          <Link dir="auto" to={`/workouts/program/${programID}`}>
            {programNameInArabic}{" "}
            {programName !== "" && programName ? `(${programName})` : ``}
          </Link>
        </p>
      </WorkoutsFormHeaderTitle>
      <WorkoutsFormHeaderButtonContainer>
        <Button type="submit">Save</Button>
      </WorkoutsFormHeaderButtonContainer>
    </WorkoutsFormHeaderContainer>
  );
};
