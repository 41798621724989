import { useMemo } from "react";
import { useAddLevelWorkoutModalAutoSelectSearchTerm } from "../context/add-level-workout-modal-auto-select-search-term-context";
import { useAddLevelWorkouts } from "../../../../../context/loadable-add-level-workouts-context";
import { ExerciseDTO } from "../../../../../../../../../../../../models/exercises-dtos/exercise-dto";
import { AddLevelWorkoutsAutoSelectListList } from "./styled-add-level-workouts-modal-auto-select-list";
import { useFormContext } from "react-hook-form";
import { AddLevelWorkoutInputs } from "../../../types/add-level-workout-inputs";
import {
  LevelExerciseListItemImageContainer,
  LevelExerciseListItemInfoContainer,
  LevelExerciseListItemInfoItem,
  LevelExerciseListItemInfoSubContainer,
  LevelExerciseListItemListItem,
  LevelExerciseListItemTextContainer,
} from "../../../../../../../../../../styles/level-workouts-styles/level-exercise-list-item-styles";
import { truncateText } from "../../../../../../../../../../../../utils/truncate-text";

type AddLevelWorkoutAutoSelectListProps = {
  showDropdown: boolean;
  append: (exerciseDTO: ExerciseDTO, index: number) => void;
};

export const AddLevelWorkoutAutoSelectList = (
  props: AddLevelWorkoutAutoSelectListProps
) => {
  const { showDropdown, append } = props;

  const { searchTerm } = useAddLevelWorkoutModalAutoSelectSearchTerm();
  const { exerciseDTOs } = useAddLevelWorkouts();

  const { watch } = useFormContext<AddLevelWorkoutInputs>();
  const workoutExercises = watch("workoutExercises");

  const filterExerciseDTOs = useMemo(() => {
    const addedExercises: ExerciseDTO[] = [];

    if (workoutExercises) {
      for (const exerciseDTO of exerciseDTOs) {
        if (
          !workoutExercises.some((item) => item.exerciseId === exerciseDTO.id)
        ) {
          addedExercises.push(exerciseDTO);
        }
      }
      return addedExercises;
    } else {
      return exerciseDTOs;
    }
  }, [workoutExercises, exerciseDTOs]);

  const searchedWorkoutExercises = useMemo(
    () =>
      filterExerciseDTOs.filter((item) =>
        item.name.defaultText.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [filterExerciseDTOs, searchTerm]
  );

  const mappedExerciseDTOs = useMemo(
    () =>
      searchedWorkoutExercises.map((exerciseDTO, index) => {
        const exerciseNameInEnglish =
          exerciseDTO.name.translations[0]?.translation;

        const displayExerciseNameInEnglish =
          exerciseNameInEnglish !== "" && exerciseNameInEnglish
            ? ` (${truncateText(exerciseNameInEnglish, 20)})`
            : ``;

        const exerciseDescriptionInEnglish =
          exerciseDTO.description.translations[0]?.translation;

        const displayExerciseDescriptionInEnglish =
          exerciseDescriptionInEnglish && exerciseDescriptionInEnglish !== ""
            ? exerciseDescriptionInEnglish
            : ``;

        const displayExerciseDescripiton = `${exerciseDTO.description.defaultText} ${displayExerciseDescriptionInEnglish}`;

        return (
          <LevelExerciseListItemListItem
            key={exerciseDTO.id}
            onClick={() => append(exerciseDTO, index)}
            $hoverActive
          >
            <LevelExerciseListItemImageContainer $width="50px" $height="60px">
              {exerciseDTO.thumbnailUploadedFile ? (
                <img
                  src={exerciseDTO.thumbnailUploadedFile?.presignedUrl}
                  alt={exerciseDTO.name.defaultText}
                />
              ) : (
                <span className="material-symbols-outlined">
                  directions_run
                </span>
              )}
            </LevelExerciseListItemImageContainer>
            <LevelExerciseListItemTextContainer>
              <p>
                {exerciseDTO.name.defaultText}
                <span
                  style={{ fontSize: "11px" }}
                  title={displayExerciseNameInEnglish}
                >
                  {displayExerciseNameInEnglish}
                </span>
              </p>
              <span title={displayExerciseDescripiton}>
                {truncateText(displayExerciseDescripiton, 35)}
              </span>
            </LevelExerciseListItemTextContainer>
            <LevelExerciseListItemInfoContainer>
              <LevelExerciseListItemInfoSubContainer>
                <LevelExerciseListItemInfoItem>
                  <span className="material-symbols-outlined">schedule</span>
                  <p>{exerciseDTO.durationInSeconds} mins</p>
                </LevelExerciseListItemInfoItem>
                <LevelExerciseListItemInfoItem>
                  <span className="material-symbols-outlined">
                    local_fire_department
                  </span>
                  <p>{exerciseDTO.burnedKCals} kcal</p>
                </LevelExerciseListItemInfoItem>
              </LevelExerciseListItemInfoSubContainer>
              <span className="material-symbols-outlined exercise-list-item-icon">
                add
              </span>
            </LevelExerciseListItemInfoContainer>
          </LevelExerciseListItemListItem>
        );
      }),
    [searchedWorkoutExercises, append]
  );

  if (searchedWorkoutExercises.length === 0 && searchTerm !== "") {
    return (
      <AddLevelWorkoutsAutoSelectListList $showDropdown={showDropdown}>
        <LevelExerciseListItemListItem $disabled>
          <span>No Results...</span>
        </LevelExerciseListItemListItem>
      </AddLevelWorkoutsAutoSelectListList>
    );
  }

  return (
    <AddLevelWorkoutsAutoSelectListList $showDropdown={showDropdown}>
      {mappedExerciseDTOs}
    </AddLevelWorkoutsAutoSelectListList>
  );
};
