import {
  UseFieldArrayMove,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";
import { useAddLevelWorkouts } from "../../../../context/loadable-add-level-workouts-context";
import { useCallback, useMemo, useRef } from "react";
import { ExerciseDTO } from "../../../../../../../../../../../models/exercises-dtos/exercise-dto";
import {
  AddLevelWorkoutsListDetails,
  AddLevelWorkoutsList,
} from "./styled-add-level-workouts-modal-body-list";
import { AddLevelWorkoutInputs } from "../../types/add-level-workout-inputs";
import { calculateWorkoutDetails } from "../../../utils/calculate-workout-details";
import {
  LevelExerciseListItemImageContainer,
  LevelExerciseListItemInfoContainer,
  LevelExerciseListItemInfoItem,
  LevelExerciseListItemInfoSubContainer,
  LevelExerciseListItemListItem,
  LevelExerciseListItemNumberContainer,
  LevelExerciseListItemTextContainer,
} from "../../../../../../../../../styles/level-workouts-styles/level-exercise-list-item-styles";
import { truncateText } from "../../../../../../../../../../../utils/truncate-text";

type AddLevelWorkoutsModalBodyListProps = {
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
};

export const AddLevelWorkoutsModalBodyList = (
  props: AddLevelWorkoutsModalBodyListProps
) => {
  const { remove, move } = props;

  const { watch, setValue, getValues } = useFormContext<
    AddLevelWorkoutInputs
  >();
  const workoutExercisesWatcher = watch("workoutExercises");

  const dragItem = useRef<number>(0);
  const draggedOverItem = useRef<number>(0);

  const handleSort = useCallback(() => {
    move(dragItem.current, draggedOverItem.current);

    // After moving, update the `uiOrder` field for all items
    setValue(
      "workoutExercises",
      getValues("workoutExercises")?.map((item, index) => ({
        ...item,
        uiOrder: index + 1, // Assuming `uiOrder` starts at 1
      }))
    );
  }, [move, getValues, setValue]);

  const { exerciseDTOs } = useAddLevelWorkouts();

  const {
    selectedExercises,
    totalWorkoutDuration,
    totalBurnedKcals,
  } = useMemo(() => {
    const selectedExercises: ExerciseDTO[] = [];

    if (workoutExercisesWatcher) {
      for (const watcherItem of workoutExercisesWatcher) {
        if (exerciseDTOs.some((item) => watcherItem.exerciseId === item.id)) {
          const exerciseIndex = exerciseDTOs.findIndex(
            (item) => watcherItem.exerciseId === item.id
          );

          selectedExercises.push(exerciseDTOs[exerciseIndex]);
        }
      }
    }

    const totalWorkoutDuration = calculateWorkoutDetails(selectedExercises)
      .totalWorkoutDuration;

    const totalBurnedKcals = calculateWorkoutDetails(selectedExercises)
      .totalBurnedKcals;

    return { selectedExercises, totalWorkoutDuration, totalBurnedKcals };
  }, [workoutExercisesWatcher, exerciseDTOs]);

  return (
    <>
      {selectedExercises.length > 0 && (
        <AddLevelWorkoutsListDetails>
          <p>
            <span className="material-symbols-outlined">schedule</span>
            {totalWorkoutDuration} mins{" "}
          </p>
          <p>
            <span className="material-symbols-outlined">
              local_fire_department
            </span>
            {totalBurnedKcals} Kcal{" "}
          </p>
        </AddLevelWorkoutsListDetails>
      )}
      <AddLevelWorkoutsList>
        {selectedExercises.map((exerciseDTO, index) => {
          const exerciseNameInEnglish =
            exerciseDTO.name.translations[0]?.translation;

          const displayExerciseNameInEnglish =
            exerciseNameInEnglish !== "" && exerciseNameInEnglish
              ? ` (${exerciseNameInEnglish})`
              : ``;

          const exerciseDescriptionInEnglish =
            exerciseDTO.description.translations[0]?.translation;

          const displayExerciseDescriptionInEnglish =
            exerciseDescriptionInEnglish && exerciseDescriptionInEnglish !== ""
              ? exerciseDescriptionInEnglish
              : ``;

          const displayExerciseDescripiton = `${exerciseDTO.description.defaultText} ${displayExerciseDescriptionInEnglish}`;

          return (
            <LevelExerciseListItemListItem
              key={exerciseDTO.id}
              draggable
              onDragStart={() => (dragItem.current = index)}
              onDragEnter={() => (draggedOverItem.current = index)}
              onDragEnd={handleSort}
              onDragOver={(e) => e.preventDefault()}
            >
              <LevelExerciseListItemNumberContainer>
                <p>{index + 1}</p>
                <span className="material-symbols-outlined">menu</span>
              </LevelExerciseListItemNumberContainer>
              <LevelExerciseListItemImageContainer
                $width={"70px"}
                $height={"60px"}
              >
                {exerciseDTO.thumbnailUploadedFile ? (
                  <img
                    src={exerciseDTO.thumbnailUploadedFile?.presignedUrl}
                    alt={exerciseDTO.name.defaultText}
                  />
                ) : (
                  <span className="material-symbols-outlined">
                    directions_run
                  </span>
                )}
              </LevelExerciseListItemImageContainer>
              <LevelExerciseListItemTextContainer>
                <p>
                  {exerciseDTO.name.defaultText}
                  <span
                    style={{ fontSize: "11px" }}
                    title={displayExerciseNameInEnglish}
                  >
                    {truncateText(displayExerciseNameInEnglish, 20)}
                  </span>
                </p>
                <span title={displayExerciseDescripiton}>
                  {truncateText(displayExerciseDescripiton, 45)}
                </span>
              </LevelExerciseListItemTextContainer>
              <LevelExerciseListItemInfoContainer>
                <LevelExerciseListItemInfoSubContainer>
                  <LevelExerciseListItemInfoItem>
                    <span className="material-symbols-outlined">schedule</span>
                    <p>{exerciseDTO.durationInSeconds} mins</p>
                  </LevelExerciseListItemInfoItem>
                  <LevelExerciseListItemInfoItem>
                    <span className="material-symbols-outlined">
                      local_fire_department
                    </span>
                    <p>{exerciseDTO.burnedKCals} kcal</p>
                  </LevelExerciseListItemInfoItem>
                </LevelExerciseListItemInfoSubContainer>
                <span
                  className="material-symbols-outlined workouts-exercises-list-item-remove-icon"
                  onClick={() => remove(index)}
                >
                  close
                </span>
              </LevelExerciseListItemInfoContainer>
            </LevelExerciseListItemListItem>
          );
        })}
      </AddLevelWorkoutsList>
    </>
  );
};
