import {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

type UpdateLevelWorkoutModalAutoSelectSearchTerm = {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  handleOnSearchInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const UpdateLevelWorkoutModalAutoSelectSearchTermContext = createContext<
  UpdateLevelWorkoutModalAutoSelectSearchTerm | undefined
>(undefined);

type UpdateLevelWorkoutModalAutoSelectSearchTermProviderProps = PropsWithChildren<{}>;

export function UpdateLevelWorkoutModalAutoSelectSearchTermProvider(
  props: UpdateLevelWorkoutModalAutoSelectSearchTermProviderProps
) {
  const { children } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const handleOnSearchInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value),
    []
  );

  return (
    <UpdateLevelWorkoutModalAutoSelectSearchTermContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        handleOnSearchInputChange,
      }}
    >
      {children}
    </UpdateLevelWorkoutModalAutoSelectSearchTermContext.Provider>
  );
}

export function useUpdateLevelWorkoutModalAutoSelectSearchTerm(): UpdateLevelWorkoutModalAutoSelectSearchTerm {
  const context = useContext(
    UpdateLevelWorkoutModalAutoSelectSearchTermContext
  );

  if (!context) {
    throw new Error(
      "useUpdateLevelWorkoutModalAutoSelectSearchTerm must be used within a UpdateLevelWorkoutModalAutoSelectSearchTermProvider"
    );
  }

  return context;
}
