import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { ContentModuleQuestionDTO } from "../../../../../../models/content-module-dtos/content-module-question-dto";
import { useConentModuleMobilePreviewCarousel } from "../../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewQuestionsList } from "../../mobile-preview-lesson-questions-styles";
import { ReadContentModuleLessonMobilePreviewQuestion } from "./read-content-module-lesson-mobile-preview-question";

type ReadContentModuleLessonMobilePreviewQuestionsProps = {
  contentModuleQuestions?: ContentModuleQuestionDTO[];
};

export const ReadContentModuleLessonMobilePreviewQuestions = (
  props: ReadContentModuleLessonMobilePreviewQuestionsProps
) => {
  const { contentModuleQuestions } = props;
  const { currentCard } = useConentModuleMobilePreviewCarousel();

  if (!contentModuleQuestions || contentModuleQuestions.length === 0) {
    return (
      <ContentModuleLessonMobilePreviewQuestionsList>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Questions Added Yet"
          title=""
        />
      </ContentModuleLessonMobilePreviewQuestionsList>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewQuestionsList>
      {contentModuleQuestions.map((question, index) => (
        <ReadContentModuleLessonMobilePreviewQuestion
          key={index}
          question={question}
          activeCard={index === currentCard}
        />
      ))}
    </ContentModuleLessonMobilePreviewQuestionsList>
  );
};
