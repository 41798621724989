import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  mediumSemiBoldFont,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";

export const LevelWorkoutsCardWorkoutContainer = styled.div<{
  $restDay: boolean;
  $readMode?: boolean;
}>`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  width: ${({ $restDay }) => ($restDay ? "150px" : "400px")};
  height: ${({ $readMode }) => ($readMode ? "325px" : "430px")};
  border-radius: 8px;

  .rest-day-text {
    ${regularSemiBoldFont}
    text-align: center;
    ${flexCenterCenter}
    height: 80%;
  }
`;

export const LevelWorkoutsCardWorkoutHeader = styled.div<{
  $restDay: boolean;
}>`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px;
  ${flexCenter}
  justify-content: ${({ $restDay }) =>
    $restDay ? "space-between" : "flex-start"};

  .level-workouts-container-header-action-move {
      cursor: grab;
      font-size: 18px;
      margin-right: 4px;
    }
`;

export const LevelWorkoutsCardWorkoutHeaderTitle = styled.p<{
  $restDay: boolean;
}>`
  margin: 0px;
  margin-left: ${({ $restDay }) => ($restDay ? "0px" : "auto")};
  text-align: center;
  ${regularRegularFont}
  transform: ${({ $restDay }) =>
    $restDay ? "translateX(0%)" : "translateX(50%)"};
`;

export const LevelWorkoutsCardWorkoutHeaderActions = styled.div`
  margin-left: auto;
  ${flexCenter}
  gap: 0px 4px;

  span {
    cursor: pointer;
    font-size: 18px;

    :last-child {
      color: ${({ theme }) => theme.colors.danger};
    }
  }
`;

export const LevelWorkoutsCardWorkoutInformation = styled.div`
  padding: 0px 8px;

  p {
    ${mediumSemiBoldFont}
    margin: 0px;
  }

  span {
    font-size: 11px;
    color: ${({ theme }) => theme.colors.textColor};
  }
`;

export const LevelWorkoutsCardWorkoutDetails = styled.div`
  ${flexCenterCenter}
  gap: 0px 16px;
  margin: 8px 0px;
`;

export const LevelWorkoutsCardWorkoutDetail = styled.div`
  ${flexCenter}
  gap: 0px 4px;

  p {
    margin: 0px;
  }

  span {
    font-size: 18px;
  }
`;

export const LevelWorkoutsCardWorkoutFooter = styled.div`
  ${flexCenterCenter}
  border-top: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 8px 0px 0px;
`;
