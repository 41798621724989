import { useCallback } from "react";
import { ModalForm } from "../../../../../../../../../../../components/modal/modal-form/modal-form";
import { ModalHeader } from "../../../../../../../../../../../components/modal/modal-header/modal-header";
import {
  Button,
  ModalFooter,
} from "../../../../../../../../../../../styles/classes/reusable-classes";
import { UpdateLevelWorkoutsModalBodyDescriptionController } from "./controllers/update-level-workouts-modal-body-description-controller";
import { UpdateLevelWorkoutsModalBodyNameController } from "./controllers/update-level-workouts-modal-body-name-controller";
import {
  UpdateLevelWorkoutsContainer,
  UpdateLevelWorkoutsHeader,
} from "./styled-update-level-workouts-modal-body";
import { UpdateLevelWorkoutModalAutoSelectSearchTermProvider } from "./update-level-workouts-modal-auto-select/context/update-level-workout-modal-auto-select-search-term-context";
import { UpdateLevelWorkoutsModalAutoSelect } from "./update-level-workouts-modal-auto-select/update-level-workouts-modal-auto-select";
import { ExerciseDTO } from "../../../../../../../../../../../models/exercises-dtos/exercise-dto";
import { UpdateLevelWorkoutInputs } from "../types/update-level-workout-inputs";
import { useFieldArray, useFormContext } from "react-hook-form";
import { UpdateLevelWorkoutsModalBodyList } from "./update-level-workouts-modal-body-list/update-level-workouts-modal-body-list";
import { useUpdateLevelWorkoutsWeeksFormHandlers } from "../../../context/update-level-workouts-weeks-form-handlers-context";
import { useUpdateLevelWorkouts } from "../../../context/loadable-update-level-workouts-context";
import { WorkoutExerciseDTO } from "../../../../../../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-exercise-dto";
import { WorkoutDTO } from "../../../../../../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-dto";
import { UpdateLevelWorkoutsModalBodyNameArabicController } from "./controllers/update-level-workouts-modal-body-name-arabic-controller";
import { UpdateLevelWorkoutsModalBodyArabicDescriptionController } from "./controllers/update-level-workouts-modal-body-description-arabic-controller";

type UpdateLevelWorkoutsModalBodyProps = {
  closeModal: () => void;
  day: number;
  editMode?: boolean;
  weekNumber: number;
};

export const UpdateLevelWorkoutsModalBody = (
  props: UpdateLevelWorkoutsModalBodyProps
) => {
  const { closeModal, day, editMode, weekNumber } = props;

  const { control, watch, setValue, getValues } = useFormContext<
    UpdateLevelWorkoutInputs
  >();

  const { append, move, remove } = useFieldArray<UpdateLevelWorkoutInputs>({
    control,
    name: `workoutExercises`,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const workoutFields = {
    dayNumber: watch("dayNumber"),
    name: {
      defaultLanguageId: 2,
      defaultText: watch("name"),
      translations:
        watch("nameInEnglish") !== "" && watch("nameInEnglish")
          ? [
              {
                languageId: 1,
                translation: watch("nameInEnglish"),
              },
            ]
          : [],
    },
    description: {
      defaultLanguageId: 2,
      defaultText: watch("description"),
      translations:
        watch("descriptionInEnglish") !== "" && watch("descriptionInEnglish")
          ? [
              {
                languageId: 1,
                translation: watch("descriptionInEnglish"),
              },
            ]
          : [],
    },
    workoutExercises: watch("workoutExercises"),
  };

  const { exerciseDTOs } = useUpdateLevelWorkouts();

  const {
    handleAppendWorkoutsToWeek,
    getWeekWorkouts,
  } = useUpdateLevelWorkoutsWeeksFormHandlers();

  const workouts = getWeekWorkouts(weekNumber);

  const handleAppendWorkouts = useCallback(() => {
    if (workoutFields && workoutFields.workoutExercises) {
      const workoutFieldExercises: WorkoutExerciseDTO[] = workoutFields.workoutExercises.map(
        (workoutExercise) => {
          const workoutExerciseDTOIndex = exerciseDTOs.findIndex(
            (exerciseDTO) => exerciseDTO.id === workoutExercise.exerciseId
          );

          const workoutExerciseDTO = exerciseDTOs[workoutExerciseDTOIndex];

          return {
            id: workoutExercise.exerciseId,
            uiOrder: workoutExercise.uiOrder,
            exercise: workoutExerciseDTO,
          };
        }
      );

      const newWorkoutsFields: WorkoutDTO = {
        dayNumber: workoutFields.dayNumber,
        name: workoutFields.name,
        description: workoutFields.description,
        workoutExercises: workoutFieldExercises,
      };

      const updatedWorkouts: WorkoutDTO[] = workouts.map((workout) =>
        workout.dayNumber === workoutFields.dayNumber
          ? { ...workout, ...newWorkoutsFields } // Update the existing workout
          : workout
      );

      handleAppendWorkoutsToWeek(
        weekNumber,
        editMode ? updatedWorkouts : [...workouts, newWorkoutsFields]
      );
    }
  }, [
    workoutFields,
    workouts,
    editMode,
    exerciseDTOs,
    handleAppendWorkoutsToWeek,
    weekNumber,
  ]);

  const handleAppendWorkoutExercises = useCallback(
    (exerciseDTO: ExerciseDTO, index: number) => {
      if (
        !workoutFields.workoutExercises?.some(
          (watcherItem) => watcherItem.exerciseId === exerciseDTO.id
        )
      ) {
        append({
          exerciseId: exerciseDTO.id,
          uiOrder: index + 1,
        });

        setValue(
          "workoutExercises",
          getValues("workoutExercises")?.map((exercise, exerciseIndex) => ({
            ...exercise,
            uiOrder: exerciseIndex + 1,
          }))
        );
      }
    },
    [append, workoutFields.workoutExercises, getValues, setValue]
  );

  return (
    <ModalForm width={800} height={800} onSubmit={(e) => e.preventDefault()}>
      <ModalHeader
        onModalClose={closeModal}
        title={
          editMode
            ? `Edit exercises for Day ${day}`
            : `Add exercises for Day ${day}`
        }
      />
      <UpdateLevelWorkoutsContainer>
        <UpdateLevelWorkoutsHeader>
          <UpdateLevelWorkoutsModalBodyNameArabicController />
          <UpdateLevelWorkoutsModalBodyNameController />
          <UpdateLevelWorkoutsModalBodyArabicDescriptionController />
          <UpdateLevelWorkoutsModalBodyDescriptionController />
          <UpdateLevelWorkoutModalAutoSelectSearchTermProvider>
            <UpdateLevelWorkoutsModalAutoSelect
              append={handleAppendWorkoutExercises}
            />
          </UpdateLevelWorkoutModalAutoSelectSearchTermProvider>
        </UpdateLevelWorkoutsHeader>
        <UpdateLevelWorkoutsModalBodyList move={move} remove={remove} />
      </UpdateLevelWorkoutsContainer>
      <ModalFooter>
        <Button outlined type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() => {
            handleAppendWorkouts();
            closeModal();
          }}
        >
          {editMode ? "Update" : "Confirm"}
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
