import { useFormContext } from "react-hook-form";
import { CreateContentModuleCardDTO } from "../../../../../models/content-module-dtos/create-content-module-card-dto";
import { CreateContentModuleQuestionDTO } from "../../../../../models/content-module-dtos/create-content-module-question-dto";
import { CreateLessonInputs } from "../../../content-course-module-lessons/create-lesson/create-lesson";
import { useContentModuleLessonTabs } from "../../../context/content-module-lesson-tabs-context";
import { ContentModuleLessonMobilePreviewFooter } from "../content-module-lesson-mobile-preview-footer/content-module-lesson-mobile-preview-footer";
import { ContentModuleLessonMobilePreviewHeader } from "../content-module-lesson-mobile-preview-header/content-module-lesson-mobile-preview-header";
import { ContentModuleLessonMobilePreviewStatusBar } from "../content-module-lesson-mobile-preview-status-bar/content-module-lesson-mobile-preview-status-bar";
import { ConentModuleMobilePreviewCarouselProvider } from "../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewContainer } from "../styled-content-module-lesson-mobile-preview";
import { CreateContentModuleLessonMobilePreviewCards } from "./create-content-module-lesson-mobile-preview-cards/create-content-module-lesson-mobile-preview-cards";
import { CreateContentModuleLessonMobilePreviewQuestions } from "./create-content-module-lesson-mobile-preview-questions/create-content-module-lesson-mobile-preview-questions";

type CreateContentModuleLessonMobilePreviewProps = {
  lessonTitle: string;
  contentModuleCards: CreateContentModuleCardDTO[];
  contentModuleQuestions?: CreateContentModuleQuestionDTO[];
};

export const CreateContentModuleLessonMobilePreview = (
  props: CreateContentModuleLessonMobilePreviewProps
) => {
  const { activeTab } = useContentModuleLessonTabs();

  const { watch } = useFormContext<CreateLessonInputs>();
  const lessonTitle = watch("name");
  const contentModuleCards = watch("contentModuleCards");
  const contentModuleQuestions = watch("contentModuleQuestions");

  return (
    <ConentModuleMobilePreviewCarouselProvider>
      <ContentModuleLessonMobilePreviewContainer>
        <ContentModuleLessonMobilePreviewStatusBar />
        <ContentModuleLessonMobilePreviewHeader
          lessonTitle={lessonTitle}
          lessonCardsLength={
            activeTab === "Questions" && contentModuleQuestions
              ? contentModuleQuestions.length
              : contentModuleCards.length
          }
        />
        {activeTab === "Cards" ? (
          <CreateContentModuleLessonMobilePreviewCards
            contentModuleCards={contentModuleCards}
          />
        ) : (
          <CreateContentModuleLessonMobilePreviewQuestions
            contentModuleQuestions={contentModuleQuestions}
          />
        )}
        <ContentModuleLessonMobilePreviewFooter
          lessonCardsLength={
            activeTab === "Questions" && contentModuleQuestions
              ? contentModuleQuestions.length
              : contentModuleCards.length
          }
        />
      </ContentModuleLessonMobilePreviewContainer>
    </ConentModuleMobilePreviewCarouselProvider>
  );
};
