import { useState } from "react";
import { useCardPartsLoading } from "../../../../context/card-parts-loading-context";
import { Controller, useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "../../../create-lesson";
import {
  LessonCardContainer,
  LessonCardHeader,
} from "../../../../../styles/content-module-lesson-classes";
import {
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { CreateLessonQuestionCardBody } from "./create-lesson-question-card-body/create-lesson-question-card-body";

type CreateLessonQuestionCardProps = {
  cardIndex: number;
  remove: () => void;
};

export const CreateLessonQuestionCard = (
  props: CreateLessonQuestionCardProps
) => {
  const { cardIndex, remove } = props;

  const { cardPartsLoading } = useCardPartsLoading();

  const [showDropdown, setShowDropdown] = useState(true);

  const toggleDropdown = () => setShowDropdown(!showDropdown);

  const { control } = useFormContext<CreateLessonInputs>();

  return (
    <LessonCardContainer $showParts={showDropdown}>
      <LessonCardHeader>
        <Controller
          control={control}
          name={`contentModuleQuestions.${cardIndex}.question`}
          render={({ field: { value, onChange } }) => (
            <UXInputContainer>
              <UXInputLabel $width="100px">Question</UXInputLabel>
              <UXInputWrapper
                $width="100%"
                className="content-module-name-input-wrapper"
              >
                <UXInputInput
                  dir="auto"
                  type="text"
                  placeholder="Enter Question"
                  value={value === undefined ? "" : value}
                  onChange={(e) =>
                    onChange(e.target.value === undefined ? "" : e.target.value)
                  }
                />
              </UXInputWrapper>
            </UXInputContainer>
          )}
        />
        {!cardPartsLoading.loading && (
          <span onClick={remove}>
            <img src="/img/trash.svg" alt="Trash Icon" />
          </span>
        )}
        <span
          className="material-symbols-outlined show-parts-arrow"
          onClick={toggleDropdown}
        >
          expand_more
        </span>
      </LessonCardHeader>
      <CreateLessonQuestionCardBody cardIndex={cardIndex} />
    </LessonCardContainer>
  );
};
