import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CreateLessonInputs } from "../../../../create-lesson";
import {
  Button,
  UXInputContainer,
  UXInputInput,
  UXInputLabel,
  UXInputTextarea,
  UXInputWrapper,
} from "../../../../../../../../styles/classes/reusable-classes";
import {
  CreateLessonQuestionCardBodyContainer,
  CreateLessonQuestionCardBodyQuestionAnswerContainer,
  CreateLessonQuestionCardBodyQuestionsTitle,
} from "./styled-create-lesson-question-card-body";

type CreateLessonQuestionCardBodyProps = {
  cardIndex: number;
};

export const CreateLessonQuestionCardBody = (
  props: CreateLessonQuestionCardBodyProps
) => {
  const { cardIndex } = props;

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateLessonInputs>();
  const choices = watch(`contentModuleQuestions.${cardIndex}.choices`);

  const { append, remove } = useFieldArray<CreateLessonInputs>({
    control,
    name: `contentModuleQuestions.${cardIndex}.choices`,
  });

  return (
    <CreateLessonQuestionCardBodyContainer className="card-parts-list">
      <CreateLessonQuestionCardBodyQuestionsTitle>
        Correct answer
      </CreateLessonQuestionCardBodyQuestionsTitle>
      {choices.map((choice, index) => {
        return (
          <CreateLessonQuestionCardBodyQuestionAnswerContainer key={index}>
            <Controller
              name={`contentModuleQuestions.${cardIndex}.choices.${index}.isCorrect`}
              control={control}
              render={({ field: { onChange } }) => (
                <input
                  type="radio"
                  checked={choice.isCorrect}
                  onChange={() => {
                    const updatedChoices = choices.map((choice, i) => ({
                      ...choice,
                      isCorrect: i === index,
                    }));

                    setValue(
                      `contentModuleQuestions.${cardIndex}.choices`,
                      updatedChoices
                    );

                    onChange(updatedChoices[index].isCorrect);
                  }}
                />
              )}
            />
            <Controller
              name={`contentModuleQuestions.${cardIndex}.choices.${index}.title`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <UXInputWrapper
                  $errorState={
                    (errors.contentModuleQuestions?.[cardIndex]?.choices?.[
                      index
                    ]?.title?.type ?? "") === "required"
                  }
                >
                  <UXInputInput
                    placeholder="Enter Answer"
                    value={
                      value === undefined
                        ? ""
                        : choices[index].title !== ""
                        ? choices[index].title
                        : value
                    }
                    onChange={(e) =>
                      onChange(
                        e.target.value === undefined ? "" : e.target.value
                      )
                    }
                    autoFocus
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission
                        append({
                          title: "",
                          isCorrect: false,
                          uiOrder: choices.length + 1,
                        });
                      }
                    }}
                  />
                </UXInputWrapper>
              )}
            />
            <span
              className="remove-lesson-card-part-icon"
              onClick={() => remove(index)}
            >
              <img
                className="cursor-pointer"
                src="/img/trash.svg"
                alt="Trash Icon"
              />
            </span>
          </CreateLessonQuestionCardBodyQuestionAnswerContainer>
        );
      })}
      <Button
        outlineNoBorder
        flex
        type="button"
        onClick={() =>
          append({ title: "", isCorrect: false, uiOrder: choices.length + 1 })
        }
      >
        <span className="material-symbols-outlined">add</span>Add Answer
      </Button>
      <Controller
        control={control}
        name={`contentModuleQuestions.${cardIndex}.correctAnswerExplination`}
        render={({ field: { onChange, value } }) => (
          <UXInputContainer $textareaDimentions style={{ marginTop: "24px" }}>
            <UXInputLabel $width="90px">Explanation</UXInputLabel>
            <UXInputWrapper $textareaDimentions>
              <UXInputTextarea
                placeholder="Enter Explanation"
                value={value === undefined ? "" : value}
                onChange={(e) =>
                  onChange(e.target.value === undefined ? "" : e.target.value)
                }
              />
            </UXInputWrapper>
          </UXInputContainer>
        )}
      />
    </CreateLessonQuestionCardBodyContainer>
  );
};
