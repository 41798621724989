import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ImageUploadHelpersProvider } from "../../../../components/image-upload/context/image-upload-helpers-context";
import { useToastService } from "../../../../context/toast-service-context";
import { useAddContentModule } from "../../../../hooks/content-module-hooks/use-add-content-module";
import { CreateContentModuleCardDTO } from "../../../../models/content-module-dtos/create-content-module-card-dto";
import { CreateContentModuleCardPartDTO } from "../../../../models/content-module-dtos/create-content-module-card-part-dto";
import { CreateContentModuleDTO } from "../../../../models/content-module-dtos/create-content-module-dto";
import { TargetAudienceDTO } from "../../../../models/target-audience-dtos/target-audience-dto";
import { useReloadContent } from "../../context/loadable-content-context";
import { ContentScreenContainer } from "../../styles/content-reusable-classes";
import { CardPartsLoadingContextProvider } from "../context/card-parts-loading-context";
import { CreateLessonControllers } from "./create-lesson-controllers/create-lesson-controllers";
import { CreateLessonHeader } from "./create-lesson-header";
import { CreateContentModuleQuestionDTO } from "../../../../models/content-module-dtos/create-content-module-question-dto";
import { ContentModuleLessonTabsProvider } from "../../context/content-module-lesson-tabs-context";

export type CreateLessonInputs = {
  name: string;
  status: boolean;
  contentModuleCards: CreateContentModuleCardDTO[];
  targetAudience: TargetAudienceDTO[];
  order?: number;
  thumbnailUploadedFileId?: number;
  contentModuleQuestions?: CreateContentModuleQuestionDTO[];
};

export const CreateLesson = () => {
  const { moduleID, courseID } = useParams();
  const parentContentModuleCourseId = parseInt(courseID!);
  const parentContentModuleId = parseInt(moduleID!);

  const createLessonFormMethods = useForm<CreateLessonInputs>({
    defaultValues: { contentModuleCards: [] },
  });

  const { showToast } = useToastService();
  const addContentModule = useAddContentModule();
  const reloadContent = useReloadContent();
  const navigate = useNavigate();

  const contentModuleCards = createLessonFormMethods.watch(
    "contentModuleCards"
  );

  const newCards = useCallback(() => {
    const newCards: CreateContentModuleCardDTO[] = [];

    for (let x = 0; x < contentModuleCards.length; x++) {
      const newParts: CreateContentModuleCardPartDTO[] = [];

      const contentModuleCardParts =
        contentModuleCards[x].contentModuleCardParts;

      const indexes = contentModuleCardParts.map((_, index) => index);

      for (let i = 0; i < contentModuleCardParts.length; i++) {
        newParts.push({
          ...contentModuleCards[x].contentModuleCardParts[i],
          uiOrder: indexes[i],
        });
      }

      newCards.push({
        ...contentModuleCards[x],
        contentModuleCardParts: newParts.map((item) => ({
          contentModuleCardPartType: item.contentModuleCardPartType,
          uiOrder: item.uiOrder,
          text: item.text,
          imageUploadedFileId: item.imageUploadedFileId,
          imageUploadedFileURL: item.imageUploadedFileURL,
          videoUploadedFileId: item.videoUploadedFileId,
          videoUploadedFileURL: item.videoUploadedFileURL,
        })),
      });
    }

    return newCards;
  }, [contentModuleCards]);

  const contentModuleQuestions = createLessonFormMethods.watch(
    "contentModuleQuestions"
  );

  const onSubmit = createLessonFormMethods.handleSubmit(async (inputs) => {
    try {
      const isValidQuestions =
        !contentModuleQuestions || // Allow submission if undefined
        contentModuleQuestions.every(
          (question) =>
            question.choices.some((choice) => choice.isCorrect) &&
            question.choices.some(
              (choice) => choice.title !== "" && choice.title !== undefined
            ) &&
            question.question !== "" &&
            question.question !== undefined
        );

      if (!isValidQuestions) {
        showToast("Error", "Choose a correct answer for each question");
        return;
      }

      const targetAudience = inputs.targetAudience;
      const createContentModuleDTO: CreateContentModuleDTO = {
        parentContentModuleId: parentContentModuleId,
        contentModuleType: "Lesson",
        uiOrder: inputs.order,
        name: inputs.name!,
        isDraft: inputs.status === true ? false : true,
        description: undefined,
        objective: undefined,
        thumbnailUploadedFileId: inputs.thumbnailUploadedFileId,
        targetAudienceIds:
          targetAudience.length > 0
            ? targetAudience.map((audience) => audience.id)
            : [],
        childContentModuleIds: [],
        contentModuleCards: newCards(),
        contentModuleQuestions: contentModuleQuestions,
      };
      await addContentModule(createContentModuleDTO).then((response) =>
        navigate(
          `../courses/course/${parentContentModuleCourseId}/module/${parentContentModuleId}/lesson/${response.id}`
        )
      );
      showToast("Success", "Lesson Added Successfully!");
      reloadContent();
    } catch (error) {
      showToast("Error", "Faild to Add Lesson :(");
    }
  });

  return (
    <CardPartsLoadingContextProvider>
      <ContentScreenContainer $lessonUI>
        <FormProvider {...createLessonFormMethods}>
          <form onSubmit={onSubmit}>
            <ContentModuleLessonTabsProvider>
              <ImageUploadHelpersProvider>
                <CreateLessonHeader />
                <CreateLessonControllers />
              </ImageUploadHelpersProvider>
            </ContentModuleLessonTabsProvider>
          </form>
        </FormProvider>
      </ContentScreenContainer>
    </CardPartsLoadingContextProvider>
  );
};
