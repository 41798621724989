import styled from "styled-components/macro";
import {
  flexCenter,
  smallRegularFont,
} from "../../../../../../../../styles/classes/gloabl-classes";
import { Button } from "../../../../../../../../styles/classes/reusable-classes";

export const CreateLessonQuestionCardBodyContainer = styled.div`
  ${Button} {
    margin-right: auto;
    margin-left: 95px;
  }
`;

export const CreateLessonQuestionCardBodyQuestionsTitle = styled.span`
  ${smallRegularFont}
  color: ${({ theme }) => theme.colors.textColor};
  margin-left: 40px;
`;

export const CreateLessonQuestionCardBodyQuestionAnswerContainer = styled.div`
  ${flexCenter}
  gap: 0px 8px;
  margin: 8px 0px;

  input[type="radio"] {
    margin-left: 95px;
  }
`;
