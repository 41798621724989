import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../../../../../../../../components/modal/modal";
import { Button } from "../../../../../../../../../../styles/classes/reusable-classes";
import { useModalHelpers } from "../../../../../../../../../../components/modal/use-modal-helpers";
import { UpdateLevelWorkoutInputs } from "./types/update-level-workout-inputs";
import { UpdateLevelWorkoutsModalBody } from "./update-level-workouts-modal-body/update-level-workouts-modal-body";

type UpdateLevelWorkoutsModalProps = {
  day: number;
  weekNumber: number;
};

export const UpdateLevelWorkoutsModal = (
  props: UpdateLevelWorkoutsModalProps
) => {
  const { day, weekNumber } = props;

  const {
    closeModal,
    isModalOpen,
    modalBodyKey,
    openModal,
  } = useModalHelpers();

  const levelWorkoutsFormMethods = useForm<UpdateLevelWorkoutInputs>();

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          openModal();
          levelWorkoutsFormMethods.setValue("dayNumber", day);
        }}
      >
        Workout Day
      </Button>
      <Modal handleHideModal={closeModal} showModal={isModalOpen}>
        <FormProvider {...levelWorkoutsFormMethods}>
          <UpdateLevelWorkoutsModalBody
            key={modalBodyKey}
            closeModal={closeModal}
            day={day}
            weekNumber={weekNumber}
          />
        </FormProvider>
      </Modal>
    </>
  );
};
