import {
  UXInputContainer,
  UXInputLabel,
} from "../../../../../../../../styles/classes/reusable-classes";
import { ExerciseDurationControllersContainer } from "../../../../../styles/exercise-common-styles";
import { UpdateExerciseDurationMinsController } from "./update-exercise-duration-mins-controller";
import { UpdateExerciseDurationSecsController } from "./update-exercise-duration-secs-controller";

export const UpdateExerciseDurationControllers = () => {
  return (
    <ExerciseDurationControllersContainer>
      <UXInputContainer>
        <UXInputLabel>Duration</UXInputLabel>
        <UpdateExerciseDurationMinsController />{" "}
        <span className="duration-ratio">:</span>
        <UpdateExerciseDurationSecsController />
      </UXInputContainer>
    </ExerciseDurationControllersContainer>
  );
};
