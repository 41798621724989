import { ContentModuleCardDTO } from "../../../../../../models/content-module-dtos/content-module-card-dto";
import {
  ContentModuleLessonMobilePreviewCardHeader,
  ContentModuleLessonMobilePreviewCardListItem,
} from "../../mobile-preview-lesson-cards-styles";
import { ContentModuleLessonMobilePreviewCardParts } from "./content-module-lesson-mobile-preview-card-parts";

type ContentModuleLessonMobilePreviewCardProps = {
  card: ContentModuleCardDTO;
  activeCard: boolean;
};

export const ContentModuleLessonMobilePreviewCard = (
  props: ContentModuleLessonMobilePreviewCardProps
) => {
  const { card, activeCard } = props;

  return (
    <ContentModuleLessonMobilePreviewCardListItem $activeCard={activeCard}>
      <ContentModuleLessonMobilePreviewCardHeader>
        <h6 dir="auto">{card.title}</h6>
      </ContentModuleLessonMobilePreviewCardHeader>
      <ContentModuleLessonMobilePreviewCardParts
        cardParts={card.contentModuleCardParts}
      />
    </ContentModuleLessonMobilePreviewCardListItem>
  );
};
