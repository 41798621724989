import { useFormContext } from "react-hook-form";
import { ContentModuleDTO } from "../../../../models/content-module-dtos/content-module-dto";
import { CreateContentModuleCardDTO } from "../../../../models/content-module-dtos/create-content-module-card-dto";
import { ContentModuleLessonMobilePreviewStatusBar } from "./content-module-lesson-mobile-preview-status-bar/content-module-lesson-mobile-preview-status-bar";
import { ContentModuleLessonMobilePreviewContainer } from "./styled-content-module-lesson-mobile-preview";
import { ContentModuleUpdateModeInputs } from "../../context/content-module-mode-context";
import { ReadContentModuleLessonMobilePreview } from "./read-content-module-lesson-mobile-preview/read-content-module-lesson-mobile-preview";
import { UpdateContentModuleLessonMobilePreview } from "./update-content-module-lesson-mobile-preview/update-content-module-lesson-mobile-preview";
import { CreateContentModuleLessonMobilePreview } from "./create-content-module-lesson-mobile-preview/create-content-module-lesson-mobile-preview";
import { CreateContentModuleQuestionDTO } from "../../../../models/content-module-dtos/create-content-module-question-dto";

type ContentModuleLessonMobilePreviewProps =
  | {
      kind: {
        mode: "Read";
        contentModuleDTO: ContentModuleDTO;
      };
    }
  | {
      kind: {
        mode: "Create";
        lessonTitle: string;
        contentModuleCards: CreateContentModuleCardDTO[];
        contentModuleQuestions?: CreateContentModuleQuestionDTO[];
      };
    }
  | {
      kind: {
        mode: "Update";
        lessonTitle: string;
      };
    };

export const ContentModuleLessonMobilePreview = (
  props: ContentModuleLessonMobilePreviewProps
) => {
  const { kind } = props;

  // This watcher is danger to use anywhere else update mode
  // It will be === undefined if used in any mode except update mode
  const { watch } = useFormContext<ContentModuleUpdateModeInputs>();
  const updateModeContentModuleCards = watch("contentModuleCards");
  const updateModeContentModuleQuestions = watch("contentModuleQuestions");

  if (kind.mode === "Read") {
    return (
      <ReadContentModuleLessonMobilePreview
        contentModuleDTO={kind.contentModuleDTO}
      />
    );
  }

  if (kind.mode === "Create") {
    return (
      <CreateContentModuleLessonMobilePreview
        lessonTitle={kind.lessonTitle}
        contentModuleCards={kind.contentModuleCards}
        contentModuleQuestions={kind.contentModuleQuestions}
      />
    );
  }

  if (kind.mode === "Update") {
    return (
      <UpdateContentModuleLessonMobilePreview
        lessonTitle={kind.lessonTitle}
        cardsLength={updateModeContentModuleCards.length}
        questionsLength={
          updateModeContentModuleQuestions
            ? updateModeContentModuleQuestions.length
            : 0
        }
      />
    );
  }

  return (
    <ContentModuleLessonMobilePreviewContainer>
      <ContentModuleLessonMobilePreviewStatusBar />
    </ContentModuleLessonMobilePreviewContainer>
  );
};
