import { Controller, useFormContext } from "react-hook-form";
import { VideoUploadHelpersProvider } from "../../../../../../../content/components/video-upload/context/video-upload-helpers-context";
import { UpdateExerciseMediaContainer } from "./update-exercise-media-container";
import { UpdateExerciseInputs } from "../../../types/update-exercise-inputs";

export const UpdateExerciseMediaController = () => {
  const { control } = useFormContext<UpdateExerciseInputs>();

  return (
    <Controller
      control={control}
      name="mediaUploadedFileId"
      rules={{ required: true }}
      render={() => (
        <VideoUploadHelpersProvider>
          <UpdateExerciseMediaContainer />
        </VideoUploadHelpersProvider>
      )}
    />
  );
};
