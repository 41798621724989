import { UpdateContentModuleLessonMobilePreviewCards } from "./update-content-module-lesson-mobile-preview-cards/update-content-module-lesson-mobile-preview-cards";
import { ContentModuleLessonMobilePreviewFooter } from "../content-module-lesson-mobile-preview-footer/content-module-lesson-mobile-preview-footer";
import { ContentModuleLessonMobilePreviewHeader } from "../content-module-lesson-mobile-preview-header/content-module-lesson-mobile-preview-header";
import { ContentModuleLessonMobilePreviewStatusBar } from "../content-module-lesson-mobile-preview-status-bar/content-module-lesson-mobile-preview-status-bar";
import { ConentModuleMobilePreviewCarouselProvider } from "../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewContainer } from "../styled-content-module-lesson-mobile-preview";
import { useContentModuleLessonTabs } from "../../../context/content-module-lesson-tabs-context";
import { UpdateContentModuleLessonMobilePreviewQuestions } from "./update-content-module-lesson-mobile-preview-questions/update-content-module-lesson-mobile-preview-questions";

type UpdateContentModuleLessonMobilePreviewProps = {
  lessonTitle: string;
  cardsLength: number;
  questionsLength: number;
};

export const UpdateContentModuleLessonMobilePreview = (
  props: UpdateContentModuleLessonMobilePreviewProps
) => {
  const { lessonTitle, cardsLength, questionsLength } = props;

  const { activeTab } = useContentModuleLessonTabs();

  return (
    <ConentModuleMobilePreviewCarouselProvider>
      <ContentModuleLessonMobilePreviewContainer>
        <ContentModuleLessonMobilePreviewStatusBar />
        <ContentModuleLessonMobilePreviewHeader
          lessonTitle={lessonTitle}
          lessonCardsLength={
            activeTab === "Questions" ? questionsLength : cardsLength
          }
        />
        {activeTab === "Cards" ? (
          <UpdateContentModuleLessonMobilePreviewCards />
        ) : (
          <UpdateContentModuleLessonMobilePreviewQuestions />
        )}
        <ContentModuleLessonMobilePreviewFooter
          lessonCardsLength={
            activeTab === "Questions" ? questionsLength : cardsLength
          }
        />
      </ContentModuleLessonMobilePreviewContainer>
    </ConentModuleMobilePreviewCarouselProvider>
  );
};
