import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useFormContext } from "react-hook-form";
import {
  ReadLevelWorkoutsWeeks,
  WorkoutWeek,
} from "../types/read-level-workout-weeks";
import { WorkoutDTO } from "../../../../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-dto";

type ReadLevelWorkoutsWeeksFormHandlers = {
  weeks: WorkoutWeek[];
  getWeekWorkouts: (weekNumber: number) => WorkoutDTO[];
};

const ReadLevelWorkoutsWeeksFormHandlersContext = createContext<
  ReadLevelWorkoutsWeeksFormHandlers | undefined
>(undefined);

type ReadLevelWorkoutsWeeksFormHandlersProviderProps = PropsWithChildren<{}>;

export function ReadLevelWorkoutsWeeksFormHandlersProvider(
  props: ReadLevelWorkoutsWeeksFormHandlersProviderProps
) {
  const { children } = props;

  const { watch } = useFormContext<ReadLevelWorkoutsWeeks>();
  const weeks = watch("weeks");

  const getWeekWorkouts = useCallback(
    (weekNumber: number) => {
      const week = weeks.find((week) => week.weekNumber === weekNumber);

      return week?.workouts || [];
    },
    [weeks]
  );

  const value: ReadLevelWorkoutsWeeksFormHandlers = useMemo(
    () => ({ weeks, getWeekWorkouts }),
    [weeks, getWeekWorkouts]
  );

  return (
    <ReadLevelWorkoutsWeeksFormHandlersContext.Provider value={value}>
      {children}
    </ReadLevelWorkoutsWeeksFormHandlersContext.Provider>
  );
}

export function useReadLevelWorkoutsWeeksFormHandlers() {
  const context = useContext(ReadLevelWorkoutsWeeksFormHandlersContext);

  if (context === undefined) {
    throw new Error(
      "useReadLevelWorkoutsWeeksFormHandlers must be used within a ReadLevelWorkoutsWeeksFormHandlersProvider"
    );
  }

  return context;
}
