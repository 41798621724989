import styled from "styled-components/macro";
import { UXInputLabel } from "../../../../../../../../../../styles/classes/reusable-classes";

export const AddLevelWorkoutsContainer = styled.div`
  border-radius: 8px;

  ${UXInputLabel} {
    margin-left: 0px;
  }
`;

export const AddLevelWorkoutsHeader = styled.div`
  display: grid;
  gap: 8px 0px;
  margin-top: 16px;
`;
