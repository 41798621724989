import { useCallback } from "react";
import { useGetAllExercises } from "../../../../../../../../hooks/exercises/use-get-all-exercises";
import { useLoadableData } from "../../../../../../../../hooks/use-loadable-data";
import { LoadableDataLoading } from "../../../../../../../../components/loadable-data-loading";
import { LoadableReadLevelWorkoutsProvider } from "./context/loadable-read-level-workouts-context";
import { ReadLevelWorkoutsContainer } from "./read-level-workouts-container/read-level-workouts-container";

export const ReadLevelWorkouts = () => {
  const getAllExercises = useGetAllExercises();

  const loadableAddLevelWorkoutsCallback = useCallback(async () => {
    const [exerciseDTOs] = await Promise.all([getAllExercises()]);

    return { exerciseDTOs };
  }, [getAllExercises]);

  const loadableAddLevelWorkouts = useLoadableData(
    loadableAddLevelWorkoutsCallback
  );

  return (
    <LoadableDataLoading
      state={loadableAddLevelWorkouts.state}
      successComponent={() => (
        <LoadableReadLevelWorkoutsProvider
          loadableReadLevelWorkouts={loadableAddLevelWorkouts}
        >
          <ReadLevelWorkoutsContainer />
        </LoadableReadLevelWorkoutsProvider>
      )}
    />
  );
};
