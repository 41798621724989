import { ContentModuleDTO } from "../../../../../models/content-module-dtos/content-module-dto";
import { useContentModuleLessonTabs } from "../../../context/content-module-lesson-tabs-context";
import { ContentModuleLessonMobilePreviewFooter } from "../content-module-lesson-mobile-preview-footer/content-module-lesson-mobile-preview-footer";
import { ContentModuleLessonMobilePreviewHeader } from "../content-module-lesson-mobile-preview-header/content-module-lesson-mobile-preview-header";
import { ReadContentModuleLessonMobilePreviewQuestions } from "./read-content-module-lesson-mobile-preview-questions/read-content-module-lesson-mobile-preview-questions";
import { ContentModuleLessonMobilePreviewStatusBar } from "../content-module-lesson-mobile-preview-status-bar/content-module-lesson-mobile-preview-status-bar";
import { ConentModuleMobilePreviewCarouselProvider } from "../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewContainer } from "../styled-content-module-lesson-mobile-preview";
import { ContentModuleLessonMobilePreviewCards } from "./read-content-module-lesson-mobile-preview-cards/content-module-lesson-mobile-preview-cards";

type ReadContentModuleLessonMobilePreviewProps = {
  contentModuleDTO: ContentModuleDTO;
};

export const ReadContentModuleLessonMobilePreview = (
  props: ReadContentModuleLessonMobilePreviewProps
) => {
  const { contentModuleDTO } = props;

  const { activeTab } = useContentModuleLessonTabs();

  return (
    <ConentModuleMobilePreviewCarouselProvider>
      <ContentModuleLessonMobilePreviewContainer>
        <ContentModuleLessonMobilePreviewStatusBar />
        <ContentModuleLessonMobilePreviewHeader
          lessonTitle={contentModuleDTO.name}
          lessonCardsLength={
            activeTab === "Questions" && contentModuleDTO.contentModuleQuestions
              ? contentModuleDTO.contentModuleQuestions.length
              : contentModuleDTO.contentModuleCards.length
          }
        />
        {activeTab === "Cards" ? (
          <ContentModuleLessonMobilePreviewCards
            contentModuleCards={contentModuleDTO.contentModuleCards}
          />
        ) : (
          <ReadContentModuleLessonMobilePreviewQuestions
            contentModuleQuestions={contentModuleDTO.contentModuleQuestions}
          />
        )}
        <ContentModuleLessonMobilePreviewFooter
          lessonCardsLength={
            activeTab === "Questions" && contentModuleDTO.contentModuleQuestions
              ? contentModuleDTO.contentModuleQuestions.length
              : contentModuleDTO.contentModuleCards.length
          }
        />
      </ContentModuleLessonMobilePreviewContainer>
    </ConentModuleMobilePreviewCarouselProvider>
  );
};
