import { PutContentModuleQuestionDTO } from "../../../../../../models/content-module-dtos/put-content-module-question-dto";
import {
  ContentModuleLessonMobilePreviewQuestionAnswer,
  ContentModuleLessonMobilePreviewQuestionAnswers,
  ContentModuleLessonMobilePreviewQuestionsListItem,
  ContentModuleLessonMobilePreviewQuestionTitle,
} from "../../mobile-preview-lesson-questions-styles";

type UpdateContentModuleLessonMobilePreviewQuestionProps = {
  question: PutContentModuleQuestionDTO;
  activeCard: boolean;
};

export const UpdateContentModuleLessonMobilePreviewQuestion = (
  props: UpdateContentModuleLessonMobilePreviewQuestionProps
) => {
  const { question, activeCard } = props;

  return (
    <ContentModuleLessonMobilePreviewQuestionsListItem $activeCard={activeCard}>
      <ContentModuleLessonMobilePreviewQuestionTitle>
        {question.question}
      </ContentModuleLessonMobilePreviewQuestionTitle>
      <ContentModuleLessonMobilePreviewQuestionAnswers>
        {question.choices.map((choice, index) => (
          <ContentModuleLessonMobilePreviewQuestionAnswer key={index}>
            {choice.title}
          </ContentModuleLessonMobilePreviewQuestionAnswer>
        ))}
      </ContentModuleLessonMobilePreviewQuestionAnswers>
    </ContentModuleLessonMobilePreviewQuestionsListItem>
  );
};
