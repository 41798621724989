import styled from "styled-components/macro";
import {
  flexCenter,
  flexCenterCenter,
  regularRegularFont,
  regularSemiBoldFont,
} from "../../../../styles/classes/gloabl-classes";
import { transition } from "../../../../styles/global-style";
import { Button } from "../../../../styles/classes/reusable-classes";

export const LevelWorkoutsContainerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  width: 95%;
  margin: 16px auto 0px;
  background-color: #fffcfdff;

  .workout-submit {
    margin-left: auto;
  }
`;

export const LevelWorkoutsContainerHeader = styled.div`
  background: ${({ theme }) => theme.colors.bodyColor};
  padding: 8px;
  ${flexCenter}

  .level-workouts-container-header-action-move {
    cursor: grab;
    font-size: 18px;
  }
`;

export const LevelWorkoutsContainerTitle = styled.p`
  margin: 0px 0px 0px auto;
  transform: translateX(50%);
  text-align: center;
  ${regularSemiBoldFont}
`;

export const LevelWorkoutsContainerHeaderActions = styled.div<{
  $showDropdown: boolean;
}>`
  ${flexCenter}
  gap: 0px 4px;
  margin-left: auto;

  span {
    cursor: pointer;
    font-size: 18px;
  }

  .level-workouts-container-header-action-remove {
    color: ${({ theme }) => theme.colors.danger};
  }

  .level-workouts-container-header-action-dropdown {
    transform: ${({ $showDropdown }) =>
      $showDropdown ? "rotate(-180deg)" : "rotate(0deg)"};
    font-size: 24px;
    transition: ${transition};
  }
`;

export const LevelWorkoutsContainerList = styled.ul<{ $showDropdown: boolean }>`
  ${flexCenterCenter}
  gap: 0px 16px;
  padding: 0px 8px;
  display: ${({ $showDropdown }) => ($showDropdown ? "flex" : "none")};
`;

export const LevelWorkoutsContainerListItem = styled.li<{ $disabled: boolean }>`
  display: grid;
  gap: 8px 0px;
  padding: 8px;
  border: 3px dashed ${({ theme }) => theme.colors.borderColor};
  border-radius: 8px;
  pointer-events: ${({ $disabled }) => ($disabled ? "none" : "auto")};
  opacity: ${({ $disabled }) => ($disabled ? "0.6" : "1")};

  p {
    margin: 0px;
    text-align: center;
    ${regularRegularFont}
  }

  ${Button} {
    padding: 8px 16px;
    font-size: 14px;
  }
`;

export const LevelWorkoutsContainerFooter = styled.div`
  ${flexCenterCenter}

  ${Button} {
    font-size: 14px;
    height: auto;
    padding: 8px 16px;
  }
`;
