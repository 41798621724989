import { EmptyState } from "../../../../../../components/empty-state/empty-state";
import { ContentModuleCardDTO } from "../../../../../../models/content-module-dtos/content-module-card-dto";
import { useConentModuleMobilePreviewCarousel } from "../../context/conent-module-mobile-preview-carousel-context";
import { ContentModuleLessonMobilePreviewCardsList } from "../../mobile-preview-lesson-cards-styles";
import { ContentModuleLessonMobilePreviewCard } from "./content-module-lesson-mobile-preview-card";

type ContentModuleLessonMobilePreviewCardsProps = {
  contentModuleCards: ContentModuleCardDTO[];
};

export const ContentModuleLessonMobilePreviewCards = (
  props: ContentModuleLessonMobilePreviewCardsProps
) => {
  const { contentModuleCards } = props;

  const { currentCard } = useConentModuleMobilePreviewCarousel();

  if (contentModuleCards.length === 0) {
    return (
      <ContentModuleLessonMobilePreviewCardsList>
        <EmptyState
          button={<></>}
          hasHeader={false}
          hasSearch={false}
          text="No Cards Added Yet"
          title=""
        />
      </ContentModuleLessonMobilePreviewCardsList>
    );
  }

  return (
    <ContentModuleLessonMobilePreviewCardsList>
      {contentModuleCards.map((card, index) => (
        <ContentModuleLessonMobilePreviewCard
          key={index}
          card={card}
          activeCard={index === currentCard}
        />
      ))}
    </ContentModuleLessonMobilePreviewCardsList>
  );
};
