import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  UpdateLevelWorkoutsWeeks,
  WorkoutWeek,
} from "../types/update-level-workouts-weeks";
import { WorkoutDTO } from "../../../../../../../../../models/workout-programs-dtos/workout-program-dtos/workout-dto";
import { generateWeekDays } from "../../../../add-level/add-level-workouts/add-level-workouts-container/utils/generate-week-days";

type UpdateLevelWorkoutsWeeksFormHandlers = {
  weeks: WorkoutWeek[];
  handleAppendWeek: VoidFunction;
  handleRemoveWeek: (index: number) => void;
  weekDragItem: React.MutableRefObject<number>;
  weekDraggedOverItem: React.MutableRefObject<number>;
  handleSortWeeks: VoidFunction;
  handleDuplicateWeek: (weekNumber: number) => void;
  // Workouts
  handleAppendWorkoutsToWeek: (
    weekNumber: number,
    workouts: WorkoutDTO[]
  ) => void;
  handleAppendWorkoutToWeek: (weekNumber: number, workout: WorkoutDTO) => void;
  getWeekWorkouts: (weekNumber: number) => WorkoutDTO[];
  handleRemoveWorkoutFromWeek: (
    weekNumber: number,
    workoutDayNumber: number
  ) => void;
  handleDuplicateWorkout: (
    weekNumber: number,
    workoutDayNumber: number
  ) => void;
  handleSortWorkoutsInWeek: (
    weekNumber: number,
    dragWrokoutIndex: number,
    dragOverWorkoutIndex: number
  ) => void;
  workoutDragItem: React.MutableRefObject<number>;
  workoutDraggedOverItem: React.MutableRefObject<number>;
};

const UpdateLevelWorkoutsWeeksFormHandlersContext = createContext<
  UpdateLevelWorkoutsWeeksFormHandlers | undefined
>(undefined);

type UpdateLevelWorkoutsWeeksFormHandlersProviderProps = PropsWithChildren<{}>;

export function UpdateLevelWorkoutsWeeksFormHandlersProvider(
  props: UpdateLevelWorkoutsWeeksFormHandlersProviderProps
) {
  const { children } = props;

  const { watch, control, setValue, getValues } = useFormContext<
    UpdateLevelWorkoutsWeeks
  >();
  const weeks = watch("weeks");

  const { append, remove, update, move } = useFieldArray<
    UpdateLevelWorkoutsWeeks
  >({
    control,
    name: "weeks",
  });

  const weekDragItem = useRef<number>(0);
  const weekDraggedOverItem = useRef<number>(0);

  const handleAppendWeek = useCallback(() => {
    const lastWeek = weeks?.[weeks.length - 1];
    const newWeekNumber = lastWeek ? lastWeek.weekNumber + 1 : 1;
    const lastDay = lastWeek?.days?.[lastWeek.days.length - 1] || 0;

    append({
      weekNumber: newWeekNumber,
      days: Array.from({ length: 7 }, (_, i) => lastDay + i + 1),
      workouts: [],
    });
  }, [append, weeks]);

  const handleRemoveWeek = useCallback(
    (index: number) => {
      if (index !== 0) {
        remove(index);

        const updatedWeeks = weeks.slice(index + 1).map((week, idx) => {
          const newWeekNumber = week.weekNumber - 1;
          const firstDay = weeks[index].days[0] || 1;
          const newDays = Array.from(
            { length: 7 },
            (_, i) => firstDay + idx * 7 + i
          );

          return {
            weekNumber: newWeekNumber,
            days: newDays,
            workouts: week.workouts.map((workout, workoutIndex) => ({
              ...workout,
              dayNumber: generateWeekDays(newWeekNumber)[workoutIndex],
            })),
          };
        });

        updatedWeeks.forEach((week, idx) => {
          update(index + idx, week);
        });
      } else {
        update(0, { weekNumber: 1, days: [1, 2, 3, 4, 5, 6, 7], workouts: [] });
      }
    },
    [remove, update, weeks]
  );

  const handleSortWeeks = useCallback(() => {
    const lastWeek = weeks[weeks.length - 1];
    const lastWeekWorkouts = lastWeek.workouts;

    if (lastWeekWorkouts.length !== 7) {
      alert("The last week must be full of workouts before moving weeks.");
      return;
    }

    move(weekDragItem.current, weekDraggedOverItem.current);

    setValue(
      "weeks",
      getValues("weeks")?.map((item, index) => ({
        weekNumber: index + 1,
        days: generateWeekDays(index + 1),
        workouts: item.workouts.map((workout, workoutIndex) => ({
          ...workout,
          dayNumber: generateWeekDays(index + 1)[workoutIndex],
        })),
      }))
    );
  }, [getValues, move, setValue, weeks]);

  const handleDuplicateWeek = useCallback(
    (weekNumber: number) => {
      const duplicatedWeek = weeks.find(
        (week) => week.weekNumber === weekNumber
      );

      const newWeekNumber = weeks.length + 1;

      if (duplicatedWeek) {
        const newDays = duplicatedWeek.days.map((day) => day + 7);

        append({
          weekNumber: newWeekNumber,
          days: newDays!,
          workouts: duplicatedWeek.workouts.map((workout, workoutIndex) => ({
            ...workout,
            dayNumber: generateWeekDays(newWeekNumber)[workoutIndex],
          })),
        });
      }
    },
    [append, weeks]
  );

  // Workouts
  const getWeekWorkouts = useCallback(
    (weekNumber: number) => {
      const week = weeks.find((week) => week.weekNumber === weekNumber);

      return week?.workouts || [];
    },
    [weeks]
  );

  const handleAppendWorkoutsToWeek = useCallback(
    (weekNumber: number, workouts: WorkoutDTO[]) => {
      const weekIndex = weeks.findIndex(
        (week) => week.weekNumber === weekNumber
      );

      if (weekIndex !== -1) {
        const updatedWeek = {
          ...weeks[weekIndex],
          workouts,
        };

        update(weekIndex, updatedWeek);
      }
    },
    [update, weeks]
  );

  const handleAppendWorkoutToWeek = useCallback(
    (weekNumber: number, workout: WorkoutDTO) => {
      const weekIndex = weeks.findIndex(
        (week) => week.weekNumber === weekNumber
      );

      if (weekIndex !== -1) {
        const updatedWeek = {
          ...weeks[weekIndex],
          workouts: [...weeks[weekIndex].workouts, workout],
        };

        update(weekIndex, updatedWeek);
      }
    },
    [update, weeks]
  );

  const handleRemoveWorkoutFromWeek = useCallback(
    (weekNumber: number, workoutDayNumber: number) => {
      const weekIndex = weeks.findIndex(
        (week) => week.weekNumber === weekNumber
      );

      if (weekIndex !== -1) {
        const week = weeks[weekIndex];

        const updatedWorkouts = week.workouts
          .filter((workout) => workout.dayNumber !== workoutDayNumber)
          .map((w, widx) => ({
            ...w,
            dayNumber: generateWeekDays(weekNumber)[widx],
          }));

        const updatedWeek = {
          ...week,
          workouts: updatedWorkouts,
        };

        if (updatedWorkouts.length === 0) {
          handleRemoveWeek(weekNumber - 1);
        }

        setValue(
          "weeks",
          getValues("weeks")?.map((item, index) =>
            index === weekNumber - 1 ? updatedWeek : item
          )
        );
      }
    },
    [setValue, getValues, weeks, handleRemoveWeek]
  );

  const handleDuplicateWorkout = useCallback(
    (weekNumber: number, workoutDayNumber: number) => {
      const weekIndex = weeks.findIndex(
        (week) => week.weekNumber === weekNumber
      );

      if (weekIndex !== -1) {
        const week = weeks[weekIndex];
        const workout = week.workouts.find(
          (workout) => workout.dayNumber === workoutDayNumber
        );

        if (workout) {
          if (weeks[weekIndex].workouts.length < 7) {
            // Duplicate workout in the same week
            const updatedWorkouts = [...week.workouts, workout];

            const updatedWeek = {
              ...week,
              workouts: updatedWorkouts.map((w, widx) => ({
                ...w,
                dayNumber: generateWeekDays(weekNumber)[widx],
              })),
            };

            setValue(
              "weeks",
              getValues("weeks")?.map((item, index) =>
                index === weekNumber - 1 ? updatedWeek : item
              )
            );
          } else {
            const newWeekNumber = weeks.length + 1;
            handleAppendWeek();

            // Wait for the new state update
            setTimeout(() => {
              const updatedWeeks = getValues("weeks");
              const newWeek = updatedWeeks?.find(
                (week) => week.weekNumber === newWeekNumber
              );

              if (newWeek) {
                const updatedNewWeek = {
                  ...newWeek,
                  workouts: [...newWeek.workouts, workout].map((w, widx) => ({
                    ...w,
                    dayNumber: generateWeekDays(newWeekNumber)[widx],
                  })),
                };

                setValue(
                  "weeks",
                  updatedWeeks.map((week) =>
                    week.weekNumber === newWeekNumber ? updatedNewWeek : week
                  )
                );
              }
            }, 0);
          }
        }
      }
    },
    [setValue, weeks, handleAppendWeek, getValues]
  );

  const workoutDragItem = useRef<number>(0);
  const workoutDraggedOverItem = useRef<number>(0);

  const handleSortWorkoutsInWeek = useCallback(
    (
      weekNumber: number,
      dragWrokoutIndex: number,
      dragOverWorkoutIndex: number
    ) => {
      const weekIndex = weeks.findIndex(
        (week) => week.weekNumber === weekNumber
      );

      if (weekIndex !== -1) {
        const week = weeks[weekIndex];

        const updatedWorkouts = week.workouts.map((workout, workoutIndex) => {
          if (workoutIndex === dragWrokoutIndex) {
            return week.workouts[dragOverWorkoutIndex];
          } else if (workoutIndex === dragOverWorkoutIndex) {
            return week.workouts[dragWrokoutIndex];
          } else {
            return workout;
          }
        });

        const updatedWeek = {
          ...week,
          workouts: updatedWorkouts.map((w, widx) => ({
            ...w,
            dayNumber: generateWeekDays(weekNumber)[widx],
          })),
        };

        setValue(
          "weeks",
          getValues("weeks")?.map((item, index) =>
            index === weekNumber - 1 ? updatedWeek : item
          )
        );
      }
    },
    [getValues, setValue, weeks]
  );

  const value: UpdateLevelWorkoutsWeeksFormHandlers = useMemo(
    () => ({
      weeks,
      handleAppendWeek,
      handleRemoveWeek,
      weekDragItem,
      weekDraggedOverItem,
      handleSortWeeks,
      handleDuplicateWeek,
      handleAppendWorkoutsToWeek,
      handleAppendWorkoutToWeek,
      getWeekWorkouts,
      handleRemoveWorkoutFromWeek,
      handleDuplicateWorkout,
      handleSortWorkoutsInWeek,
      workoutDragItem,
      workoutDraggedOverItem,
    }),
    [
      weeks,
      handleAppendWeek,
      handleRemoveWeek,
      weekDragItem,
      weekDraggedOverItem,
      handleSortWeeks,
      handleDuplicateWeek,
      handleAppendWorkoutsToWeek,
      handleAppendWorkoutToWeek,
      getWeekWorkouts,
      handleRemoveWorkoutFromWeek,
      handleDuplicateWorkout,
      handleSortWorkoutsInWeek,
      workoutDragItem,
      workoutDraggedOverItem,
    ]
  );

  return (
    <UpdateLevelWorkoutsWeeksFormHandlersContext.Provider value={value}>
      {children}
    </UpdateLevelWorkoutsWeeksFormHandlersContext.Provider>
  );
}

export function useUpdateLevelWorkoutsWeeksFormHandlers() {
  const context = useContext(UpdateLevelWorkoutsWeeksFormHandlersContext);

  if (context === undefined) {
    throw new Error(
      "useUpdateLevelWorkoutsWeeksFormHandlers must be used within a UpdateLevelWorkoutsWeeksFormHandlersProvider"
    );
  }

  return context;
}
