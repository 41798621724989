import { FormProvider, useForm } from "react-hook-form";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
} from "../../../styles/workouts-common-styles";
import { ReadProgramInputs } from "./types/read-programs-inputs";
import { useWorkoutProgram } from "../context/loadable-workout-program-context";
import { ReadProgramLeftControllers } from "./controllers/read-program-left-controllers/read-program-left-controllers";
import { ReadProgramCoverPhotoController } from "./controllers/read-program-right-controllers/read-program-cover-photo-controller";
import { Levels } from "../levels/levels";
import { ReadProgramFormHeader } from "./read-program-form-header";

export const ReadProgramForm = () => {
  const { workoutProgramDTO } = useWorkoutProgram();

  const readProgramInputsFormMethods = useForm<ReadProgramInputs>({
    defaultValues: {
      isDefault: workoutProgramDTO.isDefault,
      isDraft: workoutProgramDTO.isDraft,
    },
  });

  return (
    <FormProvider {...readProgramInputsFormMethods}>
      <form>
        <ReadProgramFormHeader />
        <WorkoutsFormContainer>
          <WorkoutsFormControllersContainer>
            <ReadProgramLeftControllers />
            <WorkoutsFormFormSubContainer>
              <ReadProgramCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
          <Levels />
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
