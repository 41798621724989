import { Controller, useFormContext } from "react-hook-form";
import {
  UXInputInput,
  UXInputWrapper,
} from "../../../../../../../styles/classes/reusable-classes";
import { AddExerciseInputs } from "../../../types/add-exercise-inputs";

export const ExerciseDurationSecsController = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<AddExerciseInputs>();

  return (
    <Controller
      control={control}
      name="durationInSeconds"
      rules={{ required: true }}
      render={({ field: { value, onChange } }) => (
        <UXInputWrapper
          $width="20%"
          $errorState={errors.durationInSeconds?.type === "required"}
        >
          <UXInputInput
            placeholder="Secs"
            value={value === undefined ? "" : value}
            dir="auto"
            onChange={(e) =>
              onChange(
                e.target.value === undefined ? undefined : e.target.value
              )
            }
          />
        </UXInputWrapper>
      )}
    />
  );
};
