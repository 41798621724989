import { FormProvider, useForm } from "react-hook-form";
import {
  WorkoutsFormContainer,
  WorkoutsFormControllersContainer,
  WorkoutsFormFormSubContainer,
} from "../../../../../styles/workouts-common-styles";
import { useLevelState } from "../context/level-state-context";
import { UpdateLevelInputs } from "./types/update-level-inputs";
import {
  useReloadWorkoutProgram,
  useWorkoutProgram,
} from "../../../context/loadable-workout-program-context";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateLevelLeftControllers } from "./controllers/update-level-left-controllers/update-level-left-controllers";
import { UpdateLevelCoverPhotoController } from "./controllers/update-level-right-controllers/update-level-cover-photo-controller";
import { useToastService } from "../../../../../../../context/toast-service-context";
import { usePutWorkoutProgram } from "../../../../../../../hooks/workout-program-hooks/use-put-workout-program";
import { PutWorkoutProgramDTO } from "../../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-dto";
import { PutWorkoutProgramLevelDTO } from "../../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-program-level-dto";
import { UpdateLevelWorkouts } from "./update-level-workouts/update-level-workouts";
import { UpdateLevelWorkoutsWeeksFormHandlersProvider } from "./update-level-workouts/context/update-level-workouts-weeks-form-handlers-context";
import { UpdateLevelWorkoutsWeeks } from "./update-level-workouts/types/update-level-workouts-weeks";
import { generateWeeks } from "../read-level-form/utils/generate-weeks";
import { PutWorkoutDTO } from "../../../../../../../models/workout-programs-dtos/put-workout-program-dtos/put-workout-dto";
import { UpdateLevelFormHeader } from "./update-level-form-header";

export const UpdateLevelForm = () => {
  const { setLevelState } = useLevelState();
  const { workoutProgramDTO } = useWorkoutProgram();
  const reloadWorkoutProgram = useReloadWorkoutProgram();
  const { showToast } = useToastService();
  const { levelID } = useParams();
  const putWorkoutProgram = usePutWorkoutProgram();
  const navigate = useNavigate();

  const currentLevel = workoutProgramDTO.workoutProgramLevels?.find(
    (item) => item.id === parseInt(levelID!)
  );

  const updateLevelFormMethods = useForm<UpdateLevelInputs>({
    defaultValues: {
      coverPhotoUploadedFileId: currentLevel?.coverPhotoUploadedFileId,
      daysCount: currentLevel?.daysCount,
      name: currentLevel?.name.defaultText,
      nameInEnglish: currentLevel?.name.translations[0]?.translation,
      uiOrder: currentLevel?.uiOrder,
      workouts: currentLevel?.workouts,
    },
  });

  const updateLevelWorkoutsWeeksFormMethods = useForm<UpdateLevelWorkoutsWeeks>(
    {
      defaultValues: {
        weeks:
          currentLevel?.workouts?.length === 0
            ? [{ weekNumber: 1, days: [1, 2, 3, 4, 5, 6, 7], workouts: [] }]
            : generateWeeks(
                currentLevel?.workouts ? currentLevel.workouts : []
              ),
      },
    }
  );

  const workouts = updateLevelWorkoutsWeeksFormMethods
    .watch("weeks")
    .flatMap((week) => week.workouts);

  const onSubmit = updateLevelFormMethods.handleSubmit(async (inputs) => {
    try {
      if (currentLevel) {
        const newLevel: PutWorkoutProgramLevelDTO = {
          daysCount: Number(inputs.daysCount),
          name: {
            defaultLanguageId: 2,
            defaultText: inputs.name,
            translations:
              inputs.nameInEnglish !== "" && inputs.nameInEnglish
                ? [
                    {
                      languageId: 1,
                      translation: inputs.nameInEnglish,
                    },
                  ]
                : [],
          },
          uiOrder: Number(inputs.uiOrder),
          coverPhotoUploadedFileId: inputs.coverPhotoUploadedFileId,
          workouts:
            workouts.length > 0
              ? workouts
                  .filter((workout) => workout.name.defaultText !== "Rest Day")
                  .map((item) => ({
                    dayNumber: item.dayNumber,
                    description: item.description,
                    name: item.name,
                    // The reason why I didn't write ...item is that I don't want the ID field
                    // When Duplicating an exercise
                    workoutExercises: item.workoutExercises?.map((i) => ({
                      exerciseId: i.exercise.id,
                      uiOrder: i.uiOrder,
                    })),
                  }))
              : undefined,
        };

        const newWorkoutLevels = workoutProgramDTO.workoutProgramLevels?.map(
          (item) => {
            if (item.id === currentLevel.id) {
              return {
                ...newLevel,
                id: currentLevel.id,
              };
            } else {
              return {
                ...item,
                workouts: item.workouts
                  ? (item.workouts.map((itemWorkout) => ({
                      ...itemWorkout,
                      workoutExercises: itemWorkout.workoutExercises?.map(
                        (i) => ({
                          exerciseId: i.exercise.id,
                          uiOrder: i.uiOrder,
                        })
                      ),
                    })) as PutWorkoutDTO[])
                  : undefined,
              };
            }
          }
        );

        const putWorkoutProgramDTO: PutWorkoutProgramDTO = {
          ...workoutProgramDTO,
          workoutProgramLevels: newWorkoutLevels as PutWorkoutProgramLevelDTO[],
        };

        await putWorkoutProgram(
          workoutProgramDTO.id,
          putWorkoutProgramDTO
        ).then((res) =>
          navigate(`/workouts/program/${res.id}/level/${levelID}`)
        );
        showToast("Success", "Level Updated Successfully");
        setLevelState("Read");
        reloadWorkoutProgram();
      }
    } catch (error) {
      showToast("Error", "Failed to update Level :(");
    }
  });

  return (
    <FormProvider {...updateLevelFormMethods}>
      <form onSubmit={onSubmit}>
        <UpdateLevelFormHeader />
        <WorkoutsFormContainer $level>
          <WorkoutsFormControllersContainer>
            <UpdateLevelLeftControllers />
            <WorkoutsFormFormSubContainer>
              <UpdateLevelCoverPhotoController />
            </WorkoutsFormFormSubContainer>
          </WorkoutsFormControllersContainer>
          <FormProvider {...updateLevelWorkoutsWeeksFormMethods}>
            <UpdateLevelWorkoutsWeeksFormHandlersProvider>
              <UpdateLevelWorkouts />
            </UpdateLevelWorkoutsWeeksFormHandlersProvider>
          </FormProvider>
        </WorkoutsFormContainer>
      </form>
    </FormProvider>
  );
};
