import { useEffect, useState } from "react";
import { useReadLevelWorkoutsWeeksFormHandlers } from "../context/read-level-workouts-weeks-form-handlers-context";
import {
  LevelWorkoutsContainerList,
  LevelWorkoutsContainerWrapper,
} from "../../../../../../../styles/level-workouts-styles/level-workouts-container-styles";
import { ReadLevelWorkoutsWeeksHeader } from "./read-level-workouts-weeks-header/read-level-workouts-weeks-header";
import { ReadLevelWorkoutsCards } from "./read-level-workouts-cards/read-level-workouts-cards";

export const ReadLevelWorkoutsContainer = () => {
  const { weeks } = useReadLevelWorkoutsWeeksFormHandlers();

  const [dropdownStates, setDropdownStates] = useState<boolean[]>([]);

  useEffect(() => {
    setDropdownStates((prevStates) =>
      weeks.map((_, index) => prevStates[index] || true)
    );
  }, [weeks]);

  const toggleDropdown = (weekIndex: number) => {
    setDropdownStates((prevStates) =>
      prevStates.map((state, index) => (index === weekIndex ? !state : state))
    );
  };

  return (
    <>
      {weeks.map((week, weekIndex) => (
        <LevelWorkoutsContainerWrapper key={weekIndex}>
          <ReadLevelWorkoutsWeeksHeader
            showDropdown={dropdownStates[weekIndex]}
            toggleDropdown={() => toggleDropdown(weekIndex)}
            week={week}
          />
          <LevelWorkoutsContainerList $showDropdown={dropdownStates[weekIndex]}>
            {week.days.map((day, index) => (
              <ReadLevelWorkoutsCards
                key={index}
                day={day}
                weekNumber={week.weekNumber}
              />
            ))}
          </LevelWorkoutsContainerList>
        </LevelWorkoutsContainerWrapper>
      ))}
    </>
  );
};
