import { useEffect } from "react";
import { Loader } from "../../../../components/loaders/otida-loader/styled-otida-loader";
import { Button } from "../../../../styles/classes/reusable-classes";
import { useVideoUploadHelpers } from "./context/video-upload-helpers-context";
import {
  VideoUploadClearIcon,
  VideoUploadContainer,
  VideoUploadVideo,
  VideoUploadVideoContainer,
} from "./styled-video-upload";

type VideoUploadProps = {
  uploadText?: string;
  readOnly?: boolean;
  errorState?: boolean;
};

export const VideoUpload = (props: VideoUploadProps) => {
  const { uploadText, readOnly, errorState } = props;

  const {
    videoFile,
    videoUploadState,
    handleDrag,
    handleDrop,
    handleFileChange,
    handleRemoveVideo,
    loading,
    setVideoFile,
    setVideoUploadState,
    videoFileInputRef,
  } = useVideoUploadHelpers();

  const isVideoUploading = videoFile && videoUploadState.state === "Upload";

  useEffect(() => {
    if (
      videoUploadState.state === "Upload" &&
      videoUploadState.previewUrl === undefined
    ) {
      setVideoUploadState({ state: "Idle" });
      setVideoFile(null);
    }
  }, [videoUploadState, setVideoFile, setVideoUploadState]);

  if (loading) {
    return (
      <VideoUploadContainer $dragActive={false} $errorState={errorState}>
        <VideoUploadVideoContainer>
          <Loader radius={80} />
        </VideoUploadVideoContainer>
      </VideoUploadContainer>
    );
  }
  if (videoUploadState.state === "Uploaded") {
    return (
      <VideoUploadContainer $dragActive={false} $uploadedDimentions>
        <VideoUploadVideoContainer>
          <VideoUploadVideo src={videoUploadState.previewUrl} controls>
            Your browser does not support the video tag.
          </VideoUploadVideo>
          {!readOnly && (
            <VideoUploadClearIcon
              className="material-symbols-outlined"
              onClick={handleRemoveVideo}
            >
              close
            </VideoUploadClearIcon>
          )}
        </VideoUploadVideoContainer>
      </VideoUploadContainer>
    );
  }

  if (isVideoUploading) {
    return (
      <VideoUploadContainer
        onDragEnter={(event) => handleDrag(event)}
        onDragLeave={(event) => handleDrag(event)}
        onDragOver={(event) => handleDrag(event)}
        onDrop={(event) => handleDrop(event)}
        $dragActive={false}
      >
        <VideoUploadVideoContainer>
          <video src={videoUploadState.previewUrl} controls>
            Your browser does not support the video tag.
          </video>
          {!readOnly && (
            <VideoUploadClearIcon
              className="material-symbols-outlined"
              onClick={handleRemoveVideo}
            >
              close
            </VideoUploadClearIcon>
          )}
        </VideoUploadVideoContainer>
      </VideoUploadContainer>
    );
  }

  if (
    (videoUploadState.state === "Drag" || videoUploadState.state === "Idle") &&
    !readOnly
  ) {
    return (
      <VideoUploadContainer
        onDragEnter={(event) => handleDrag(event)}
        onDragLeave={(event) => handleDrag(event)}
        onDragOver={(event) => handleDrag(event)}
        onDrop={(event) => handleDrop(event)}
        $dragActive={
          videoUploadState.state === "Drag" && videoUploadState.dragActive
        }
        onClick={() => videoFileInputRef.current?.click()}
        $errorState={errorState}
      >
        <span className="material-symbols-outlined">videocam</span>
        <p>Drag and drop a {uploadText ? uploadText : `Video`}</p>
        <input
          ref={videoFileInputRef}
          type="file"
          accept="video/mp4, video/webm, video/ogg"
          onChange={(event) => handleFileChange(event)}
        />
        <Button outlineNoBorder type="button">
          Upload {uploadText ? uploadText : `Video`}
        </Button>
      </VideoUploadContainer>
    );
  }

  return (
    <VideoUploadContainer $dragActive={false} $errorState={errorState}>
      <span className="material-symbols-outlined">videocam</span>
    </VideoUploadContainer>
  );
};
