import { useGetAllWorkoutPrograms } from "../../../hooks/workout-program-hooks/use-get-all-workout-programs";
import { useCallback } from "react";
import { LoadableDataLoading } from "../../../components/loadable-data-loading";
import { LoadableWorkoutProgramsProvider } from "./context/loadable-workout-programs-context";
import { useLoadableData } from "../../../hooks/use-loadable-data";
import { ProgramsTable } from "./programs-table/programs-table";

export const Programs = () => {
  const getAllWorkoutPrograms = useGetAllWorkoutPrograms();

  const workoutProgramsCallback = useCallback(async () => {
    const [workoutProgramDTOs] = await Promise.all([getAllWorkoutPrograms()]);

    return { workoutProgramDTOs };
  }, [getAllWorkoutPrograms]);

  const loadableWorkoutPrograms = useLoadableData(workoutProgramsCallback);

  return (
    <LoadableDataLoading
      state={loadableWorkoutPrograms.state}
      successComponent={() => (
        <LoadableWorkoutProgramsProvider
          loadableWorkoutPrograms={loadableWorkoutPrograms}
        >
          <ProgramsTable />
        </LoadableWorkoutProgramsProvider>
      )}
    />
  );
};
