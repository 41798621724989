import {
  LevelWorkoutsContainerHeader,
  LevelWorkoutsContainerHeaderActions,
  LevelWorkoutsContainerTitle,
} from "../../../../../../../../styles/level-workouts-styles/level-workouts-container-styles";
import { WorkoutWeek } from "../../types/read-level-workout-weeks";

type ReadLevelWorkoutsWeeksHeaderProps = {
  showDropdown: boolean;
  toggleDropdown: VoidFunction;
  week: WorkoutWeek;
};

export const ReadLevelWorkoutsWeeksHeader = (
  props: ReadLevelWorkoutsWeeksHeaderProps
) => {
  const { showDropdown, toggleDropdown, week } = props;

  return (
    <LevelWorkoutsContainerHeader>
      <LevelWorkoutsContainerTitle>
        Days {week.days[0]} to {week.days[week.days.length - 1]}
      </LevelWorkoutsContainerTitle>
      <LevelWorkoutsContainerHeaderActions $showDropdown={showDropdown}>
        <span
          className="material-symbols-outlined level-workouts-container-header-action-dropdown"
          onClick={toggleDropdown}
        >
          keyboard_arrow_down
        </span>
      </LevelWorkoutsContainerHeaderActions>
    </LevelWorkoutsContainerHeader>
  );
};
